@header-font: Rajdhani, Arial Narrow, sans-serif; //Roboto,Helvetica Neue,sans-serif; //
@body-font: Rajdhani, sans-serif; //Roboto,Helvetica Neue,sans-serif; //

@background: #f8f8f8;
@off-white: #f3f3f3;
@primary-colour: #3a3a3c;
@primary-text: #1e1e1f;
@primary-colour-hover: #454547;
@secondary-color-grey: #888888;
@secondary-color-grey-hover: #cccccc;
@table-border-grey: #bebebe;
@contrast-colour: #dbd3d2;
@date-period: #c2b49b;
@tan: @date-period;
@date-period-light: #d7cfc4;
@date-period-dark: #9e8b68;
@tan-text: @date-period-dark;
@date-period-disabled: @form-grey;
@date-period-disabled-text: @table-border-grey;
@date-period-text: #6d6e70;
@form-element-border: @date-period-text;
@form-element-background: white;
@link: white;
@medium-gradient: rgba(236, 104, 59, 1);
@light-medium-gradient: rgba(241, 135, 63, 1);
@light-grey: #dbd3d2;
@login-grey: #dbd3d2;
@login-pass: #a0958f;
@tile-header: #7b7b7d;
@form-grey: #e6e4e1;
@dark-orange: darkorange;
@disabled: @form-grey;
@text-button-hover: #bebebe;
@wire-orange: rgb(216, 71, 39); // = #d84727;
@wire-orange-light: rgb(216, 140, 100); // = #ffa500;
:root {
  --disabled: @disabled; //Can be imported to .ts files
  --wire-orange: @wire-orange;
}

@button-disabled: @table-border-grey;
@dark-disabled: #575757;
@red: red;
@error: @red;
@error-text: #d10404;
@unfavourable: @red;
@warning: @wire-orange;
@warning-text: @wire-orange;
@red-orange: rgba(236, 104, 59, 1);
@favourable: rgba(0, 128, 0, 0.9);
@success: rgba(0, 128, 0, 0.9);

@dropdown-hover: #f3f3f3;
@dropdown-selected: #c1c1c1;

@gradient: linear-gradient(90deg,
@light-medium-gradient 0%,
@medium-gradient 25%,
@wire-orange 50%,
@medium-gradient 85%,
@light-medium-gradient 100%);

@mat-toolbar-height: 48px;
@date-period-height: 32px;

@page-padding: 0px @header-unit @header-unit @header-unit;
@mobile-padding: 10px;
@toolbar-height: 48px;
@header-unit: 36px;
@unit: 28px;
@form-element-height: @unit;
@form-element-width: 180px;
@form-element-width-min: 180px;
@search-element-height: @header-unit;
@tablet: 0.6;
@header-line: 20px;
@wire-logo-width: 115px;
@header-vpadding: ((@header-unit * 2 - @header-line)/2);
@header-padding-min: ((@header-unit - @header-line)/2);
@header-hpadding: 40px;
@form-padding: 24px;
@dtp-padding: 4px;
@table-header-padding: 4px 0 4px 12px;
@input-padding-min: 2px 4px;
@form-element-outer-padding: 0 8px;
@menu-item-padding: 0 16px;
@mat-dialog-top-padding: 20px; //the default padding set by material
@form-ctrl-margin: 20px;
@form-ctrl-margin-top: @form-ctrl-margin;
@form-ctrl-margin-right: @form-ctrl-margin;
@form-ctrl-margin-bottom: @form-ctrl-margin;
@input-element-border-radius: 4px;

@font-size: 14.4px;
:root {
  --font-size: 14.4; //Can be imported to .ts files
}

@media-xs: 800px;
@media-sm: 960px;
@media-m: 1220px;
@media-md: 1280px;
@media-lg: 1350px;
@media-ws: 1880px;

@z-index-init: 160;

@border-radius: 8px;
@transition-duration: 0.5s;
//@button-border-radius: 5px;
@button-padding: 0.1rem 0.75rem;


@keyframes flashing_warning {
  0% {
    color: @link;
  }
  50% {
    color: @wire-orange;
  }
  100% {
    color: @link;
  }
}

@flashing-warning-animation: flashing_warning 1.5s infinite alternate;
