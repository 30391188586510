@use '@angular/material' as mat;
@import 'globals';

@mixin wire-card-styles($theme) {

  .mat-card-header-text {
    margin: 0;
  }
  .mat-card-title {
    font-weight: 700;
  }
}
