@use '@angular/material' as mat;
@import 'globals';

@mixin wire-button-styles($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .mat-mdc-button, .mat-mdc-button.mat-mdc-button-base,
  {
    letter-spacing: normal;
    --mdc-filled-button-container-color: transparent;
    --mdc-typography-button-letter-spacing: 0.05;
    --mdc-stroked-button-outline-color: #{$primary-colour};
  }

  .mdc-button.mat-mdc-button-base:not(.form-element-button):not(.date-select-button):not(.table-button) {
    &.mat-mdc-button, &.mat-mdc-raised-button, &.mat-mdc-unelevated-button, &.mat-mdc-outlined-button {
      height: #{$header-unit};
    }
  }
  .mdc-button.mat-mdc-button-base.table-button {
    &.mat-mdc-button, &.mat-mdc-raised-button, &.mat-mdc-unelevated-button, &.mat-mdc-outlined-button {
      height: 30px;
    }
  }
  .mat-mdc-unelevated-button {
    --mdc-button-disabled-container-color: transparent;
    --mdc-button-disabled-label-text-color: #{$secondary-colour};
  }
  .mat-mdc-unelevated-button:not(:disabled) {
    --mdc-filled-button-container-color: transparent;
    --mdc-button-hovered-container-color: #{$text-button-hover};
    --mdc-filled-button-label-text-color: #{$primary-colour};

  }
}


//line-height: var(--mdc-typography-button-line-height, 36px);
//font-weight: var(--mdc-typography-button-font-weight, 500);
//letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
//--mat-mdc-button-vertical-padding: 6px;
//--mat-mdc-button-horizontal-padding: 2px;