@use '@angular/material' as mat;
@import 'palette';


//--------COLOURS--------//
$primary-text: mat.m2-get-color-from-palette($primary-palette, 900);
$primary-colour: mat.m2-get-color-from-palette($primary-palette, 500);
$primary-contrast-colour: map-get(map-get($primary-palette, contrast), 500);
$off-white: unquote(mat.m2-get-color-from-palette($primary-palette, A100));
$form-element-border: unquote(mat.m2-get-color-from-palette($primary-palette, A700));
$tile-header: mat.m2-get-color-from-palette($primary-palette, 300);
$secondary-colour: mat.m2-get-color-from-palette($primary-palette, 200);
$placeholder-text: mat.m2-get-color-from-palette($primary-palette, 300);
$disabled-text: mat.m2-get-color-from-palette($primary-palette, 500);
$table-border-grey: mat.m2-get-color-from-palette($primary-palette, 100);
$form-element-background: #f8f8f8;
$menu-item-padding: 0 16px;
$off-white: unquote(mat.m2-get-color-from-palette($primary-palette, A100));
$text-button-hover: mat.m2-get-color-from-palette($primary-palette, 100);
$date-period-text: unquote(mat.m2-get-color-from-palette($primary-palette, A700));
$wire-orange: mat.m2-get-color-from-palette($secondary-palette, 500);
$form-grey: unquote(mat.m2-get-color-from-palette($primary-palette, A200));
//END COLOURS//


//--------HEIGHTS, MARGINS, PADDING--------//
$header-unit: 36px;
$unit: 28px;
$border-radius: 8px;
$page-padding: 0 $header-unit $header-unit $header-unit;
$form-element-height: $unit;
$form-element-outer-padding: 0 8px;
$input-padding-min: 2px 4px;
//END HEIGHTS, MARGINS, PADDING//

//--------TABLES--------//
$row-height: 30px;
//END TABLES//


$media-xs: 800px;
$media-sm: 960px;
$media-m: 1220px;
$media-md: 1280px;
$media-lg: 1350px;
$media-ws: 1880px;
