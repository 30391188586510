.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

html {
  --mat-sys-on-surface: initial;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-sys-background, transparent));
  color: var(--mat-app-text-color, var(--mat-sys-on-background, inherit));
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #3a3a3c;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #c2b49b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #d84727;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #c2b49b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
html {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c2b49b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #3a3a3c;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.mat-primary {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #3a3a3c;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #c2b49b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.mat-accent {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c2b49b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #d84727;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.mat-warn {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #d84727;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-option-label-text-font: Rajdhani, sans-serif;
  --mat-option-label-text-line-height: 16px;
  --mat-option-label-text-size: 14.4px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 500;
}

html {
  --mat-optgroup-label-text-font: Rajdhani, sans-serif;
  --mat-optgroup-label-text-line-height: 16px;
  --mat-optgroup-label-text-size: 14.4px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 500;
}

html {
  --mdc-elevated-card-container-shape: 4px;
}
html {
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}
html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: Rajdhani, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Rajdhani, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #3a3a3c;
  --mdc-linear-progress-track-color: rgba(58, 58, 60, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #c2b49b;
  --mdc-linear-progress-track-color: rgba(194, 180, 155, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #d84727;
  --mdc-linear-progress-track-color: rgba(216, 71, 39, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Rajdhani, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 14.4px;
  --mdc-plain-tooltip-supporting-text-weight: 500;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
}
html {
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}
html {
  --mdc-filled-text-field-caret-color: #3a3a3c;
  --mdc-filled-text-field-focus-active-indicator-color: #3a3a3c;
  --mdc-filled-text-field-focus-label-text-color: rgba(58, 58, 60, 0.87);
  --mdc-filled-text-field-container-color: rgb(244.8, 244.8, 244.8);
  --mdc-filled-text-field-disabled-container-color: rgb(249.9, 249.9, 249.9);
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #d84727;
  --mdc-filled-text-field-error-focus-label-text-color: #d84727;
  --mdc-filled-text-field-error-label-text-color: #d84727;
  --mdc-filled-text-field-error-caret-color: #d84727;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #d84727;
  --mdc-filled-text-field-error-focus-active-indicator-color: #d84727;
  --mdc-filled-text-field-error-hover-active-indicator-color: #d84727;
}
html {
  --mdc-outlined-text-field-caret-color: #3a3a3c;
  --mdc-outlined-text-field-focus-outline-color: #3a3a3c;
  --mdc-outlined-text-field-focus-label-text-color: rgba(58, 58, 60, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #d84727;
  --mdc-outlined-text-field-error-focus-label-text-color: #d84727;
  --mdc-outlined-text-field-error-label-text-color: #d84727;
  --mdc-outlined-text-field-error-hover-label-text-color: #d84727;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #d84727;
  --mdc-outlined-text-field-error-hover-outline-color: #d84727;
  --mdc-outlined-text-field-error-outline-color: #d84727;
}
html {
  --mat-form-field-focus-select-arrow-color: rgba(58, 58, 60, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #d84727;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #c2b49b;
  --mdc-filled-text-field-focus-active-indicator-color: #c2b49b;
  --mdc-filled-text-field-focus-label-text-color: rgba(194, 180, 155, 0.87);
}
.mat-mdc-form-field.mat-accent {
  --mdc-outlined-text-field-caret-color: #c2b49b;
  --mdc-outlined-text-field-focus-outline-color: #c2b49b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(194, 180, 155, 0.87);
}
.mat-mdc-form-field.mat-accent {
  --mat-form-field-focus-select-arrow-color: rgba(194, 180, 155, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #d84727;
  --mdc-filled-text-field-focus-active-indicator-color: #d84727;
  --mdc-filled-text-field-focus-label-text-color: rgba(216, 71, 39, 0.87);
}
.mat-mdc-form-field.mat-warn {
  --mdc-outlined-text-field-caret-color: #d84727;
  --mdc-outlined-text-field-focus-outline-color: #d84727;
  --mdc-outlined-text-field-focus-label-text-color: rgba(216, 71, 39, 0.87);
}
.mat-mdc-form-field.mat-warn {
  --mat-form-field-focus-select-arrow-color: rgba(216, 71, 39, 0.87);
}

html {
  --mat-form-field-container-height: 48px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 12px;
  --mat-form-field-filled-with-label-container-padding-top: 12px;
  --mat-form-field-filled-with-label-container-padding-bottom: 12px;
}

html {
  --mdc-filled-text-field-label-text-font: Rajdhani, sans-serif;
  --mdc-filled-text-field-label-text-size: 14.4px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 500;
}
html {
  --mdc-outlined-text-field-label-text-font: Rajdhani, sans-serif;
  --mdc-outlined-text-field-label-text-size: 14.4px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 500;
}
html {
  --mat-form-field-container-text-font: Rajdhani, sans-serif;
  --mat-form-field-container-text-line-height: 16px;
  --mat-form-field-container-text-size: 14.4px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 500;
  --mat-form-field-outlined-label-text-populated-size: 14.4px;
  --mat-form-field-subscript-text-font: Rajdhani, sans-serif;
  --mat-form-field-subscript-text-line-height: 16px;
  --mat-form-field-subscript-text-size: 14.4px;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 500;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(58, 58, 60, 0.87);
  --mat-select-invalid-arrow-color: rgba(216, 71, 39, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(194, 180, 155, 0.87);
  --mat-select-invalid-arrow-color: rgba(216, 71, 39, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(216, 71, 39, 0.87);
  --mat-select-invalid-arrow-color: rgba(216, 71, 39, 0.87);
}

html {
  --mat-select-arrow-transform: none;
}

html {
  --mat-select-trigger-text-font: Rajdhani, sans-serif;
  --mat-select-trigger-text-line-height: 16px;
  --mat-select-trigger-text-size: 14.4px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 500;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-shape: 4px;
}
html {
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
html {
  --mdc-dialog-subhead-font: Rajdhani, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Rajdhani, sans-serif;
  --mdc-dialog-supporting-text-line-height: 16px;
  --mdc-dialog-supporting-text-size: 14.4px;
  --mdc-dialog-supporting-text-weight: 500;
  --mdc-dialog-supporting-text-tracking: normal;
}
.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
}
.mat-mdc-standard-chip {
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-disabled-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-flat-disabled-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip {
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #e6e4e1;
  --mdc-chip-elevated-container-color: #3a3a3c;
  --mdc-chip-elevated-selected-container-color: #3a3a3c;
  --mdc-chip-elevated-disabled-container-color: #3a3a3c;
  --mdc-chip-flat-disabled-selected-container-color: #3a3a3c;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #e6e4e1;
  --mdc-chip-selected-label-text-color: #e6e4e1;
  --mdc-chip-with-icon-icon-color: #e6e4e1;
  --mdc-chip-with-icon-disabled-icon-color: #e6e4e1;
  --mdc-chip-with-icon-selected-icon-color: #e6e4e1;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #e6e4e1;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #e6e4e1;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mat-chip-selected-disabled-trailing-icon-color: #e6e4e1;
  --mat-chip-selected-trailing-icon-color: #e6e4e1;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-elevated-container-color: #c2b49b;
  --mdc-chip-elevated-selected-container-color: #c2b49b;
  --mdc-chip-elevated-disabled-container-color: #c2b49b;
  --mdc-chip-flat-disabled-selected-container-color: #c2b49b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-selected-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mat-chip-selected-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mat-chip-selected-trailing-icon-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #d84727;
  --mdc-chip-elevated-selected-container-color: #d84727;
  --mdc-chip-elevated-disabled-container-color: #d84727;
  --mdc-chip-flat-disabled-selected-container-color: #d84727;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 24px;
}
.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Rajdhani, sans-serif;
  --mdc-chip-label-text-line-height: 18px;
  --mdc-chip-label-text-size: 16px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 500;
}
html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #343436;
  --mdc-switch-selected-handle-color: #343436;
  --mdc-switch-selected-hover-state-layer-color: #343436;
  --mdc-switch-selected-pressed-state-layer-color: #343436;
  --mdc-switch-selected-focus-handle-color: #18181a;
  --mdc-switch-selected-hover-handle-color: #18181a;
  --mdc-switch-selected-pressed-handle-color: #18181a;
  --mdc-switch-selected-focus-track-color: #7b7b7d;
  --mdc-switch-selected-hover-track-color: #7b7b7d;
  --mdc-switch-selected-pressed-track-color: #7b7b7d;
  --mdc-switch-selected-track-color: #7b7b7d;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #bcad93;
  --mdc-switch-selected-handle-color: #bcad93;
  --mdc-switch-selected-hover-state-layer-color: #bcad93;
  --mdc-switch-selected-pressed-state-layer-color: #bcad93;
  --mdc-switch-selected-focus-handle-color: #9f8c6d;
  --mdc-switch-selected-hover-handle-color: #9f8c6d;
  --mdc-switch-selected-pressed-handle-color: #9f8c6d;
  --mdc-switch-selected-focus-track-color: #d0bfa6;
  --mdc-switch-selected-hover-track-color: #d0bfa6;
  --mdc-switch-selected-pressed-track-color: #d0bfa6;
  --mdc-switch-selected-track-color: #d0bfa6;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #d44023;
  --mdc-switch-selected-handle-color: #d44023;
  --mdc-switch-selected-hover-state-layer-color: #d44023;
  --mdc-switch-selected-pressed-state-layer-color: #d44023;
  --mdc-switch-selected-focus-handle-color: #bf200e;
  --mdc-switch-selected-hover-handle-color: #bf200e;
  --mdc-switch-selected-pressed-handle-color: #bf200e;
  --mdc-switch-selected-focus-track-color: #e77d65;
  --mdc-switch-selected-hover-track-color: #e77d65;
  --mdc-switch-selected-pressed-track-color: #e77d65;
  --mdc-switch-selected-track-color: #e77d65;
}

html {
  --mdc-switch-state-layer-size: 32px;
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Rajdhani, sans-serif;
  --mat-switch-label-text-line-height: 18px;
  --mat-switch-label-text-size: 16px;
  --mat-switch-label-text-tracking: normal;
  --mat-switch-label-text-weight: 500;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3a3a3c;
  --mdc-radio-selected-hover-icon-color: #3a3a3c;
  --mdc-radio-selected-icon-color: #3a3a3c;
  --mdc-radio-selected-pressed-icon-color: #3a3a3c;
}
.mat-mdc-radio-button.mat-primary {
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #3a3a3c;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c2b49b;
  --mdc-radio-selected-hover-icon-color: #c2b49b;
  --mdc-radio-selected-icon-color: #c2b49b;
  --mdc-radio-selected-pressed-icon-color: #c2b49b;
}
.mat-mdc-radio-button.mat-accent {
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c2b49b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #d84727;
  --mdc-radio-selected-hover-icon-color: #d84727;
  --mdc-radio-selected-icon-color: #d84727;
  --mdc-radio-selected-pressed-icon-color: #d84727;
}
.mat-mdc-radio-button.mat-warn {
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #d84727;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mdc-radio-state-layer-size: 32px;
}
html {
  --mat-radio-touch-target-display: none;
}

html {
  --mat-radio-label-text-font: Rajdhani, sans-serif;
  --mat-radio-label-text-line-height: 18px;
  --mat-radio-label-text-size: 16px;
  --mat-radio-label-text-tracking: normal;
  --mat-radio-label-text-weight: 500;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-handle-color: #3a3a3c;
  --mdc-slider-focus-handle-color: #3a3a3c;
  --mdc-slider-hover-handle-color: #3a3a3c;
  --mdc-slider-active-track-color: #3a3a3c;
  --mdc-slider-inactive-track-color: #3a3a3c;
  --mdc-slider-with-tick-marks-inactive-container-color: #3a3a3c;
  --mdc-slider-with-tick-marks-active-container-color: #e6e4e1;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
}
html {
  --mat-slider-ripple-color: #3a3a3c;
  --mat-slider-hover-state-layer-color: rgba(58, 58, 60, 0.05);
  --mat-slider-focus-state-layer-color: rgba(58, 58, 60, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mdc-slider-handle-color: #c2b49b;
  --mdc-slider-focus-handle-color: #c2b49b;
  --mdc-slider-hover-handle-color: #c2b49b;
  --mdc-slider-active-track-color: #c2b49b;
  --mdc-slider-inactive-track-color: #c2b49b;
  --mdc-slider-with-tick-marks-inactive-container-color: #c2b49b;
  --mdc-slider-with-tick-marks-active-container-color: rgba(0, 0, 0, 0.87);
}
html .mat-accent {
  --mat-slider-ripple-color: #c2b49b;
  --mat-slider-hover-state-layer-color: rgba(194, 180, 155, 0.05);
  --mat-slider-focus-state-layer-color: rgba(194, 180, 155, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #d84727;
  --mdc-slider-focus-handle-color: #d84727;
  --mdc-slider-hover-handle-color: #d84727;
  --mdc-slider-active-track-color: #d84727;
  --mdc-slider-inactive-track-color: #d84727;
  --mdc-slider-with-tick-marks-inactive-container-color: #d84727;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-warn {
  --mat-slider-ripple-color: #d84727;
  --mat-slider-hover-state-layer-color: rgba(216, 71, 39, 0.05);
  --mat-slider-focus-state-layer-color: rgba(216, 71, 39, 0.2);
}

html {
  --mdc-slider-label-label-text-font: Rajdhani, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: Rajdhani, sans-serif;
  --mat-menu-item-label-text-size: 14.4px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 16px;
  --mat-menu-item-label-text-weight: 500;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}
html {
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3a3a3c;
  --mdc-radio-selected-hover-icon-color: #3a3a3c;
  --mdc-radio-selected-icon-color: #3a3a3c;
  --mdc-radio-selected-pressed-icon-color: #3a3a3c;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c2b49b;
  --mdc-radio-selected-hover-icon-color: #c2b49b;
  --mdc-radio-selected-icon-color: #c2b49b;
  --mdc-radio-selected-pressed-icon-color: #c2b49b;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #d84727;
  --mdc-radio-selected-hover-icon-color: #d84727;
  --mdc-radio-selected-icon-color: #d84727;
  --mdc-radio-selected-pressed-icon-color: #d84727;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #e6e4e1;
  --mdc-checkbox-selected-focus-icon-color: #3a3a3c;
  --mdc-checkbox-selected-hover-icon-color: #3a3a3c;
  --mdc-checkbox-selected-icon-color: #3a3a3c;
  --mdc-checkbox-selected-pressed-icon-color: #3a3a3c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3a3a3c;
  --mdc-checkbox-selected-hover-state-layer-color: #3a3a3c;
  --mdc-checkbox-selected-pressed-state-layer-color: #3a3a3c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #c2b49b;
  --mdc-checkbox-selected-hover-icon-color: #c2b49b;
  --mdc-checkbox-selected-icon-color: #c2b49b;
  --mdc-checkbox-selected-pressed-icon-color: #c2b49b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c2b49b;
  --mdc-checkbox-selected-hover-state-layer-color: #c2b49b;
  --mdc-checkbox-selected-pressed-state-layer-color: #c2b49b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #d84727;
  --mdc-checkbox-selected-hover-icon-color: #d84727;
  --mdc-checkbox-selected-icon-color: #d84727;
  --mdc-checkbox-selected-pressed-icon-color: #d84727;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #d84727;
  --mdc-checkbox-selected-hover-state-layer-color: #d84727;
  --mdc-checkbox-selected-pressed-state-layer-color: #d84727;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #3a3a3c;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 40px;
  --mdc-list-list-item-two-line-container-height: 56px;
  --mdc-list-list-item-three-line-container-height: 80px;
}
html {
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 32px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 48px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 64px;
}

html {
  --mdc-list-list-item-label-text-font: Rajdhani, sans-serif;
  --mdc-list-list-item-label-text-line-height: 16px;
  --mdc-list-list-item-label-text-size: 14.4px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 500;
  --mdc-list-list-item-supporting-text-font: Rajdhani, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 18px;
  --mdc-list-list-item-supporting-text-size: 16px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 500;
  --mdc-list-list-item-trailing-supporting-text-font: Rajdhani, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 16px;
  --mdc-list-list-item-trailing-supporting-text-size: 14.4px;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 500;
}
.mdc-list-group__subheader {
  font: 400 16px / 28px Rajdhani, sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 48px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}

html {
  --mat-paginator-container-text-font: Rajdhani, sans-serif;
  --mat-paginator-container-text-line-height: 16px;
  --mat-paginator-container-text-size: 14.4px;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 500;
  --mat-paginator-select-trigger-text-size: 14.4px;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
}
html {
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #3a3a3c;
}
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #3a3a3c;
  --mat-tab-header-active-ripple-color: #3a3a3c;
  --mat-tab-header-inactive-ripple-color: #3a3a3c;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3a3a3c;
  --mat-tab-header-active-hover-label-text-color: #3a3a3c;
  --mat-tab-header-active-focus-indicator-color: #3a3a3c;
  --mat-tab-header-active-hover-indicator-color: #3a3a3c;
}
.mat-mdc-tab-group.mat-accent,
.mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #c2b49b;
}
.mat-mdc-tab-group.mat-accent,
.mat-mdc-tab-nav-bar.mat-accent {
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c2b49b;
  --mat-tab-header-active-ripple-color: #c2b49b;
  --mat-tab-header-inactive-ripple-color: #c2b49b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c2b49b;
  --mat-tab-header-active-hover-label-text-color: #c2b49b;
  --mat-tab-header-active-focus-indicator-color: #c2b49b;
  --mat-tab-header-active-hover-indicator-color: #c2b49b;
}
.mat-mdc-tab-group.mat-warn,
.mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #d84727;
}
.mat-mdc-tab-group.mat-warn,
.mat-mdc-tab-nav-bar.mat-warn {
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #d84727;
  --mat-tab-header-active-ripple-color: #d84727;
  --mat-tab-header-inactive-ripple-color: #d84727;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #d84727;
  --mat-tab-header-active-hover-label-text-color: #d84727;
  --mat-tab-header-active-focus-indicator-color: #d84727;
  --mat-tab-header-active-hover-indicator-color: #d84727;
}
.mat-mdc-tab-group.mat-background-primary,
.mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #3a3a3c;
  --mat-tab-header-with-background-foreground-color: #e6e4e1;
}
.mat-mdc-tab-group.mat-background-accent,
.mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #c2b49b;
  --mat-tab-header-with-background-foreground-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-tab-group.mat-background-warn,
.mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #d84727;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 40px;
}
.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Rajdhani, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}
html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}
html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #c2b49b;
  --mdc-checkbox-selected-hover-icon-color: #c2b49b;
  --mdc-checkbox-selected-icon-color: #c2b49b;
  --mdc-checkbox-selected-pressed-icon-color: #c2b49b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c2b49b;
  --mdc-checkbox-selected-hover-state-layer-color: #c2b49b;
  --mdc-checkbox-selected-pressed-state-layer-color: #c2b49b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
html {
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #e6e4e1;
  --mdc-checkbox-selected-focus-icon-color: #3a3a3c;
  --mdc-checkbox-selected-hover-icon-color: #3a3a3c;
  --mdc-checkbox-selected-icon-color: #3a3a3c;
  --mdc-checkbox-selected-pressed-icon-color: #3a3a3c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3a3a3c;
  --mdc-checkbox-selected-hover-state-layer-color: #3a3a3c;
  --mdc-checkbox-selected-pressed-state-layer-color: #3a3a3c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #d84727;
  --mdc-checkbox-selected-hover-icon-color: #d84727;
  --mdc-checkbox-selected-icon-color: #d84727;
  --mdc-checkbox-selected-pressed-icon-color: #d84727;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #d84727;
  --mdc-checkbox-selected-hover-state-layer-color: #d84727;
  --mdc-checkbox-selected-pressed-state-layer-color: #d84727;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 32px;
}
html {
  --mat-checkbox-touch-target-display: none;
}

html {
  --mat-checkbox-label-text-font: Rajdhani, sans-serif;
  --mat-checkbox-label-text-line-height: 18px;
  --mat-checkbox-label-text-size: 16px;
  --mat-checkbox-label-text-tracking: normal;
  --mat-checkbox-label-text-weight: 500;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
}
html {
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
}
html {
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
}
html {
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
}
html {
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
}
html {
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
}
html {
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
}
html {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
}
html {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
}
html {
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
html {
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3a3a3c;
}
.mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #3a3a3c;
  --mat-text-button-ripple-color: rgba(58, 58, 60, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #c2b49b;
}
.mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #c2b49b;
  --mat-text-button-ripple-color: rgba(194, 180, 155, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #d84727;
}
.mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #d84727;
  --mat-text-button-ripple-color: rgba(216, 71, 39, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3a3a3c;
  --mdc-filled-button-label-text-color: #e6e4e1;
}
.mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: #e6e4e1;
  --mat-filled-button-ripple-color: rgba(230, 228, 225, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #c2b49b;
  --mdc-filled-button-label-text-color: black;
}
.mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #d84727;
  --mdc-filled-button-label-text-color: white;
}
.mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3a3a3c;
  --mdc-protected-button-label-text-color: #e6e4e1;
}
.mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: #e6e4e1;
  --mat-protected-button-ripple-color: rgba(230, 228, 225, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #c2b49b;
  --mdc-protected-button-label-text-color: black;
}
.mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #d84727;
  --mdc-protected-button-label-text-color: white;
}
.mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3a3a3c;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #3a3a3c;
  --mat-outlined-button-ripple-color: rgba(58, 58, 60, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #c2b49b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #c2b49b;
  --mat-outlined-button-ripple-color: rgba(194, 180, 155, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #d84727;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #d84727;
  --mat-outlined-button-ripple-color: rgba(216, 71, 39, 0.1);
}

html {
  --mdc-text-button-container-height: 28px;
}
html {
  --mdc-filled-button-container-height: 28px;
}
html {
  --mdc-protected-button-container-height: 28px;
}
html {
  --mdc-outlined-button-container-height: 28px;
}
html {
  --mat-text-button-touch-target-display: none;
}
html {
  --mat-filled-button-touch-target-display: none;
}
html {
  --mat-protected-button-touch-target-display: none;
}
html {
  --mat-outlined-button-touch-target-display: none;
}

html {
  --mdc-text-button-label-text-font: Rajdhani, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
}
html {
  --mdc-filled-button-label-text-font: Rajdhani, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
}
html {
  --mdc-protected-button-label-text-font: Rajdhani, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}
html {
  --mdc-outlined-button-label-text-font: Rajdhani, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
}
html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3a3a3c;
}
html .mat-mdc-icon-button.mat-primary {
  --mat-icon-button-state-layer-color: #3a3a3c;
  --mat-icon-button-ripple-color: rgba(58, 58, 60, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #c2b49b;
}
html .mat-mdc-icon-button.mat-accent {
  --mat-icon-button-state-layer-color: #c2b49b;
  --mat-icon-button-ripple-color: rgba(194, 180, 155, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #d84727;
}
html .mat-mdc-icon-button.mat-warn {
  --mat-icon-button-state-layer-color: #d84727;
  --mat-icon-button-ripple-color: rgba(216, 71, 39, 0.1);
}

html {
  --mat-icon-button-touch-target-display: none;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-fab-container-color: white;
}
html {
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html {
  --mdc-fab-small-container-color: white;
}
html {
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #3a3a3c;
}
html .mat-mdc-fab.mat-primary {
  --mat-fab-foreground-color: #e6e4e1;
  --mat-fab-state-layer-color: #e6e4e1;
  --mat-fab-ripple-color: rgba(230, 228, 225, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #c2b49b;
}
html .mat-mdc-fab.mat-accent {
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #d84727;
}
html .mat-mdc-fab.mat-warn {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #3a3a3c;
}
html .mat-mdc-mini-fab.mat-primary {
  --mat-fab-small-foreground-color: #e6e4e1;
  --mat-fab-small-state-layer-color: #e6e4e1;
  --mat-fab-small-ripple-color: rgba(230, 228, 225, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #c2b49b;
}
html .mat-mdc-mini-fab.mat-accent {
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #d84727;
}
html .mat-mdc-mini-fab.mat-warn {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: none;
}
html {
  --mat-fab-small-touch-target-display: none;
}

html {
  --mdc-extended-fab-label-text-font: Rajdhani, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}
html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
html {
  --mat-snack-bar-button-color: #c2b49b;
}

html {
  --mdc-snackbar-supporting-text-font: Rajdhani, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 18px;
  --mdc-snackbar-supporting-text-size: 16px;
  --mdc-snackbar-supporting-text-weight: 500;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 48px;
  --mat-table-footer-container-height: 44px;
  --mat-table-row-item-container-height: 44px;
}

html {
  --mat-table-header-headline-font: Rajdhani, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Rajdhani, sans-serif;
  --mat-table-row-item-label-text-line-height: 18px;
  --mat-table-row-item-label-text-size: 16px;
  --mat-table-row-item-label-text-weight: 500;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Rajdhani, sans-serif;
  --mat-table-footer-supporting-text-line-height: 18px;
  --mat-table-footer-supporting-text-size: 16px;
  --mat-table-footer-supporting-text-weight: 500;
  --mat-table-footer-supporting-text-tracking: normal;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #3a3a3c;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #c2b49b;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #d84727;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #3a3a3c;
  --mat-badge-text-color: #e6e4e1;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #c2b49b;
  --mat-badge-text-color: rgba(0, 0, 0, 0.87);
}

.mat-badge-warn {
  --mat-badge-background-color: #d84727;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Rajdhani, sans-serif;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Rajdhani, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 18px;
  --mat-bottom-sheet-container-text-size: 16px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 500;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
}
html {
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
}
html {
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: rgb(224.4, 224.4, 224.4);
}

html {
  --mat-standard-button-toggle-height: 40px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Rajdhani, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 16px;
  --mat-legacy-button-toggle-label-text-size: 14.4px;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 500;
}
html {
  --mat-standard-button-toggle-label-text-font: Rajdhani, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 16px;
  --mat-standard-button-toggle-label-text-size: 14.4px;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 500;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: #e6e4e1;
  --mat-datepicker-calendar-date-selected-state-background-color: #3a3a3c;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(58, 58, 60, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #e6e4e1;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(58, 58, 60, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(58, 58, 60, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #3a3a3c;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(58, 58, 60, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #c2b49b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(194, 180, 155, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(194, 180, 155, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(194, 180, 155, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(194, 180, 155, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #d84727;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(216, 71, 39, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(216, 71, 39, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(216, 71, 39, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(216, 71, 39, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #c2b49b;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #d84727;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Rajdhani, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 500;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 40px;
  --mat-expansion-header-expanded-state-height: 56px;
}

html {
  --mat-expansion-header-text-font: Rajdhani, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Rajdhani, sans-serif;
  --mat-expansion-container-text-line-height: 18px;
  --mat-expansion-container-text-size: 16px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 500;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 16px;
  --mat-grid-list-tile-header-secondary-text-size: 14.4px;
  --mat-grid-list-tile-footer-primary-text-size: 16px;
  --mat-grid-list-tile-footer-secondary-text-size: 14.4px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #3a3a3c;
}
.mat-icon.mat-accent {
  --mat-icon-color: #c2b49b;
}
.mat-icon.mat-warn {
  --mat-icon-color: #d84727;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: #e6e4e1;
  --mat-stepper-header-selected-state-icon-background-color: #3a3a3c;
  --mat-stepper-header-selected-state-icon-foreground-color: #e6e4e1;
  --mat-stepper-header-done-state-icon-background-color: #3a3a3c;
  --mat-stepper-header-done-state-icon-foreground-color: #e6e4e1;
  --mat-stepper-header-edit-state-icon-background-color: #3a3a3c;
  --mat-stepper-header-edit-state-icon-foreground-color: #e6e4e1;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #d84727;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #d84727;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #c2b49b;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #c2b49b;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #c2b49b;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #d84727;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #d84727;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #d84727;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 64px;
}

html {
  --mat-stepper-container-text-font: Rajdhani, sans-serif;
  --mat-stepper-header-label-text-font: Rajdhani, sans-serif;
  --mat-stepper-header-label-text-size: 16px;
  --mat-stepper-header-label-text-weight: 500;
  --mat-stepper-header-error-state-label-text-size: 14.4px;
  --mat-stepper-header-selected-state-label-text-size: 14.4px;
  --mat-stepper-header-selected-state-label-text-weight: 500;
}

html {
  --mat-sort-arrow-color: rgb(117.3, 117.3, 117.3);
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #3a3a3c;
  --mat-toolbar-container-text-color: #e6e4e1;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #c2b49b;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #d84727;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 56px;
  --mat-toolbar-mobile-height: 48px;
}

html {
  --mat-toolbar-title-text-font: Rajdhani, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 40px;
}

html {
  --mat-tree-node-text-font: Rajdhani, sans-serif;
  --mat-tree-node-text-size: 16px;
  --mat-tree-node-text-weight: 500;
}

html {
  --mat-timepicker-container-shape: 4px;
  --mat-timepicker-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-timepicker-container-background-color: white;
}

html {
  --mat-menu-item-label-text-size: $body-2-size;
}
html .mat-mdc-button, html .mat-mdc-button.mat-mdc-button-base {
  letter-spacing: normal;
  --mdc-filled-button-container-color: transparent;
  --mdc-typography-button-letter-spacing: 0.05;
  --mdc-stroked-button-outline-color: #3a3a3c;
}
html .mdc-button.mat-mdc-button-base:not(.form-element-button):not(.date-select-button):not(.table-button).mat-mdc-button, html .mdc-button.mat-mdc-button-base:not(.form-element-button):not(.date-select-button):not(.table-button).mat-mdc-raised-button, html .mdc-button.mat-mdc-button-base:not(.form-element-button):not(.date-select-button):not(.table-button).mat-mdc-unelevated-button, html .mdc-button.mat-mdc-button-base:not(.form-element-button):not(.date-select-button):not(.table-button).mat-mdc-outlined-button {
  height: 36px;
}
html .mdc-button.mat-mdc-button-base.table-button.mat-mdc-button, html .mdc-button.mat-mdc-button-base.table-button.mat-mdc-raised-button, html .mdc-button.mat-mdc-button-base.table-button.mat-mdc-unelevated-button, html .mdc-button.mat-mdc-button-base.table-button.mat-mdc-outlined-button {
  height: 30px;
}
html .mat-mdc-unelevated-button {
  --mdc-button-disabled-container-color: transparent;
  --mdc-button-disabled-label-text-color: #a1a1a2;
}
html .mat-mdc-unelevated-button:not(:disabled) {
  --mdc-filled-button-container-color: transparent;
  --mdc-button-hovered-container-color: #c6c6c7;
  --mdc-filled-button-label-text-color: #3a3a3c;
}
html .mat-mdc-checkbox, html .mat-mdc-radio-button {
  --mdc-typography-body1-font-size: 14.4px;
  --mdc-typography-body1-line-height: 16px;
  --mdc-typography-body1-font-weight: 500;
  --mdc-typography-body1-letter-spacing: normal;
}
html .mat-mdc-checkbox, html .mat-mdc-checkbox.mat-accent, html .mat-pseudo-checkbox {
  --mdc-checkbox-selected-checkmark-color: #e6e4e1;
  --mdc-checkbox-selected-focus-icon-color: #d84727;
  --mdc-checkbox-selected-hover-icon-color: #d84727;
  --mdc-checkbox-selected-pressed-icon-color: #d84727;
  --mdc-checkbox-selected-icon-color: #d84727;
  --mdc-checkbox-label-text-color: black;
  --mat-full-pseudo-checkbox-selected-icon-color: #d84727;
  --mdc-checkbox-unselected-focus-icon-color: #6d6e70;
  --mdc-checkbox-unselected-hover-icon-color: #6d6e70;
  --mdc-checkbox-unselected-icon-color: #6d6e70;
  --mdc-checkbox-unselected-pressed-icon-color: #6d6e70;
  --mdc-checkbox-unselected-focus-icon-width: 1px;
  --mdc-checkbox-unselected-hover-icon-width: 1px;
  --mdc-checkbox-unselected-icon-width: 1px;
  --mdc-checkbox-unselected-pressed-icon-width: 1px;
  --mdc-checkbox-shape-size: 0px;
  --mdc-checkbox-label-text-weight: 500;
  --mdc-checkbox-label-text-size: 14.4px;
  --mdc-checkbox-label-spacing: 0;
  --mdc-checkbox-state-layer-size: 20px;
  --mdc-checkbox-icon-size: 16px;
  padding: 0 8px;
  padding-right: 0;
}
html .mat-mdc-checkbox .mdc-checkbox__background, html .mat-mdc-checkbox.mat-accent .mdc-checkbox__background, html .mat-pseudo-checkbox .mdc-checkbox__background {
  border-radius: 0 !important;
  border-width: 1px !important;
}
html .mat-mdc-checkbox .mat-mdc-checkbox-ripple, html .mat-mdc-checkbox .mdc-checkbox__ripple, html .mat-mdc-checkbox.mat-accent .mat-mdc-checkbox-ripple, html .mat-mdc-checkbox.mat-accent .mdc-checkbox__ripple, html .mat-pseudo-checkbox .mat-mdc-checkbox-ripple, html .mat-pseudo-checkbox .mdc-checkbox__ripple {
  display: none;
}
html .mat-mdc-checkbox .mdc-checkbox, html .mat-mdc-checkbox.mat-accent .mdc-checkbox, html .mat-pseudo-checkbox .mdc-checkbox {
  padding: 0 8px;
  padding-right: 0;
  margin: 0;
}
html .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control, html .mat-mdc-checkbox.mat-accent .mdc-checkbox .mdc-checkbox__native-control, html .mat-pseudo-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  left: 0;
  top: 0;
  height: auto;
  width: auto;
}
html .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background, html .mat-mdc-checkbox.mat-accent .mdc-checkbox .mdc-checkbox__background, html .mat-pseudo-checkbox .mdc-checkbox .mdc-checkbox__background {
  left: 0;
  top: 0;
}
html .mat-card-header-text {
  margin: 0;
}
html .mat-card-title {
  font-weight: 700;
}
html .mat-mdc-dialog-container, html .dialog-table {
  --mdc-dialog-container-color: rgba(102, 102, 102, 0.8);
  --mdc-dialog-supporting-text-color: #18181a;
}
html .mat-mdc-dialog-container .mat-mdc-label-container, html .dialog-table .mat-mdc-label-container {
  border: none;
}
html .mat-mdc-dialog-container .mat-mdc-tab-body-wrapper, html .dialog-table .mat-mdc-tab-body-wrapper {
  background: #e6e4e1;
}
html .mat-mdc-dialog-container .mat-mdc-tab-labels .mat-mdc-tab, html .dialog-table .mat-mdc-tab-labels .mat-mdc-tab {
  background: #a1a1a2;
  opacity: 1;
  font-size: 1.166rem;
  letter-spacing: 1.166px;
  color: #3a3a3c;
  text-transform: uppercase;
  font-weight: 400;
  height: 36px;
  padding: 2px 10px;
  margin-right: 5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
html .mat-mdc-dialog-container .mat-mdc-tab-labels .mat-mdc-tab .mdc-tab-indicator, html .dialog-table .mat-mdc-tab-labels .mat-mdc-tab .mdc-tab-indicator {
  display: none;
}
html .mat-mdc-dialog-container .mat-mdc-tab-labels .mdc-tab--active, html .dialog-table .mat-mdc-tab-labels .mdc-tab--active {
  background: #e6e4e1;
  color: #7b7b7d;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px 2px rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-color: #c6c6c7;
  border-width: 1px 1px 0 1px;
}
html .mat-mdc-dialog-container .mat-mdc-tab-labels .mdc-tab--active:hover, html .dialog-table .mat-mdc-tab-labels .mdc-tab--active:hover {
  background: #e6e4e1;
}
html .data-exceptions-table .mat-mdc-tab-header, html .manage-user-list .mat-mdc-tab-header, html .page .mat-mdc-tab-group.dialog-table .mat-mdc-tab-header {
  top: 1px;
}
html .data-exceptions-table .mat-mdc-tab-labels .mat-mdc-tab, html .manage-user-list .mat-mdc-tab-labels .mat-mdc-tab, html .page .mat-mdc-tab-group.dialog-table .mat-mdc-tab-labels .mat-mdc-tab {
  color: white;
  font-weight: bold;
  font-size: 1rem;
  background: #7b7b7d;
}
html .data-exceptions-table .mat-mdc-tab-labels .mdc-tab.mdc-tab--active, html .manage-user-list .mat-mdc-tab-labels .mdc-tab.mdc-tab--active, html .page .mat-mdc-tab-group.dialog-table .mat-mdc-tab-labels .mdc-tab.mdc-tab--active {
  background: #f3f3f3;
  color: #6d6e70;
  border: solid #c6c6c7;
  border-width: 1px 1px 0 1px;
}
html .data-exceptions-table .mat-mdc-tab-body-wrapper, html .manage-user-list .mat-mdc-tab-body-wrapper, html .page .mat-mdc-tab-group.dialog-table .mat-mdc-tab-body-wrapper {
  background: #f3f3f3;
  border: 1px solid #c6c6c7;
}
html .data-exceptions-table .table-container, html .manage-user-list .table-container, html .page .mat-mdc-tab-group.dialog-table .table-container {
  border-width: 1px 0 0 0;
}
html .data-exceptions-table .mat-mdc-tab-body, html .manage-user-list .mat-mdc-tab-body, html .page .mat-mdc-tab-group.dialog-table .mat-mdc-tab-body {
  height: auto;
  overflow: unset;
}
html .mdc-form-field, html .mat-mdc-checkbox .mat-internal-form-field {
  font-size: var(--mdc-typography-body1-font-size, 14.4px);
  color: var(--mat-checkbox-label-text-color);
}
html .row .mat-mdc-form-field.fixed-height {
  height: 52px;
}
html .row .mat-mdc-form-field.no-label.fixed-height {
  height: 32px;
}
html .mat-mdc-form-field {
  --mat-mdc-form-field-floating-label-scale: 1;
}
html .mat-mdc-form-field .mdc-line-ripple::before,
html .mat-mdc-form-field .mdc-line-ripple::after {
  border-bottom-style: none;
}
html .mat-mdc-form-field .mat-mdc-text-field-wrapper {
  background-color: transparent;
  border-radius: 0;
  padding: 0 8px;
}
html .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
html .mat-mdc-form-field .mat-mdc-form-field-flex {
  align-items: center;
  min-height: 28px;
}
html .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-floating-label {
  transform: translateY(-1.8em);
  color: black;
  width: 133.33333%;
}
html .mat-mdc-form-field:not(.no-label) .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label,
html .mat-mdc-form-field .mat-mdc-floating-label {
  display: block;
  --mdc-typography-body1-font-weight: 600;
  font-weight: var(--mdc-typography-body1-font-weight);
}
html .mat-mdc-form-field .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
html .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 0;
  margin-bottom: 0;
  min-height: unset;
}
html .mat-mdc-form-field.no-label .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
html .mat-mdc-form-field.no-label .mat-mdc-form-field-infix {
  padding-top: 0;
}
html .mat-mdc-form-field.no-label .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix input.mat-mdc-input-element,
html .mat-mdc-form-field.no-label .mat-mdc-form-field-infix input.mat-mdc-input-element {
  height: 28px;
}
html .mat-mdc-form-field.no-label label, html .mat-mdc-form-field.no-label mdc-floating-label {
  display: none;
}
html .mat-mdc-form-field-focus-overlay {
  background-color: inherit;
}
html .mat-mdc-form-field.inline {
  width: 500px;
}
html .mat-mdc-form-field.inline .mat-mdc-form-field-infix {
  display: flex;
  align-items: center;
  padding-top: 0 !important;
}
html .mat-mdc-form-field.inline .mat-mdc-form-field-infix label {
  min-width: fit-content;
  margin-right: 8px;
}
html .mat-mdc-form-field.min {
  background: orange;
}
html .mat-mdc-form-field.min .mat-mdc-form-field-infix {
  padding: 0;
}
html .mat-mdc-form-field.min .mat-mdc-text-field-wrapper {
  padding: 0;
}
html .mat-mdc-input-element.mdc-text-field__input,
html .mat-mdc-form-field .mat-mdc-input-element.mdc-text-field__input,
html .form-dialog .mat-mdc-input-element.mdc-text-field__input,
html .form-dialog .mat-mdc-form-field .mat-mdc-input-element.mdc-text-field__input,
html .form-dialog .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control,
html .form-dialog .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input {
  height: 28px;
  --mdc-typography-body1-line-height: $form-element-height;
  border: 1px solid #6d6e70;
  background-color: #f8f8f8;
  box-sizing: border-box;
}
html input.mat-mdc-input-element {
  height: 28px;
  padding: 2px 4px;
}
html .dark .mat-mdc-select-value-text, html .dark .mat-mdc-select-arrow {
  color: #e6e4e1;
}
html .mat-mdc-menu-panel:not(.dark) .mat-mdc-menu-content .mat-mdc-menu-item, html .mat-mdc-menu-panel:not(.dark) .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  font-size: 16px;
  min-height: 36px;
  align-items: center;
  display: flex;
}
html .mat-mdc-menu-panel:not(.dark) .mat-mdc-menu-content .mat-mdc-menu-item i, html .mat-mdc-menu-panel:not(.dark) .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text i {
  width: 20px;
}
html .mdc-menu-surface.mat-mdc-select-panel {
  min-width: fit-content;
}
html .mdc-menu-surface.mat-mdc-select-panel .mat-mdc-option {
  line-height: 36px;
  min-height: 36px;
  font-size: 1.1rem;
  padding: 0 16px;
}
html .mdc-menu-surface.mat-mdc-select-panel .mat-mdc-option .mdc-list-item__primary-text {
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
html .mdc-menu-surface.mat-mdc-select-panel.full-height-select-panel {
  max-height: calc(100vh - 72px);
}
html .mat-mdc-paginator, html .utils-bar .mat-mdc-paginator {
  background: inherit;
  --mdc-theme-surface: inherit;
}
html .mat-mdc-paginator .mat-mdc-paginator-container, html .utils-bar .mat-mdc-paginator .mat-mdc-paginator-container {
  min-height: 36px;
}
html .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-range-actions, html .utils-bar .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-range-actions {
  height: 28px;
}
html .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-icon-button, html .utils-bar .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-icon-button {
  border-radius: 0;
}
html .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-icon-button:hover:enabled, html .utils-bar .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-icon-button:hover:enabled {
  background-color: #bebebe;
}
html .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-range-label, html .utils-bar .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-range-label {
  white-space: nowrap;
}
html .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field, html .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field .mat-mdc-form-field-infix, html .utils-bar .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field, html .utils-bar .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field .mat-mdc-form-field-infix {
  height: 28px;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;
  text-align: center;
}
html .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field .mat-mdc-text-field-wrapper, html .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field .mat-mdc-form-field-infix .mat-mdc-text-field-wrapper, html .utils-bar .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field .mat-mdc-text-field-wrapper, html .utils-bar .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field .mat-mdc-form-field-infix .mat-mdc-text-field-wrapper {
  height: inherit;
}
html .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-select-value, html .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field .mat-mdc-form-field-infix .mat-mdc-text-field-wrapper .mat-mdc-select-value, html .utils-bar .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-select-value, html .utils-bar .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field .mat-mdc-form-field-infix .mat-mdc-text-field-wrapper .mat-mdc-select-value {
  margin-top: 4px;
}
html .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field .mdc-notched-outline, html .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field .mat-mdc-form-field-infix .mdc-notched-outline, html .utils-bar .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field .mdc-notched-outline, html .utils-bar .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-form-field .mat-mdc-form-field-infix .mdc-notched-outline {
  display: none;
}
html .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-select, html .utils-bar .mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-select {
  background: transparent;
}
html .mat-mdc-radio-button .mdc-radio__native-control:enabled:focus + .mdc-radio__background .mdc-radio__outer-circle,
html .mat-mdc-radio-button .mdc-radio__native-control:enabled:focus + .mdc-radio__background .mdc-radio__inner-circle,
html .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
html .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__inner-circle,
html .mat-mdc-radio-button .mdc-radio:active .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
html .mat-mdc-radio-button .mdc-radio:active .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__inner-circle,
html .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #d84727;
}
html .mat-mdc-select:not(.account-picker-select) {
  font-weight: normal;
  height: 28px;
  --mdc-typography-body1-line-height: $form-element-height;
  --mdc-select-outline-color: $form-element-border;
  --mdc-select-hover-outline-color: $form-element-border;
  --mdc-select-focus-outline-color: $form-element-border;
}
html .mat-mdc-select:not(.account-picker-select) .mat-mdc-select-placeholder {
  color: #7b7b7d;
}
html .mat-mdc-select-disabled .mat-mdc-select-value {
  color: #3a3a3c;
}
html .form-dialog .mat-mdc-form-field:not(.show-select-arrow):not(.mat-mdc-paginator-page-size-select) .mat-mdc-select:not(.mat-mdc-select-disabled):not(.calendar-select) .mat-mdc-select-arrow-wrapper,
html .bordered-select .mat-mdc-form-field .mat-mdc-select:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow-wrapper,
html .form-dialog .mat-mdc-select:not(.mat-mdc-select-disabled):not(.calendar-select):not(.show-select-arrow) .mat-mdc-select-arrow-wrapper,
html .bordered-select .mat-mdc-select:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow-wrapper {
  background: #7b7b7d;
  height: 26px;
  transform: translateY(0);
  width: 28px;
}
html .form-dialog .mat-mdc-form-field:not(.show-select-arrow):not(.mat-mdc-paginator-page-size-select) .mat-mdc-select:not(.mat-mdc-select-disabled):not(.calendar-select) .mat-mdc-select-arrow-wrapper > div,
html .bordered-select .mat-mdc-form-field .mat-mdc-select:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow-wrapper > div,
html .form-dialog .mat-mdc-select:not(.mat-mdc-select-disabled):not(.calendar-select):not(.show-select-arrow) .mat-mdc-select-arrow-wrapper > div,
html .bordered-select .mat-mdc-select:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow-wrapper > div {
  color: #e6e4e1;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 9px solid;
  margin-top: 8px;
  margin-left: 7px;
  height: 18px;
  box-sizing: border-box;
}
html .form-dialog .mat-mdc-form-field:not(.show-select-arrow):not(.mat-mdc-paginator-page-size-select) .mat-mdc-select:not(.mat-mdc-select-disabled):not(.calendar-select) .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow svg,
html .bordered-select .mat-mdc-form-field .mat-mdc-select:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow svg,
html .form-dialog .mat-mdc-select:not(.mat-mdc-select-disabled):not(.calendar-select):not(.show-select-arrow) .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow svg,
html .bordered-select .mat-mdc-select:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow svg {
  display: none;
}
html .form-dialog .mat-mdc-select.calendar-select:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow-wrapper > .mat-mdc-select-arrow,
html .form-dialog .mat-mdc-form-field.mat-mdc-paginator-page-size-select .mat-mdc-select:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow-wrapper > .mat-mdc-select-arrow {
  color: #3a3a3c;
  border: unset;
  margin: unset;
}
html .form-dialog .mat-mdc-select.calendar-select:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow-wrapper > .mat-mdc-select-arrow svg,
html .form-dialog .mat-mdc-form-field.mat-mdc-paginator-page-size-select .mat-mdc-select:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow-wrapper > .mat-mdc-select-arrow svg {
  display: inline-block;
}
html .form-dialog .mat-mdc-form-field .mat-mdc-select,
html .bordered-select.mat-mdc-form-field .mat-mdc-select {
  border: 1px solid #6d6e70;
  display: block;
}
html .mat-mdc-table td.mat-mdc-cell:not(.formula-builder-cell) .mat-mdc-select {
  border: none;
  background: transparent;
}
html .mat-mdc-table td.mat-mdc-cell:not(.formula-builder-cell) .mat-mdc-select .mat-mdc-select-arrow-wrapper {
  background: transparent !important;
}
html .mat-mdc-table td.mat-mdc-cell:not(.formula-builder-cell) .mat-mdc-form-field:not(.show-select-arrow):not(.mat-mdc-paginator-page-size-select) .mat-mdc-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  display: none;
}
html .mat-mdc-table td.mat-mdc-cell:not(.formula-builder-cell) .mat-mdc-form-field:not(.show-select-arrow):not(.mat-mdc-paginator-page-size-select) .mat-mdc-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow svg {
  display: none;
}
html .mat-mdc-table td.mat-mdc-cell:not(.formula-builder-cell) .mat-mdc-form-field.show-select-arrow .mat-mdc-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow,
html .mat-mdc-form-field.mat-mdc-paginator-page-size-select .mat-mdc-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  display: inline-block;
  color: #3a3a3c;
  margin: 0;
  border: 0;
}
html .mat-mdc-table td.mat-mdc-cell:not(.formula-builder-cell) .mat-mdc-form-field.show-select-arrow .mat-mdc-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow svg,
html .mat-mdc-form-field.mat-mdc-paginator-page-size-select .mat-mdc-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow svg {
  display: inline-block;
}
html .mat-mdc-table td.mat-mdc-cell .mat-mdc-form-field.show-select-arrow .mat-mdc-select-disabled .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  color: var(--mat-select-disabled-arrow-color);
}
html .form-dialog .mat-mdc-paginator .mat-mdc-form-field.mat-mdc-paginator-page-size-select .mat-mdc-select {
  border: none;
}
html .form-dialog .mat-mdc-paginator .mat-mdc-form-field.mat-mdc-paginator-page-size-select .mat-mdc-select .mat-mdc-select-arrow-wrapper {
  background: transparent;
}
html .date-period .mat-mdc-select .mat-mdc-select-arrow-wrapper {
  background: transparent;
}
html .row > .mat-mdc-form-field-type-mat-select {
  height: min-content;
}
html .mat-mdc-table {
  border: 1px solid #c6c6c7;
}
html tr.mat-mdc-header-row {
  height: 36px;
}
html th.mat-mdc-header-cell, html .handsontable thead th, html .ht_clone_left thead th, html .ht_clone_top thead th {
  background: white;
  height: 36px;
  padding: 4px 0 4px 4px;
  border: solid #c6c6c7;
  border-width: 0 1px 1px 0;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}
html th.mat-mdc-header-cell .mat-sort-header-container, html .handsontable thead th .mat-sort-header-container, html .ht_clone_left thead th .mat-sort-header-container, html .ht_clone_top thead th .mat-sort-header-container {
  justify-content: center;
}
html .mat-mdc-table tr.mat-mdc-row, html .mat-mdc-table tr.mat-mdc-header-row {
  height: 30px;
}
html .mat-mdc-table tr.mat-mdc-row td.mat-mdc-cell, html .mat-mdc-table tr.mat-mdc-header-row td.mat-mdc-cell {
  border-color: #c6c6c7;
  padding: 0 8px;
  vertical-align: middle;
}
html .mat-table.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
html .mat-table.table-striped td.mat-mdc-cell {
  border: none;
}
html .cdk-overlay-container {
  --mdc-tooltip-container-padding: 10px;
}
html .cdk-overlay-container .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: rgba(58, 58, 60, 0.9);
  --mdc-plain-tooltip-supporting-text-color: white;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-supporting-text-size: 16px;
  --mdc-plain-tooltip-supporting-text-weight: 500;
  --mdc-tooltip-word-break: normal;
}
html .cdk-overlay-container .mat-mdc-tooltip .mdc-tooltip__surface {
  padding: 10px !important;
}
html .mdc-snackbar {
  --mdc-snackbar-container-color: white;
  --mdc-snackbar-supporting-text-color: mat.$light-primary-text;
}
html .wire .mat-mdc-slide-toggle, html .wire .mat-mdc-slide-toggle.mat-accent {
  --mdc-typography-body1-font-size: 14.4px;
  --mdc-typography-body1-line-height: 16px;
  --mdc-typography-body1-font-weight: 500;
  --mdc-typography-body1-letter-spacing: normal;
  --mdc-switch-state-layer-size: 0;
  --mdc-switch-handle-surface-color: #a1a1a2;
  --mdc-switch-handle-hover-surface-color: #a1a1a2;
  --mdc-switch-unselected-track-color: #a1a1a2;
  --mdc-switch-unselected-hover-track-color: #a1a1a2;
  --mdc-switch-unselected-icon-color: transparent;
  --mdc-switch-selected-icon-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #d84727;
  --mdc-switch-selected-handle-color: #d84727;
  --mdc-switch-selected-hover-state-layer-color: #d84727;
  --mdc-switch-selected-pressed-state-layer-color: #d84727;
  --mdc-switch-selected-focus-handle-color: #ce371d;
  --mdc-switch-selected-hover-handle-color: #ce371d;
  --mdc-switch-selected-pressed-handle-color: #ce371d;
  --mdc-switch-selected-focus-track-color: #e77d65;
  --mdc-switch-selected-hover-track-color: #e77d65;
  --mdc-switch-selected-pressed-track-color: #e77d65;
  --mdc-switch-selected-track-color: #e77d65;
}
html .wire .mat-mdc-slide-toggle .mdc-form-field .mdc-switch__icons, html .wire .mat-mdc-slide-toggle.mat-accent .mdc-form-field .mdc-switch__icons {
  display: none;
}