@import url('../../../../style/variables.less');

div table.custom-table {
  border-collapse: collapse;
  margin: 10px 0;
  width: 100%;

  td {
    padding: 5px;
    border: 2px solid @table-border-grey;
  }

  .head, .head td {
    font-weight: bold;
    border-width: 2px;
  }

  .left, td.left {
    text-align: left;
  }

  .center, td.center {
    text-align: center;
  }

  .head td.border-left, td.head.border-left {
    border-left-width: 3px !important;
  }

  td.border-right {
    border-right-width: 2px !important;
  }

  td.blank {
    border: none;
  }

  .light-yellow-back, td.light-yellow-back {
    background: #fae76e;
  }

  .light-blue-back, td.light-blue-back {
    background: #8bc9be;
  }

  .light-pink-back, td.light-pink-back {
    background: #fcc0c0;
  }

  .light-green-back, td.light-green-back {
    background: #91bd7e;
  }

  .light-orange-back, td.light-orange-back {
    background: #f2bd8f;
  }

  .lime-back, td.lime-back {
    background: #8ac728;
  }

  .grey-back, td.grey-back {
    background: #c2c7b9;
  }
}

.mat-mdc-table {
  min-width: 100%;
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}


.resize-handle {
  display: inline-block;
  border-right: 2px solid darkorange !important;
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1000px;
  cursor: col-resize;
  //opacity: 0;
  width: 18px;
  text-align: center;

  i {
    color: lightgoldenrodyellow;
    background: darkorange;
    padding: @form-element-outer-padding;
    margin-top: 4px;
    width: 18px;
  }
}

th div.resize-col {
  background-color: #ccc;
  position: absolute;
  right: -2px;
  top: 0;
  width: 4px;
  height: 100%;
  cursor: w-resize;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .fa-grip-vertical {
    background-color: #ccc;
    color: gray;
    padding: 4px 2px;
  }
}

td.mat-mdc-cell.index-column {
  width: 40px;
  min-width: 40px;
  padding: 0;
  vertical-align: middle;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: #eee;
    border: 1px outset;
    height: 100%;

    i.fa-asterisk {
      visibility: hidden;
      font-size: 0.7em;
      color: #3a3a3a;
    }

    i.fa-asterisk.new-row {
      visibility: visible;
    }
  }

  > div.dirty {
    background: peachpuff;
  }

  > div.success {
    background: lightgreen;
    animation-name: successAnimate;
    animation-duration: 2s;
    animation-delay: 5s; /*uncomment this for delays*/
    animation-fill-mode: forwards;
  }

  > div.error {
    background: darkorange;
  }
}

td.select-column, th.select-column {
  text-align: center;
  width: 30px;
  min-width: 30px;
  border-right: 0;
}


table.mat-mdc-table tr.mat-mdc-header-row {
  th:not(.mat-sort-header) {
    padding: 4px;
  }

  th.mat-sort-header {
    padding: 4px 4px 4px 21px;
  }
}

td.mat-mdc-cell:first-of-type {
  padding: 8px;
}

//From bootstrap
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-striped > tbody {
  td {
    border: none;
  }
}

.table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
  border: 1px solid @table-border-grey;
}

//End from bootstrap

.simple-table-container {
  background: white;
  border: 1px solid @table-border-grey;
}

.dashboard-tile .simple-table {
  tr.mat-row {
    height: unset;
  }
}

.simple-table {
  border: 1px solid @table-border-grey;
  width: 100%;

  thead {
    background: white;

    tr, th {
      height: 30px;
    }
  }

  th {
    padding: 0 6px;
    background: white;
  }

  td {
    height: 30px;
    vertical-align: middle;
    padding: 0 8px;
  }

  td.header-cell {
    font-weight: bold;
  }

  /*absolute width must be set in td style to override this*/

  td.ellipsis {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  tr:nth-child(even) td {
    background: #ffffff;
  }

  tr:nth-child(odd) td {
    background: #f5f5f5;
  }

}

.column-width-input {
  background: white;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 6px 10px;

  label {
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 10px;
  }

  input {
    width: 200px;
    height: 30px;
    font-size: 1.1em;
    border: 1px solid #ccc;
  }
}

.form-dialog div.utils-bar .row {
  min-height: unset;
  height: @header-unit;
}


div.utils-bar, .form-dialog div.utils-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: transparent;
  height: @header-unit;

  .table-filter {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    margin-left: 2px;
    > form > div {
      align-items: center;
    }

    select-many-filter {
      height: 32px;
    }

    .mat-mdc-form-field.no-label .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      padding-top: 2px;
    }

    .mat-mdc-select, input {
      background: white;
      font-size: 1rem;
      font-family: @body-font;
      font-weight: normal;
      width: 180px;
      max-width: 180px;
      //margin: 2px 4px 2px 4px;
      border: 1px solid @table-border-grey;
      border-radius: @input-element-border-radius;
      height: @unit;
      padding: @input-padding-min;
      box-sizing: border-box;

      .mat-mdc-select-trigger {
        height: 100%;
        text-align: left;
      }
    }

    input {
      margin: 0;
    }

    .mat-mdc-select {
      margin-right: 2px;
    }

    .search-button {
      width: @unit;
      min-width: @unit;
      height: @unit !important;

      .mat-mdc-button-wrapper {
        height: inherit;
        display: flex;
        align-items: center;
      }

      &:disabled {
        background: transparent;
      }
    }

    .mat-icon.search-clear {
      position: relative;
      right: 32px;
      color: @tile-header;
      margin-right: -24px;
      cursor: pointer;
    }

    i.fa-times {
      position: absolute;
      right: 10px;
      font-size: 1.8rem;
      padding-top: 3px;
      color: @tile-header;
      cursor: pointer;
    }

    filter-table-button .filter-table-button {
      height: @unit !important;
    }

    .table-search-btn {
      height: @unit;
      margin: 0 20px 0 4px;
      min-width: 30px;
      width: 30px;
      padding: 0 4px;
    }

    .account-select {
      height: @unit;

      .mat-mdc-select {
        margin: 0;
      }
    }
  }

  .mat-mdc-paginator-page-size {
    align-items: center !important;
  }
}

.form-paginator {
  .mat-mdc-paginator {
    .mat-mdc-paginator-page-size {
      align-items: center;

      .mat-mdc-form-field {
        margin-right: 4px;
        min-width: unset;
        margin-bottom: 0;

        .mat-mdc-form-field-infix {
          margin-bottom: 0;
        }

        .mat-mdc-select {
          top: 0;
        }
      }

    }
  }

}

div.lock-indicator, td.lock-indicator {
  position: relative;
  height: 100%;

  i.lock-indicator-icon {
    display: none;
  }

  &:hover i.lock-indicator-icon {
    display: block;
  }
}

.lock-indicator-icon {
  position: absolute;
  font-size: 1em;
  color: @primary-colour;
  left: 0;
  padding: 0 2px;
  cursor: default;
  z-index: 1;
}
