@import url("../../../../../style/variables.less");

.snack-bar-confirm {
  min-width: 600px;
  align-items: center;
  justify-content: space-between;
  border-left: none;
  border-right: none;
  border-bottom: none;
  background-color: white;
  border-top: 3px solid @primary-colour;
  border-radius: @input-element-border-radius;
  z-index: 2000 !important;

  .mdc-snackbar__surface {
    width: 100%;
  }

  .action-class .confirm-container {
    display: flex;
    justify-content: flex-end;

  }
}
