@import url("../../../../style/variables.less");

.custom-snackbar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .message {
    padding-right: 14px;
    font-size: 16px;
    font-weight: 600;
  }

  .action-class {
    display: flex;
    justify-content: flex-end;
    text-align: right;

    button {
      padding: @button-padding;
      margin: 3px;
      font-weight: bold;
      height: @header-unit;
    }

    button:hover {
      cursor: pointer !important;
    }

    .snack-bar-dismiss-button {
      align-items: center;
      //background: transparent;
      width: 40px;
      min-width: unset;

      .snack-bar-icon {
        color: #888888;
        font-size: 1.8rem !important;
      }
    }
  }
}

.user-status-container {
  min-width: 600px;

  .custom-snackbar {
    display: flex;
    justify-content: space-between;

    .action-class {
      max-width: 70px;
      width: auto;
      display: block;
    }
  }
}

.upload-model-error-container {
  min-width: 600px;
}

.snack-bar-success, .snack-bar-error, .snack-bar-warning, .snack-bar-info {
  border-left: none;
  border-right: none;
  border-bottom: none;
  background-color: white;
  border-radius: @input-element-border-radius;
}

.snack-bar-success {
  border-top: 3px solid @success;
  color: @success;
}

.snack-bar-error {
  border-top: 3px solid @error;
  color: @error-text;
}

.snack-bar-warning {
  border-top: 3px solid @warning;
  color: @warning-text;
}

.custom-snackbar .message {
  .font-normal, &.font-normal {
    font-weight: 600;
    font-size: 1.1rem;
    color: @primary-colour;
  }
}

.custom-snackbar.calc-error-message {
  div.message:not(.detail) {
    line-height: 30px;
  }

  a {
    font-weight: 700;
    color: @wire-orange;
    cursor: pointer;
  }
}

.snack-bar-info {
  border-top: 3px solid @primary-colour;
  color: black;
}
