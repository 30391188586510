@import url('../../../../style/variables.less');

.formula-builder {
  border: 1px solid #ccc;
  margin-top: 20px;
  padding: 0 20px;

  h3 {
    background: white;
    width: 180px;
    text-align: center;
    margin: -10px 10px 10px 0px;
  }

  textarea {
    margin: @form-element-outer-padding;
  }
}

.btn-in-out {
  border: none;
  background: transparent;
  padding-left: 1px;
  padding-right: 1px;

  .mat-icon {
    color: #595859;
    font-size: 30px;
    cursor: pointer;
  }
}

.constant-property-table-container {
  padding: @form-element-outer-padding;
}

table.mat-mdc-table.constant-properties-table {
  //border: 1px solid @table-border-grey;
  overflow: auto;
  width: 100%;
  height: 100%;
  border-collapse: collapse;

  thead {
    background: white;

    tr:not(.event-detail-row) {
      height: 40px;
    }
  }

  th {
    padding: 0 6px;
    background: white;
    height: 40px;

    &.mat-mdc-header-cell {
      position: sticky;
      top: 0;
      z-index: 100;
    }
  }

  tr.mat-mdc-row td.mat-mdc-cell:not(.formula-builder-cell) {
    height: 30px;
    vertical-align: middle;
    text-align: left;
    border: solid @table-border-grey;
    border-width: 0 1px 1px 0;
    background: white;
    padding: 0;

    tr.mat-mdc-row td.mat-mdc-cell:last-of-type {
      border-right-width: 0;
    }

    &.icon-cell {
      text-align: center;
      padding: 4px;
    }

    input, textarea, > div, .mat-mdc-form-field, .mat-mdc-select {
      width: 100%;
      //height: 100%;
      box-sizing: border-box;
      border: none;
      padding: @input-padding-min;
      font-size: @body-font;
      font-weight: inherit;
      color: inherit;
    }
  }

  td.mat-mdc-cell, th.mat-mdc-header-cell {
    &.cdk-column-name {
      position: sticky;
      left: 34px;
      min-width: 180px;
    }

    &.cdk-column-edit {
      position: sticky;
      left: 0;
    }

    &.cdk-column-description {
      min-width: 180px;
    }
  }

  tr.new-row .mat-mdc-cell {
    &.cdk-column-name {
      left: 74px;
    }

    &.cdk-column-edit {
      min-width: 74px;
    }
  }

  th.mat-mdc-header-cell.cdk-column-name {
    z-index: 101;
  }

  tr:nth-child(even) td {
    background: #ffffff;
  }

  tr:nth-child(odd) td {
    background: #f5f5f5;
  }

  .formula-detail {
    textarea {
      height: 60px;
      margin-bottom: 0 !important;
    }
  }

  td .mat-mdc-form-field .mat-mdc-select {
    width: 100%;

    &.mat-mdc-select-disabled {
      background: white;
    }
  }

  i.fa-edit, i.fa-save, i.fa-times {
    background: #FFCD33;
    padding: 5px 4px 5px 5px;
    border-radius: 4px;
    color: white;
    cursor: pointer;
  }

  i.fa-times {
    margin-right: 2px;
    padding: 5px 6px;
    background: @primary-colour;
  }

  i.fa-save {
    padding: 5px 6px;
    width: 25px;
  }

  tr.disabled {
    input {
      background: inherit;
      border: none
    }

    i.fa-edit, i.fa-save, i.fa-times {
      background: @primary-colour;
    }

    mat-select {
      background: transparent;
    }

    textarea {
      background: transparent;
    }
  }

  textarea {
    height: 26px;
    margin-bottom: 0 !important;
  }

  tr.event-detail-row {
    height: 100px;

    formula-builder {
      display: block;
    }
  }

  tr.event-detail-row.detail-not-expanded {
    height: 0;

    formula-builder {
      display: none;
    }

    input {
      display: none;
    }

    td.mat-mdc-cell {
      padding: 0 !important;
      height: 0;
    }

    .formula-detail {
      display: none;
    }
  }

}
