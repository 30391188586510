@import '../../../style/variables.less';

.handsontable .relative {
  position: relative;
  text-align: left;

}

.handsontable.hot-template-anchor {
  height: 100% !important;

  .ht_master, .ht_master .wtHolder {
    height: inherit !important;
  }
}

table.htCore td, table.htCore tr th {
  white-space: nowrap;
  vertical-align: middle;
  //height: 30px !important;
  //padding: @input-padding-min;
  input.htCheckboxRendererInput {
    height: unset;
  }
}

.manualColumnResizer {
  z-index: 5000;
  top: 80px !important;
}

@media (min-width: 960px) {
  .btn-icon {
    margin: 10px 0 0 16px !important;
  }
}

.scroll-area {
  height: calc(100% - 44px);
}

.event-anchor {
  overflow: visible !important;
  height: 100%;
}

.custom-hot-date-renderer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .hot-date-renderer-click {
    justify-self: flex-end;
    margin: 4px;
    cursor: pointer;
  }

  .hot-date-renderer-click.disabled {
    display: none;
  }
}

.hot-date-renderer.disabled {
  height: 100%;
}

.hot-infinite-scroll {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .hot-infinite-scroll-click {
    justify-self: flex-end;
    margin: 4px;
    cursor: pointer;
  }

  .hot-infinite-scroll-click.disabled {
    cursor: default;

    &::before {
      color: @disabled;
    }
  }
}


.handsontable .htDimmed {
  background: @disabled;
}
