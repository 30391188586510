@use '@angular/material' as mat;
@import 'globals';

@mixin wire-paginator-styles($theme) {
  .mat-mdc-paginator, .utils-bar .mat-mdc-paginator {
    background: inherit;
    --mdc-theme-surface: inherit;

    .mat-mdc-paginator-container {
      min-height: $header-unit;

      .mat-mdc-paginator-range-actions {
        height: $unit;
      }

      .mat-mdc-icon-button {
        border-radius: 0;
      }

      .mat-mdc-icon-button:hover:enabled {
        background-color: #bebebe;
      }

      .mat-mdc-paginator-range-label {
        white-space: nowrap;
      }

      .mat-mdc-form-field, .mat-mdc-form-field .mat-mdc-form-field-infix {
        height: $unit;
        margin-bottom: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-top: 0;
        text-align: center;

        .mat-mdc-text-field-wrapper {
          height: inherit;

          .mat-mdc-select-value {
            margin-top: 4px;
          }
        }

        .mdc-notched-outline {
          display: none; // Hide the notched outline
        }
      }

      .mat-mdc-select {
        background: transparent;
      }

      .mat-mdc-form-field mat-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
        //color:unset;
        //border-width:5px;
      }
    }

  }
}
