@import url("../../style/variables.less");
@import url("shared/styles/base-style");
@import url("../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.css");
@import url("../../node_modules/@fortawesome/fontawesome-free/css/solid.css");
@import url("../../node_modules/font-awesome/css/font-awesome.css");
@import url("../../node_modules/quill/dist/quill.snow.css");
//@import url("../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css");
//
* { //Taken from bootstrap - padding can be applied to inside of final width yay
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

input.mat-mdc-input-element {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: @font-size;
}

@media screen and (max-width: @media-xs) {
  html {
    font-size: 12px;
  }
}

body {
  font-family: @body-font !important;
  font-weight: 500;
  background: @background;
  color: black;
  height: inherit;
  //overflow-x:scroll;
  > app-root > div {
    height: 100%;
  }
}

.body.logged-out {
  background: url("/static/images/img1-large.jpg") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .mat-mdc-tab-body {
    padding-bottom: 25px !important;
  }
}

notifications {
  display: none;
}

.icon-arrow-down {
  background-image: url('../../icons/arrow_drop_down.svg') !important;
  height: 24px;
  width: 24px;
  display: block;
}

@media (min-width: @media-m) {
  .mat-toolbar h1.page-title-header {
    color: @contrast-colour;
    font-family: @header-font;
    -webkit-align-items: center;
    font-size: 1.6rem;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  div.page-title:not(.no-dtp) {
    button {
      background: transparent;

      span i, span .mat-icon {
        color: @primary-colour;
        padding-top: 4px;

        :hover {
          color: @primary-colour-hover;
        }
      }
    }

    button.icon-download {
      background-image: url('../../icons/dwnld_con.svg') !important;
    }

    button.icon-refresh {
      background-image: url('../../icons/refresh_con.svg') !important;
    }

    button.icon-comment-invert {
      background-image: url('../../icons/comment-invert.svg') !important;
      background-size: 100% !important;
      background-repeat: no-repeat !important;
      margin-top: 1px !important;
      //background-color: @primary-colour !important;

    }
  }

  div.page-title {
    position: fixed;
    top: 48px;
    z-index: 162;
    right: 30px;
    width: auto;

    h1 {
      display: none;
    }

    .buttons {
      left: unset;
      right: 0;
      top: -2px;

    }
  }
}

@media (max-width: @media-m) {
  h1.page-title-header {
    display: none;
  }

  .page-title.no-dtp {
    top: 0px;
  }
}

//@media (max-width: @media-sm){
//  .mat-menu-panel.header-menu {
//    margin-right: 40px;
//  }
//}
.sidenav-container {
  position: absolute;
  top: @mat-toolbar-height;
  bottom: 0;
  left: 0;
  right: 0;
}

.mat-drawer-container {
  position: absolute !important;
}

.mat-drawer-inner-container {
  background: @primary-colour;
  height: 100% !important;
  padding-bottom: 50px;
}

.mat-drawer-content {
  height: 100vh;
}

sidenavigation {
  min-width: 360px;
  display: block;
}

@media (max-width: @media-xs) {
  .mat-drawer {
    width: 100%;
  }
}

.mat-drawer-content {
  padding-top: 0;
}


//}

.page-title {
  -webkit-align-items: center;
  font-family: @header-font;
  position: absolute;
  top: @date-period-height + 4px;
  z-index: (@z-index-init);
  width: 100%;
  height: @header-unit;
  -webkit-transition: top @transition-duration; /* Safari */
  transition: top @transition-duration;

  h1 {
    position: absolute;
    background: @primary-colour;
    color: @contrast-colour;
    height: @header-unit;
    padding-top: 8px;
    width: 40%;
    left: 30%;
    min-width: (@header-unit * 8);
    border-radius: 0px 0px 8px 8px;
    display: inline-block;
    font-size: 1.166rem;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    margin: 0;
    line-height: @header-line;
    overflow-x: hidden;
    white-space: nowrap;

    span {
      //display: none;
      text-transform: none;
    }

  }

  > div {
    position: absolute;
    left: 70%;
    @media (max-width: @media-xs) {
      position: static;
    }
  }

  button {
    //position: relative;
    margin: 0px 0px 0px 12px;
    width: @header-unit;
    min-width: @header-unit;
    background: @primary-colour;

    @media (max-width: @media-md) {
      margin-left: 6px;
    }
    height: @header-unit;
    min-height: @header-unit;
    display: inline-block;
    border-radius: 0px 0px 4px 4px;
    //@media (max-width:@media-xs){
    //  display:none;
    //}
  }

  button.icon-comment-invert {
    background-image: url('../../icons/comment-transparent.svg') !important;
    background-size: 100% !important;
  }

  button:hover {
    background-color: @primary-colour-hover;
  }

  .md-button:hover {
    background: rgba(236, 104, 59, 1) !important;
  }
}

@media (max-width: @media-xs) {
  .page-title {
    justify-content: center;

    h1 {
      position: static;
      min-width: 270px;
      margin-left: 10px;

    }

  }
}

@media (max-width: @media-xs) {
  div.page-title {
    top: 0;
    transition: none;
  }

  .visible-dtp div.page-title {
    top: 80px;
  }

}

.page {
  //background: white;
  padding: @page-padding;
  padding-top: (@toolbar-height);

  @media screen and (min-width: @media-m) {
    padding-top: (@header-unit);
  }
  @media screen and (max-width: @media-xs) {
    padding-left: @mobile-padding;
    padding-right: @mobile-padding;
  }
}

div.new-line {
  display: none;
}

div.view-page-divider {
  display: none;
}

.mat-divider {
  border-color: @table-border-grey;
}

.drag_orientation {
  display: none;
}

.page.editing-mode .section-title button, edit-section > div button.mat-mdc-button, button.mat-mdc-button.formatting-button {
  margin: 0 5px;
  background: @tile-header;
  width: @header-unit;
  min-width: @header-unit;
  height: @header-unit;
  padding: 0;
  color: white;
  font-size: 1rem;
  font-weight: normal;
}

button.mat-mdc-button.formatting-button, div.formatting-label {
  margin-top: 5px;
  background-color: transparent;

  &.active {
    background-color: @wire-orange;
  }
}

.mat-mdc-button.mat-mdc-button-disabled {
  background-color: #ccc;
}

.page.printing-mode {
  .displayonprint {
    display: block;
  }

  .inline-button-orange {
    background-color: orange;
    padding: 4px 6px;
    border-radius: 2px;
    margin-left: 10px;
    margin-top: -4px;
  }

  .section-title {
    display: flex;
  }

  edit-section > div {
    background: white;
    border: 1px solid #ccc;
    padding: 10px;
    width: 1000px;
    margin-bottom: 10px;

    > div.row {
      justify-content: space-between;
    }

    input, mat-mdc-select {
      border: 1px solid #ccc;
      margin-top: 20px;
      height: 24px;
      padding: 2px;
    }
  }

  div.break-before::before, div.break-after::after {
    display: block;
    content: "Page break";
    width: 100%;
    color: orangered;
    margin-top: 10px;
    border-top: 2px dashed orangered;
  }

  div.break-after::after {
    margin-top: 10px;
  }

  .section {
    border: 1px dashed #404041;
    align-items: unset; //This aligns tiles to top and bottom
    > div {
      align-items: unset; //This aligns tiles to top and bottom
      > div.cdk-drag {
        align-self: unset; //This prevents the override of align-items above on section.auto tiles
        //width:auto; //Implement this as an option per section for tiles with scroll ??
      }
    }
  }

  .section-area, .tile-area {
    border-top: 1px dashed #ef7a3d;
    margin-top: 20px;
    //margin-bottom: -40px;
    width: 100%;
    text-align: right;

    .section-area-title {
      color: #ef7a3d;
      width: 100%;
      text-align: center;
      position: relative;
      top: -10px;

      span {
        background: white;
        padding: 0 20px;
      }
    }

    button {
      margin: 0 10px -34px 0px;
      position: relative;
      background: #ef7a3d;
      width: 36px;
      min-width: 36px;
      padding: 0px;
      color: white;
      top: -34px;
      /* text-align: right; */
      font-size: 1rem;
      font-weight: normal;
    }

    .mat-mdc-checkbox {
      position: relative;
      top: -34px;
      background: white;
      color: #ef7a3d;
      font-weight: bold;
    }

    .mat-mdc-checkbox-checked .mat-mdc-checkbox-background {
      background: #ef7a3d;
    }
  }

  .drag_orientation {
    display: block;
    margin-bottom: 10px;

    .mat-mdc-radio-label {
      font-weight: bold;
    }

    .mat-mdc-radio-container {
      margin-left: 10px;
    }

    .mat-mdc-radio-checked .mat-mdc-radio-inner-circle {
      background-color: orange;
    }
  }

  .tile-area {
    border: none;
  }
}

.page.printing-sections-mode {
  print-formatter > div {
    text-align: right;
  }

  button {
    background: #ef7a3d;
    width: 36px;
    min-width: 36px;
    padding: 0px;
    color: white;
    font-size: 1rem;
    font-weight: normal;
  }

  .section-area-title {
    display: none;
  }
}

as-split-area.as-split-area {
  height: 100%;
}

/**pages with grister use different padding rules - see page-view.less**/
.page.traveler {
  padding: @page-padding;
  padding-top: (@header-unit + @toolbar-height);

  @media screen and (min-width: @media-m) {
    padding-top: (@toolbar-height);
  }
  @media screen and (max-width: @media-xs) {
    padding-left: @mobile-padding;
    padding-right: @mobile-padding;
    height: calc(100vh - (@header-unit + @date-period-height));
  }
}

@media screen and (max-width: @media-xs) {
  as-split-area.as-split-area {
    height: 100% !important;
    margin-top: 0 !important;
  }

  page-view .page.traveler {
    height: calc(100vh - (@header-unit + @date-period-height));
  }

  .visible-dtp .page.traveler {
    padding-top: (@header-unit * 3.5);
  }
}

div.body.layout-fill {
  height: auto;
}


.section-title {
  font-family: @body-font;
  font-weight: 600;
  color: #595859;
  width: 100%;
  font-size: 2.66rem;
  margin: 10px 0px;
  padding: 0 1%;
}

.bold {
  font-weight: bold !important;
}

h3 {
  text-transform: uppercase;
  display: block;
  font-weight: 500;
  color: @primary-colour;
}

button {
  font-size: 1rem;
}

.handsontable td {
  text-align: unset;
  text-overflow: ellipsis;
}

//Theme positioning styles
.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  background: rgba(0, 0, 0, 0.3);
}

.transparent-backdrop {
  opacity: 0 !important;
}

.header-menu-backdrop {
  opacity: 0 !important;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100% !important;
  max-height: 100%;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  background: rgba(0, 0, 0, 0.3);
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
}

.invisible {
  visibility: hidden;
}

.mat-snack-bar-container {
  background-color: white;
  color: black;
  font-size: 1.2rem;
  font-weight: 700;
  max-width: 50vw !important;
  transform-origin: center;

  button.mat-mdc-button {
    background: transparent;
    color: #888888;
    font-weight: 700;
  }

  button.mat-mdc-button:hover {
    background: #ccc;
  }
}

.mat-snack-bar-container.warning {
  background-color: @medium-gradient;
  color: @primary-colour;
}

.mat-snack-bar-container.general {
  background-color: #666666;
  color: @contrast-colour;
}

.select-search-many {
  overflow: hidden !important;
  position: relative;
}


//.mat-mdc-form-field.search {
//  padding: 0 16px;
//  font-weight: normal;
//  color: @tile-header;
//  display: flex;
//  justify-content: space-between;
//  margin: 0;
//
//  input {
//    top: 0;
//    border: none;
//    background: transparent;
//  }


.search {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  .mat-mdc-form-field {
    width: 100%;
    margin-bottom: 0;

    input {
      width: 100%;
      //border: 1px solid #ccc !important;
      line-height: 30px !important;
      top: 0 !important;
      padding-left: 2px;
    }
  }

  .mat-mdc-form-field-label {
    margin-left: 4px;
  }

  .mat-mdc-form-field-infix {
    margin-bottom: 0;
  }

}

.select-menu-panel {
  .search input {
    border: 1px solid #ccc
  }
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: 0 0;
}

.mat-button, .mat-fab, .mat-flat-button, .mat-icon-button, .mat-mini-fab, .mat-raised-button, .mat-stroked-button {
  font-family: @header-font;
  font-weight: 500;
}

.search > div {
  right: 36px;
  position: absolute;
  margin-left: 10px;

  i {
    font-size: 2em;
    color: #8b8c8d;
    vertical-align: middle;
    width: 22px;
    padding-left: 8px;
    cursor: pointer;
  }
}

[hidden] {
  visibility: hidden !important
}

mat-list {
  list-style: none;
}

.mat-elevation-z4 {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}

.cp-preset-colors-class {
  display: inline-flex;
  flex-wrap: wrap;
}

.constant-series-table table {
  td {
    border: 1px solid #ccc;

    input {
      border: 0;
      background: rgba(100, 100, 100, 0.2);
    }
  }
}

.inline-button button {
  border: 2px solid;
  border-radius: 4px;
  margin-right: 4px;
  min-width: 0;
}

.warning {
  color: #ef7a3d;
}

.error {
  color: #E93E3A;
}

.missing {
  opacity: 0.5;
}

.warning-none {
  display: none;
}

//**************************************************************//
.mat-checkbox-frame, .mat-pseudo-checkbox {
  border-width: 1px !important;
  border-radius: 0 !important;
}

.mat-checkbox-checked .mat-checkbox-background, .mat-option.mat-selected .mat-pseudo-checkbox {
  background: #d84325;
  border-radius: 0;
  color: white;
}

.mat-checkbox-checked.mat-checkbox-disabled .mat-checkbox-background, .mat-option.mat-selected .mat-pseudo-checkbox {
  path {
    stroke: @secondary-color-grey;
  }
}

.form-dialog input:disabled {
  background: @disabled;
}

.mat-checkbox-disabled .mat-checkbox-background, .mat-checkbox-disabled .mat-checkbox-frame {
  background: transparent;
  border-color: @tile-header;
}

.favourable {
  color: green;
}

.unfavourable {
  color: red;
}

//default size for forms
.cdk-overlay-pane .mat-mdc-dialog-container {
  width: 1000px;
  height: 600px;
}

.hot-infinite-scroll-dialog {
  visibility: hidden;
}

.tile-form-dialog, .default-form-dialog {
  .mat-mdc-dialog-container {
    width: 94vw;
    height: 90vh;
  }
}

/**For overriding default tile-form-dialog size, use the component specific dialog name**/
.estimate-form-dialog, .ore-body-form-dialog, .upload-form-dialog, .edit-history-form-dialog {
  .mat-mdc-dialog-container {
    width: 1200px;
    height: 620px;
  }
}

.series-form-dialog {
  .mat-mdc-dialog-container {
    max-width: 94vw;
  }
}

.copy-tile-form-dialog {
  .mat-mdc-dialog-container {
    width: 500px;
    height: 240px;
  }

}

.report-issue-form-dialog {
  .mat-mdc-dialog-container {
    width: 630px;
    height: 90vh;
    overflow: hidden;
    min-width: 550px !important;

    .form-dialog {
      min-width: 550px !important;
    }
  }
}

.default-chart-dialog {
  .mat-mdc-dialog-container {
    width: 1200px;
    height: 700px;
  }
}

.mat-mdc-dialog-container:after {
  content: "";
  position: absolute;
  z-index: 900;
}

//Scroll for dialogs is on mat-mdc-tab-body
.mat-mdc-dialog-container {
  .ql-editor h1 {
    height: unset;
  }

  .action-buttons {
    position: absolute;
    right: 16px;
    bottom: 10px;
  }
}

.confirm-dialog-container .mat-mdc-dialog-container {
  width: 550px;
  height: auto;
  padding: 0 !important;
  min-height: unset !important;
  overflow: visible;
  min-width: unset !important;
  resize: none;
}

#custom-dialog, #comment-prompt-dialog {
  width: auto;
  height: auto;
  min-width: 0 !important;
  min-height: 0 !important;
  background: transparent;
  padding: 0 !important;
  resize: none;
}

.form-dialog.tile-form-dialog {
  .mat-mdc-tab-body {
    padding: @header-unit @header-unit 120px @header-unit;
  }
}

.form-dialog {
  .mat-mdc-dialog-content {
    //margin: 0;
    //padding: 0;
    //max-height: 100%;
    //position: absolute;
    //right: 24px;
    //left: 24px;
    //bottom: 56px;
    //top: 56px;
    ////border: 1px solid white;
  }

  .mat-mdc-tab-group {
    min-height: 220px;
    border-radius: @border-radius;
  }

  .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
    display: none;
  }

  .mat-mdc-tab-body.mat-mdc-tab-body-active {
    overflow-y: visible;
  }

  //.mat-mdc-dialog-content::-webkit-scrollbar {
  //  background: transparent;
  //}
  //
  //.mat-mdc-dialog-content::-webkit-scrollbar-track {
  //  margin-top: 42px;
  //}

  .mat-mdc-tab-labels .mat-mdc-tab {
    width: min-content;
    font-size: 1.166rem;
    font-weight: normal;
    height: 45px;
    color: #3a3a3c;
    min-width: 160px;
  }

  .mat-mdc-tab-labels .mat-mdc-tab-label.mat-mdc-tab-label-active {
    background: #e6e4e1;
    opacity: 1;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 2px 1px 2px rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
    color: @date-period-text;
  }

  .mat-mdc-tab-body-wrapper {
    &:extend(.base-mat-mdc-tab-body-wrapper);
    border-top-left-radius: 0;
    position: absolute;
    right: 0;
    left: 0;
    top: 45px;
    bottom: 0;
    overflow-y: visible;
  }

  .mat-mdc-tab-body-content {
    height: auto;
    overflow: unset;
  }

  .mat-mdc-tab-body {
    padding: @header-unit;

    .reset-password {
      margin-top: 0px;
    }
  }
}

.chart-dialog {
  //position: relative !important;

  .mat-mdc-dialog-content {
    margin: 0;
    padding: 0;

    default-chart, generic-chart {
      height: 460px;
    }
  }

  .mat-mdc-dialog-content.comment-drawer-open {
    default-chart, generic-chart {
      height: 360px;
    }
  }

  custom-chart {
    .comment-button {
      position: absolute;
      top: 440px;
      right: 0;

      .icon-comment {
        position: relative;
        bottom: 2px;
        right: 6px;
        //left: calc(100% - 40px);
      }
    }
  }

  //comment-drawer {
  //  position: relative;
  //  width: 100%;
  //  height: 120px;
  //
  //  .comment-drawer {
  //    position: static;
  //    border: none;
  //    width: inherit;
  //    margin-bottom: 0;
  //  }
  //}

}

.filter-dialog-panel {
  .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      padding: @form-padding;
    }

    min-width: 840px !important;
    width: fit-content !important;
    min-height: fit-content !important;
    height: fit-content !important;
  }
}


.app-password {
  display: flex;

  i {
    position: relative;
    left: -20px;
    align-self: center;
    cursor: pointer;
  }
}

.mat-mdc-form-field-infix .app-password {
  height: 22px;
  align-self: center;

  i {
    position: absolute;
    right: 12px;
    left: unset;
  }
}

.comment-button {
  visibility: hidden;
}

@media screen and (max-width: @media-xs) {

  .comment-button {
    visibility: visible;
  }
}

.mat-mdc-form-field-hide-placeholder:focus label {
  visibility: hidden;
}

///mat-slider styles from purple theme
.mat-mdc-slider-track-background {
  background-color: rgba(0, 0, 0, .26);
}

.mat-mdc-accent .mat-mdc-slider-thumb, .mat-mdc-accent .mat-mdc-slider-thumb-label, .mat-mdc-accent .mat-mdc-slider-track-fill {
  background-color: #ffd740;
}

.mat-mdc-slider-horizontal .mat-mdc-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(.0001deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent);
}

.mat-mdc-slider-min-value .mat-mdc-slider-focus-ring {
  background-color: rgba(0, 0, 0, .12);
}

.mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing) .mat-mdc-slider-thumb {
  border-color: rgba(0, 0, 0, .26);
  background-color: transparent;
}

.mat-mdc-accent .mat-mdc-slider-thumb, .mat-mdc-accent .mat-mdc-slider-thumb-label, .mat-mdc-accent .mat-mdc-slider-track-fill {
  background-color: #ffd740;
}

.mat-mdc-accent .mat-mdc-slider-thumb-label-text {
  color: rgba(0, 0, 0, .87);
}

.mat-mdc-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.drop-list {
  min-width: 300px;
  max-width: 100%;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.drop-box {
  padding: 4px 4px;
  border: solid 1px #ccc;
  border-bottom: 0;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drop-box:last-child {
  border: 1px solid #ccc;
}

.drop-list.cdk-drop-list-dragging .drop-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview .tip-box, .cdk-drag-preview .drag-hide {
  display: none;

}

.cdk-drag-preview.section-drag-list {
  background: white;
}

.flash-warning-button {
  animation: @flashing-warning-animation;

  &.is-icon {
    cursor: default;
  }
}

.quick-charts .drop-list {
  background: unset;
}

/* #### Mobile Phones Portrait or Landscape #### */
@media screen and (max-width: @media-xs) {
  /* some CSS here */
  body {
    //min-width:400px;
    //width: 100%;
  }

  .hide-xs {
    display: none !important;

  }

  .quick-charts {
    flex-direction: column;

    .select-search {
      width: 80vw;
    }

    .drop-list {
      margin-bottom: 20px;
      margin-top: 24px;
    }
  }

  .mat-mdc-select-panel {
    min-width: 98% !important;
  }
}

select-search {
  display: grid;
}

.mat-mdc-slide-toggle-bar {
  background: @tile-header;
}

.mat-mdc-slide-toggle-thumb {
  background: darkgray;
}

mat-mdc-slide-toggle.mat-checked .mat-mdc-slide-toggle-thumb, mat-mdc-slide-toggle.mat-checked .mat-mdc-slide-toggle-bar {
  background: @wire-orange;
}

.mat-mdc-slide-toggle-content {
  font-weight: 600;
}

.pika-time {
  margin-top: 10px;
  text-align: center;
}

.pika-select {
  height: 28px;
  width: 50px;
  font-size: 1em;
  border-color: #ccc;
  margin-left: 4px;
  margin-bottom: 4px;
}

.vdt-formula-title, .vdt-formula {
  padding: 4px;
  height: 25%;
  width: 180px;
  max-height: 30px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 1.13rem;
}

.vdt-formula {
  height: 50px;
  font-weight: normal;
  font-size: 1rem;
  white-space: normal;
  overflow: unset;
  word-wrap: break-word;
}

.dialog-with-comments {
  top: 0;
  left: 0;
  width: 70%;
}

.os-scrollbar-vertical, .os-scrollbar-horizontal {
  .os-scrollbar-handle {
    background: rgba(136, 136, 136, 0.7);
  }

  .os-scrollbar-handle:active, .os-scrollbar-handle:hover {
    background: @tile-header;
  }
}

//TODO - make this work properly
::-webkit-scrollbar, .page-list-view .list::-webkit-scrollbar,
.mat-mdc-tab-body::-webkit-scrollbar, .comment-list-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  margin-right: -@header-padding-min;
}

/* Track */
::-webkit-scrollbar-track, .page-list-view .list::-webkit-scrollbar-track,
.mat-mdc-tab-body::-webkit-scrollbar-track, .comment-list-container::-webkit-scrollbar-track {
  border-radius: @border-radius;
  background-clip: padding-box;
  margin-top: 1px;
}

.form-dialog.tile-form-dialog .mat-mdc-tab-body::-webkit-scrollbar-track {
  margin-bottom: 120px;
}

::-webkit-scrollbar-track-piece, .page-list-view .list::-webkit-scrollbar-track-piece,
.mat-mdc-tab-body::-webkit-scrollbar-track-piece, .comment-list-container::-webkit-scrollbar-track-piece {
  padding: 2px;
  width: 8px;
  height: 8px;
}

page-tile .no-format .tile-content.paragraph::-webkit-scrollbar-track-piece, page-tile .no-format .tile-content.custom::-webkit-scrollbar-track-piece {
  margin-top: 22px;
}

::-webkit-scrollbar-corner, .page-list-view .list::-webkit-scrollbar-corner,
.mat-mdc-tab-body::-webkit-scrollbar-corner, .comment-list-container::-webkit-scrollbar-corner {
  //background: #999999;
  border-bottom-right-radius: 6px;
}

/* Handle */

::-webkit-scrollbar-thumb, .page-list-view .list::-webkit-scrollbar-thumb,
.mat-mdc-tab-body::-webkit-scrollbar-thumb, .comment-list-container::-webkit-scrollbar-thumb {
  //border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 6px;
  background-color: rgba(136, 136, 136, 0.7);

  &:hover {
    background-color: @tile-header;
  }
}

::-webkit-scrollbar-button, .page-list-view .list::-webkit-scrollbar-button,
.mat-mdc-tab-body::-webkit-scrollbar-button, .comment-list-container::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.custom-chart-series-tooltip {
  position: relative;
  top: 10px;
  padding: 10px;
  opacity: 0.8;
}

//Presentation mode style overrides/////////////////////////////////////////////////
.large-font {
  .c3-axis-x text, .c3-axis-y2, .c3-axis-y text, tspan {
    font-size: 15px !important;
  }

  .dashboard-tile div.tile-header h2 {
    font-size: 20px !important;
  }

  .dashboard-tile div.tile-header {
    height: 40px !important;
  }

  .c3-legend-item {
    font-size: 14px !important;
    font-weight: normal !important;
    transform: translateY(9px);
  }

  .legend > div div.legend-name {
    font-size: 16px;
    font-weight: 500;

  }

  a, td, th, button.mat-mdc-sort-header-button {
    font-size: 15px !important;
  }

  .page-title > h1 {
    font-size: 20px !important;
  }

  //page-tile .no-format.text-editing quill-editor.text-editing {
  //  top: 40px;
  //}

  .mat-mdc-tooltip {
    font-size: 16px !important;
  }

  header .mat-mdc-toolbar .mat-mdc-button {
    padding: 0 13px !important;
    font-size: 20px !important;
  }

  .mat-mdc-menu-panel {
    max-width: unset !important;
  }

  .header-menu .mat-mdc-menu-content a.mat-mdc-menu-item {
    font-size: 17px !important;
  }

  header .brand img {
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 126px;
    height: 46px;
  }

  div.page-title {
    top: 45px;
  }

  @media (min-width: @media-m) {
    div.page-title:not(.no-dtp) {
      .buttons {
        top: 2px;
      }
    }

    div.page-title {
      right: 18px;

      .buttons {

        button {
          width: 40px;
          height: 40px;
          margin-left: 2px;

          span i {
            padding-top: 2px;
          }
        }
      }
    }
  }

}

////End .large-font//////////////////////////////////

div.welcome {
  height: 100%;

  div {
    height: 80%;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
  }
}

.at-who-container {
  display: none;
  position: absolute;
  z-index: 1100;
}

page-tile .dashboard-tile {
  .handsontable .htAutocompleteArrow {
    color: transparent;
    background-image: url('../../icons/arrow_drop_down.svg') !important;
    height: 24px;
    width: 24px;

    &:hover {
      color: transparent;
    }
  }

  .handsontable .htAutocomplete.htDimmed .htAutocompleteArrow {
    opacity: 0.4;
  }

  .handsontable td.area .htAutocompleteArrow {
    color: transparent;
  }

  .handsontable thead th, .ht_clone_left thead th, .ht_clone_top thead th {
    height: @header-unit !important;
    box-sizing: border-box;

    > div {
      display: flex;
      justify-content: center;
    }
  }
}

.handsontable .autocompleteEditor.handsontable {
  min-width: 230px;
  min-height: 200px;

  .ht_master.handsontable {
    min-width: 220px;
    min-height: 190px;

    .wtHolder {
      min-width: 220px;
      min-height: 180px;
      background: white;
      //overflow-x: visible;

      .wtHider {
        min-width: 200px;
        min-height: 160px;

        table {
          min-width: 210px;
          background: white;
          text-align: left;
          border: none;

          .listbox {
            background: white;
          }
        }
      }
    }
  }
}

.cdk-virtual-scroll-content-wrapper {
  width: 100%;
}

.loading-bar-fixed {
  div#loading-bar {

    div.bar {
      height: 3px;
      //background: gold !important;
    }
  }

  div#loading-bar-spinner {
    //color: gold !important;
    padding: 4px;

    .spinner-icon {
      height: 22px !important;
      width: 22px !important;
      border-width: 3px !important;
    }
  }
}

.app-loading-component {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  background: rgba(1, 1, 1, 0.1);
  z-index: 1000;
}


.loading {
}


.dtp-buffer {
  padding-top: (@header-unit * 2); //page padding + dtp padding

  @media (max-width: @media-xs) {
    padding-top: (@header-unit * 3); //page padding + dtp padding + title bar padding
  }
}
