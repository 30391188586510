@import url("../../../../style/variables.less");

.mat-mdc-select:not(.account-picker-select) {
  width: 100%;
  background: @form-element-background;
  overflow: hidden;

  .mat-mdc-select-value {
    padding: @input-padding-min;
  }

}

.mat-mdc-form-field.form-mat-field .mat-mdc-form-field-infix,
.select-search.form-mat-select, .select-search.form-mat-select .mat-mdc-form-field,
.select-search.form-mat-select .mat-mdc-form-field-infix {
  //margin-bottom: 26px;

  .mat-mdc-form-field-label-wrapper {
    top: -2px;
  }

  label {
    font-weight: 600;
    line-height: 20px;
    color: black;
  }
}

.mat-option-disabled {
  color: #666666;
}

.form-dialog .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled, .mat-mdc-select.form-mat-select.mat-mdc-select-disabled,
.mat-mdc-select:disabled, .mat-mdc-form-field-disabled .mat-mdc-select-disabled, .mat-mdc-select-disabled,
.form-dialog .mat-mdc-form-field-disabled .mat-mdc-select-disabled {
  background: @disabled;
  color: @dark-disabled;

  div.mat-mdc-select-arrow-wrapper {
    color: transparent;
    background-color: transparent;

    div.mat-mdc-select-arrow {
      color: transparent;
      border: transparent;
    }
  }
}

.mat-mdc-select:disabled, .mat-mdc-form-field-disabled .mat-mdc-select-disabled, .mat-mdc-select-disabled,
.form-dialog .mat-mdc-form-field-disabled .mat-mdc-select-disabled, .form-dialog input:disabled {
  background: @disabled;

  .mat-mdc-select-arrow, .mat-mdc-select-arrow-wrapper {
    color: transparent;
    background: transparent;
  }
}

//When using mat-label with mat-form-field the placeholder gets hidden. This class overrides that behaviour
.mat-mdc-form-field.mat-mdc-form-field-has-label.mat-mdc-form-field-hide-placeholder.override-hide-placeholder {
  .mat-mdc-select-placeholder {
    color: rgb(117, 117, 117);
    -webkit-text-fill-color: rgb(117, 117, 117);
  }
}

.select-search .mat-mdc-form-field.fill, select-many-search .mat-mdc-form-field.fill {
  min-width: 100%;
}

.select-search, select-search-component, select-many-search-component {
  width: 100%;
}

.form-dialog .select-search {
  flex-grow: 1;
  width: @form-element-width;
}

.mat-mdc-select.page-field-select {
  position: relative;
}

select-filter.select-filter-inline, .form-dialog select-filter.select-filter-inline,select-many-filter.select-filter-inline, .form-dialog select-many-filter.select-filter-inline, .mat-mdc-form-field.select-inline {
  mat-select.mat-mdc-select {
    top: 0;
  }

  .mat-mdc-form-field-infix {
    margin-bottom: 0;
  }
}

.mat-mdc-select-panel.with-search {
  padding-top: 0;
}
