@import '../../../../node_modules/material-symbols/outlined.css';

@font-face {
  font-family: 'Rajdhani';
  src: url("../../../../src/assets/fonts/Rajdhani/Light/Rajdhani-Light.woff2") format("woff2"),
  url("../../../../src/assets/fonts/Rajdhani/Light/Rajdhani-Light.woff") format("woff"),
  url("../../../../src/assets/fonts/Rajdhani/Light/Rajdhani-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rajdhani';
  src: url("../../../../src/assets/fonts/Rajdhani/Regular/Rajdhani-Regular.woff2") format("woff2"),
  url("../../../../src/assets/fonts/Rajdhani/Regular/Rajdhani-Regular.woff") format("woff"),
  url("../../../../src/assets/fonts/Rajdhani/Regular/Rajdhani-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rajdhani';
  src: url("../../../../src/assets/fonts/Rajdhani/Medium/Rajdhani-Medium.woff2") format("woff2"),
  url("../../../../src/assets/fonts/Rajdhani/Medium/Rajdhani-Medium.woff") format("woff"),
  url("../../../../src/assets/fonts/Rajdhani/Medium/Rajdhani-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rajdhani';
  src: url("../../../../src/assets/fonts/Rajdhani/SemiBold/Rajdhani-SemiBold.woff2") format("woff2"),
  url("../../../../src/assets/fonts/Rajdhani/SemiBold/Rajdhani-SemiBold.woff") format("woff"),
  url("../../../../src/assets/fonts/Rajdhani/SemiBold/Rajdhani-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rajdhani';
  src: url("../../../../src/assets/fonts/Rajdhani/Bold/Rajdhani-Bold.woff2") format("woff2"),
  url("../../../../src/assets/fonts/Rajdhani/Bold/Rajdhani-Bold.woff") format("woff"),
  url("../../../../src/assets/fonts/Rajdhani/Bold/Rajdhani-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url("../../../../src/assets/fonts/material-icons/MaterialIcons-Regular.woff2") format("woff2"),
  url("../../../../src/assets/fonts/material-icons/MaterialIcons-Regular.woff") format("woff"),
  url("../../../../src/assets/fonts/material-icons/MaterialIcons-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
  local('MaterialIconsOutlined-Regular'),
  url("../../../../src/assets/fonts/material-icons/MaterialIconsOutlined-Regular.woff2") format("woff2"),
  url("../../../../src/assets/fonts/material-icons/MaterialIconsOutlined-Regular.woff") format("woff"),
  url("../../../../src/assets/fonts/material-icons/MaterialIconsOutlined-Regular.eot") format("truetype");
}

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

