@use '@angular/material' as mat;
@use 'sass:map';
@import 'globals';

@mixin wire-input-styles($theme) {
  .mat-mdc-input-element.mdc-text-field__input,
  .mat-mdc-form-field .mat-mdc-input-element.mdc-text-field__input,
  .form-dialog .mat-mdc-input-element.mdc-text-field__input,
  .form-dialog .mat-mdc-form-field .mat-mdc-input-element.mdc-text-field__input,
  .form-dialog .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control,
  .form-dialog .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input
  {
    height: $form-element-height;
    --mdc-typography-body1-line-height: $form-element-height;
    border: 1px solid $form-element-border;
    background-color:$form-element-background;
    box-sizing: border-box;
  }
  input.mat-mdc-input-element {
    height:$form-element-height;
    padding: $input-padding-min;
  }
}