@import url("../../../../style/variables.less");

.fill {
  min-width: 100% !important;
  //padding-right:20px;
}

.select-search-indi-label {
  display: block;
  font-weight: 600;
  margin-bottom: 6px;
}

.select-search {
  .mat-form-field-label, label {
    font-weight: 600;
    //line-height: 20px;
    color: black;
  }

  .mat-form-field input, .mat-form-field .mat-select {
    position: relative;
    top: 26px;
    //line-height: 20px;
    background: white;
    border: 1px solid @form-element-border;
  }

}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.fa-ellipsis-h {
  font-size: 16px;
}

.fa-ellipsis-h:before {
  content: "\f141";
}

//.mat-form-field-label-wrapper {
//  height: 26px;
//  //align-items: center;
//  //justify-content: center;
//  display: flex;
//}

.select-many-search-selection {
  position: absolute;
  right: 14px;
  top: 0;

  i {
    cursor: pointer;
  }

  i:hover {
    background-color: rgba(1, 1, 1, 0.1);
  }
}

.select-many-search-icon {
  font-size: 14px;
}

.select-many-search-selection:hover .current-selection {
  display: block;
}

.mat-form-field-hide-placeholder .mat-select-placeholder {
  color: grey !important;
  -webkit-text-fill-color: grey !important;
}

//
//mat-option {
//  padding-right: 8px;
//
//  .mat-option-text {
//    display: flex;
//
//  }
//}

//.select-item-name {
//  flex: 20 1 auto;
//  overflow-x: hidden;
//  text-overflow: ellipsis;
//}
//
//.select-item-account-name {
//  padding-right: 8px;
//  flex: 1 1 auto;
//}

.select-search-virtual-scroll {
  height: 200px;
  overflow-x: hidden;
  background: white;
}
