@use '@angular/material' as mat;
@import 'globals';

@mixin wire-dialog-styles($theme) {
  $primary: map-get($theme, primary);
  .mat-mdc-dialog-container, .dialog-table {
    --mdc-dialog-container-color: rgba(102, 102, 102, 0.8);
    --mdc-dialog-supporting-text-color: #{$primary-text};

    .mat-mdc-label-container {
      border: none;
    }

    .mat-mdc-tab-body-wrapper {
      background: $form-grey;
    }

    .mat-mdc-tab-labels .mat-mdc-tab {
      background: $secondary-colour;
      opacity: 1;
      font-size: 1.166rem;
      letter-spacing: 1.166px;
      color: $primary-colour;
      text-transform: uppercase;
      font-weight: 400;
      height: 36px;
      padding: 2px 10px;
      margin-right: 5px;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

      .mdc-tab-indicator {
        display: none
      }
    }

    .mat-mdc-tab-labels .mdc-tab--active {
      background: $form-grey;
      color: $tile-header;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 2px 1px 2px rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
      border-color: $table-border-grey;
      border-width: 1px 1px 0 1px;

      &:hover {
        background: $form-grey;
      }
    }


  }
  .data-exceptions-table, .manage-user-list, .page .mat-mdc-tab-group.dialog-table {
    .mat-mdc-tab-header {
      top: 1px;
    }

    .mat-mdc-tab-labels .mat-mdc-tab {
      color: white;
      font-weight: bold;
      font-size: 1rem;
      background: $tile-header;
    }

    .mat-mdc-tab-labels .mdc-tab.mdc-tab--active {
      background: $off-white;
      color: $date-period-text;
      border: solid $table-border-grey;
      border-width: 1px 1px 0 1px;
    }

    .mat-mdc-tab-body-wrapper {
      background: $off-white;
      border: 1px solid $table-border-grey;
    }

    .table-container {
      border-width: 1px 0 0 0;
    }

    .mat-mdc-tab-body {
      height: auto;
      overflow: unset;
    }
  }
}