.select-additional-selection {
  opacity: 0.75;
  font-size: 0.75em;
}

.audit-history-table {

  .audit-history-series-select {
    margin-left: -4px;
    min-width: 194px;

  }

  .clear-button {
    min-width: unset;
    height: 28px !important;
    width: 30px;
    line-height: unset;
    padding: unset;

    > .mat-icon {
      margin: 0 0 0 0;
    }
  }
}