@import url("../../../../style/variables.less");

.account-picker-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dark .account-picker-row {
  color: @contrast-colour;
}

.header-menu .mat-mdc-menu-content {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  font-family: @header-font;
  background: @primary-colour;

  a.mat-mdc-menu-item, a > span.mdc-list-item__primary-text {
    height: @header-unit;
    min-height: 30px;
    line-height: unset;
    font-size: 1.166rem;
    font-weight: 500;
    color: @contrast-colour;
    text-decoration: none;
    margin: 0;
    outline: none;
    display: flex;
    align-items: center;
    padding: 0 1rem;

    &:hover {
      background: @primary-colour-hover;
    }
  }

  a.mat-mdc-menu-item:hover {
    color: white;
    text-decoration: none;
    outline: none;
  }
}

mat-toolbar {
  .right-mat-menu {
    height: inherit;
    margin-left: auto;
    align-items: center;
    display: flex;

    button {
      background: transparent;
      color: #dbd3d2;
      font-family: @body-font;
      font-size: 1.26rem;

      i {
        font-size: 1.2rem;
      }
    }

    button.toggle-dtp {
      padding-right: 0;
      display: none;
    }
  }
}

.tree-node-menu, .column-menu {
  background: white;
  padding: 0 1rem;

  .mat-mdc-menu-item, .mdc-list-item {
    line-height: 3em;
    height: 36px;
    min-height: 36px;
    background-color: unset;
    display: flex;
    align-items: center;
    padding: @menu-item-padding;
  }

  a, button, .mat-mdc-slide-toggle, .mat-mdc-option .mdc-list-item__primary-text, .mat-mdc-slide-toggle label {
    text-decoration: none;
    color: @primary-colour;
    font-size: 1.166rem;
    font-family: @header-font !important;
    font-weight: 500;
  }

  .mat-mdc-slide-toggle {
    display: block;

    .mdc-form-field {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    label {
      font-weight: 500;
      margin-left: 0;
    }

    &:hover {
      background-color: #454547;
    }
  }

  .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background: #8b8c8e;
  }

  a:hover {
    background: #e6e4e1;
  }
}

.tree-node-menu {
  padding: 0;
}

.tree-node-menu.dark, .column-menu.dark {
  background: @primary-colour;

  .mat-mdc-option:hover {
    background-color: @primary-colour-hover;
  }

  .mat-option-text, .mdc-list-item__primary-text, .mat-mdc-slide-toggle, .mat-mdc-slide-toggle .mdc-form-field, label {
    color: @contrast-colour;
  }


  .mat-pseudo-checkbox-checked {
    color: @contrast-colour;
  }

  .mdc-list-item.mdc-list-item--disabled .mdc-list-item__primary-text {
    color: #888888;
  }

  button, .mat-mdc-slide-toggle, .menu-item {
    color: @contrast-colour;
    font-weight: 500;
    background-color: unset;
    display: flex;
    align-items: center;

    span {
      //width: 100%;
    }
  }

  button.mat-button.mat-button-disabled {
    background-color: @dark-disabled;
  }

  button:hover {
    background: @primary-colour-hover;
  }

  .mat-menu-content {
    padding: 0;
  }
}


.mat-mdc-menu-panel.column-menu.series-formatting-menu {
  max-width: unset;
}


.column-menu, .column-menu.dark {
  min-height: 50px;
  min-width: 250px;
  overflow: hidden;

  .mat-menu-content {
    display: flex;
  }
}

.color-picker-menu-container {
  width: auto;
  height: auto;
  padding: 8px;

  .color-picker {
    border-color: @disabled;
  }

}

.color-picker-container {
  display: inline;

  > input {
    border-radius: 12px;
    width: 24px;
    border: none;
    height: 24px;
    cursor: default;
  }
}

.color-picker .hex-text .box {
  padding: 0 24px 2px 2px;

  > div {
    line-height: 20px;
  }
}

.color-picker .button-area {
  padding: 0 16px 16px;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  button {
    color: black;
    font-weight: bold;
    border: none;
    cursor: pointer;
  }
}

.aggregation-menu {
  min-width: unset !important;

  .mat-menu-content {
    flex-direction: column;
  }

  button {
    padding: 4px 4px 4px 8px;
    width: 120px;
    border: none;
    border-bottom: 1px solid #333;
    cursor: pointer;
  }

}

//.list-below-select-panel {
//  margin-top: 30px;
//}

div.full-height-select-panel {
  max-height: calc(100vh - 72px);
}

table-column-menu > .row {
  padding-bottom: 4px;
}


.page-tile-menu.mat-menu-panel {
  margin-top: 4px;
  background: white;
  min-height: 36px;

  .menu-item {
    color: @primary-colour;
  }

  i {
    width: 20px;
    margin-right: 4px;
  }

  .menu-item:hover {
    background: #e6e4e1;
  }
}

.tile-menu {
  i {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    z-index: 1;
    padding: 0 16px 0 0;
    line-height: 36px;
  }

  button {
    width: 120px !important;
    height: @header-unit;
    min-height: @unit;
    padding: 0 16px 0 16px;
    line-height: unset;
    font-family: @header-font !important;
    background: inherit;

    a {
      text-decoration: none;
      color: inherit;
      display: flex;
      padding: 10px 30px 10px 10px;
    }
  }

  .mat-menu-content {
    padding: 0 !important;
  }

  .mat-menu-item:hover {
    background: #e6e4e1;
    //background: #e9e9e9;
  }
}

button.active {
  color: @wire-orange;
}


.current-selection-menu .mat-mdc-menu-content {
  background: #fefefe;
  padding: 8px;
}

.current-selection {
  background: #fefefe;
  width: 220px;

  ul {
    list-style: none;
    padding: 0;
  }
}
