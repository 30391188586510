@import url("../../../../style/variables.less");

table.water-balance-table {
  border-collapse: collapse;

  td, th, td.mat-mdc-cell, td.mat-mdc-cell:last-of-type, td.mat-mdc-cell:first-of-type, th.mat-mdc-header-cell {
    border: 1px solid #dddddd;
    padding: 2px;
  }

  th, th.mat-mdc-header-cell:first-of-type, th.mat-mdc-header-cell:last-of-type {
    text-align: center;
    padding: 6px 2px;
    background-color: #eee;
    min-width: 5px;
    white-space: nowrap;
  }

  td {
    text-align: right;
  }

  td.expand-chart {
    text-align: left;
  }

  tr, tr.mat-mdc-header-row, tr.mat-mdc-row {
    height: 0;
  }

}
