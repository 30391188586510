@import url("../../../../style/variables.less");

.wire-pivot {
  height: 100%;

  .c3-legend-item {
    visibility: visible !important;
  }

  div.c3 g.tick text {
    transform: rotate(0) translateY(10px);
  }
}


table.pvtTable, table.pvtUi {
  border: 0;
  padding: 0;
  margin: 0;
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 100%;

  tbody tr th, thead tr th {
    border: 1px solid #e6e4e1;
    background-color: #ffffff;
    font-size: 1rem;
  }

  tbody tr td {
    font-size: 1rem;
  }

  thead tr {
    th {
      padding: 6px;
    }

    th.pvtAxisLabel {
      text-align: center;
    }
  }

  .pvtRendererArea {
    padding: 0;
  }

  .pvtAxisLabel, .pvtColLabel {
    color: black;
  }

  th.pvtRowLabel, th.pvtTotalLabel {
    text-align: left;
  }

  td.pvtAxisContainer {
    text-align: left;
  }

  tbody td, tbody td.pvtVal, tbody td.pvtTotal, tbody td.pvtGrandTotal {
    height: 33px;
    text-align: right;
    background: #e6e4e1;
    padding: 1px 4px;
    vertical-align: middle;
    border: 0;
  }

  tbody td {
    text-align: left;
  }

  th.pvtTotalLabel, tbody td.pvtTotal.colTotal, tbody td.pvtGrandTotal {
    border-top: 1px solid #cccccc;
  }

  tbody td.pvtTotal.rowTotal, tbody td.pvtGrandTotal {
    border-left: 1px solid #cccccc;
  }

  tbody tr:nth-child(even) td {
    background: #e6e4e1;
  }

  tbody tr:nth-child(odd) td {
    background: #e3e1de;
  }

  tbody tr:nth-child(even) td:nth-child(even) {
    background: #e2e0dc;
  }

  tbody tr:nth-child(odd) td:nth-child(even) {
    background: #deddda;
  }
}

@media screen and (min-width: @media-ws) {
  table.pvtTable, table.pvtUi {

    tbody tr th, thead tr th {
      font-size: 1.1rem;
      padding: 7px;
    }

    thead tr th {
      padding: 8px;
    }

    tbody tr td {
      padding: 7px;
      font-size: 1.1rem;
    }

  }
}

//have included separate classes here in case we want to have a separate hide col totals and hide row totals later
.hide-pivot-totals-columns {
  th.pvtTotalLabel.pvtRowTotalLabel,
  td.pvtTotal.rowTotal, td.pvtGrandTotal {
    display: none;
  }
}

.hide-pivot-totals-rows {
  th.pvtTotalLabel.pvtColTotalLabel,
  td.pvtTotal.colTotal, td.pvtGrandTotal {
    display: none;
  }
}

//Custom Theme Style Overrides here/////////////////////////////////////////////////////////////////

body.light {
  table.pvtTable, table.pvtUi {

    tbody tr:nth-child(even) td, tbody tr:nth-child(odd) td,
    tbody tr:nth-child(even) td:nth-child(even), tbody tr:nth-child(odd) td:nth-child(even) {
      background: #ffffff;
      border-bottom: 1px solid #e6e4e1;
      border-right: 1px solid #e6e4e1;
    }

  }
}
