//density feature may not be supported in current version of Angular (15)

@use '@angular/material' as mat;
@use 'sass:map';
@import 'globals';

@mixin wire-form-field-styles($theme) {
  $primary: map.get($theme, color, primary);
  $foreground: map.get($theme, color, foreground);
  $background: map.get($theme, color, background);

  .mdc-form-field, .mat-mdc-checkbox .mat-internal-form-field {
    font-size: var(--mdc-typography-body1-font-size, 14.4px);
    color: var(--mat-checkbox-label-text-color);
  }
  .row .mat-mdc-form-field.fixed-height {
    height: 52px; //prevents the select panel from opening far below element when row height expands with other elements
  }
  .row .mat-mdc-form-field.no-label.fixed-height {
    height: 32px; //prevents the select panel from opening far below element when row height expands with other elements
  }
  .mat-mdc-form-field {
    --mat-mdc-form-field-floating-label-scale: 1;

    // Remove default underline
    .mdc-line-ripple::before,
    .mdc-line-ripple::after {
      border-bottom-style: none;
    }

    // Style for the form field wrapper
    .mat-mdc-text-field-wrapper {
      background-color: transparent;
      border-radius: 0; // Square corners
      padding: $form-element-outer-padding;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    // Style for the form field flex container
    .mat-mdc-form-field-flex {
      align-items: center;
      min-height: $form-element-height;
    }

    // Move label above the select/input
    &.mat-form-field-appearance-fill .mat-mdc-floating-label {
      transform: translateY(-1.8em);
      color: black;
      width: 133.33333%;
    }

    &:not(.no-label) .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label,
    .mat-mdc-floating-label {
      display: block;
      --mdc-typography-body1-font-weight: 600;
      font-weight: var(--mdc-typography-body1-font-weight);
    }

    //// Style the label
    //.mat-mdc-floating-label {
    //  color: mat.get-color-from-palette($foreground, secondary-text);
    //}

    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
    .mat-mdc-form-field-infix {
      padding-top: 24px;
      padding-bottom: 0;
      margin-bottom: 0;
      min-height: unset;
    }

    &.no-label {
      .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
      .mat-mdc-form-field-infix {
        padding-top: 0;

        input.mat-mdc-input-element {
          height: $form-element-height;
        }
      }

      label, mdc-floating-label {
        display: none;
      }
    }

  }

  .mat-mdc-form-field-focus-overlay {
    background-color: inherit;
  }

  .mat-mdc-form-field.inline {
    width: 500px;

    .mat-mdc-form-field-infix {
      display: flex;
      align-items: center;
      padding-top: 0 !important;

      label {
        min-width: fit-content;
        margin-right: 8px;
      }
    }
  }
  //.min is a custom class which should be added to mat-form-field to override label padding
  .mat-mdc-form-field.min {
    background: orange;

    .mat-mdc-form-field-infix {
      padding: 0;
    }

    .mat-mdc-text-field-wrapper {
      padding: 0;
    }
  }
}
