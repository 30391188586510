@use '@angular/material' as mat;
@import 'globals';

@mixin wire-table-styles($theme) {
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);

  .mat-mdc-table {
    border: 1px solid $table-border-grey;
  }

  tr.mat-mdc-header-row {
    height: $header-unit;
  }

  th.mat-mdc-header-cell, .handsontable thead th, .ht_clone_left thead th, .ht_clone_top thead th {
    background: white;
    height: $header-unit;
    padding: 4px 0 4px 4px;
    border: solid $table-border-grey;
    border-width: 0 1px 1px 0;
    text-align: center;
    font-weight: bold;
    font-size: 14px;

    .mat-sort-header-container {
      justify-content: center;
    }
  }

  .mat-mdc-table {
    tr.mat-mdc-row, tr.mat-mdc-header-row {
      height: $row-height;

      td.mat-mdc-cell {
        border-color: $table-border-grey;
        padding: $form-element-outer-padding;
        vertical-align: middle;
      }
    }
  }

  .mat-table.table-striped {
    > tbody > tr:nth-of-type(odd) {
      background-color: #f9f9f9;
    }

    td.mat-mdc-cell {
      border: none;
    }
  }
}
