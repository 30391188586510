@import '../../../../style/variables.less';

.mat-mdc-dialog-container {
  resize: both;
  overflow: auto;
  position: relative;
  min-width: 900px;
  min-height: 400px;
  max-width: min(1700px, 94vw) !important;
  max-height: 90vh !important;

  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
  display: block;
  border-radius: 4px;
  box-sizing: border-box;
  outline: 0;

  @media screen and (max-width: @media-xs) {
    min-width: unset;
  }

  h1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 @form-padding;
    height: @mat-toolbar-height;
    background: inherit;
    color: white;
    font-size: 1.166rem;
    font-weight: 500;
    //padding:8px 8px 0px 16px;
    > div {
      display: inline-flex;
      color: orange;
      float: right;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .mat-mdc-tab-list {
    flex-grow: 0;
  }

  .mat-mdc-tab-header {
    top: -@mat-dialog-top-padding;
    z-index: 0;
  }

  .mat-mdc-tab-labels .mdc-tab--active {
    border: solid @table-border-grey;
    border-width: 1px 1px 0 1px;
  }

  .mat-mdc-table-body {
    border-top: 1px solid @table-border-grey;
  }

  .mdc-tab-indicator__content--underline {
    border: none;
  }

  .mat-mdc-tab-body-wrapper {
    min-height: inherit;
    border-radius: 0 @border-radius @border-radius;
    position: absolute;
    right: @form-padding;
    left: @form-padding;
    top: calc(@mat-toolbar-height + @header-unit);
    bottom: @mat-toolbar-height;
    overflow-y: visible;
  }

}