.print-footer, .print-header {
  display: none;
}

.page.fixed-width-portrait {
  width: 1457px !important; //60 not enough, 50 too much
}

.page.fixed-width-landscape {
  width: 2200px !important;
}

.page.portrait-widescreen {
  width: 2000px !important;
}

//These styles should be visible in the 'preview' before pressing print
body.portrait-widescreen, body.portrait, body.landscape {
  date-time-period, .page-title.row {
    visibility: hidden;
  }
}

.printing-mode {
  padding-top: 0 !important;

  &.page.traveler {
    background: white;
    height: -webkit-fill-available;
  }

  gridster#gridster_parent {
    padding-top: 0 !important;
  }

  gridster.tile-grid gridster-item {
    box-shadow: none;
  }

  gridster.tile-grid gridster-item page-tile .dashboard-tile:not(.no-format) {
    border: 1px solid #8b8c8e;
  }

  .hideonprint:not(.printing) {
    display: none !important;
  }

  .date-period-range-select {
    min-width: 175px;
    margin-right: 10px;
    text-align: right;

    .showonprint {
      visibility: visible;
      width: auto;
    }

    .displayonprint {
      display: inline-block;
    }
  }

  .series-table {
    .mat-column-Name, .mat-column-Description {
      position: static !important;
    }

    td.mat-mdc-cell:first-of-type, td.mat-footer-cell:first-of-type, th.mat-mdc-header-cell:first-of-type {
      padding: 1px 4px;
    }
  }

  table.mat-table.component-events-table {
    th {
      position: static !important;
      height: 30px;
    }

    td {
      position: static !important;
    }
  }

  /**Table specific print styles**/

  .component-events-table {
    //remove the sticky behaviour and align columns to left side
    .cdk-column-Expand {
      max-width: 0;
    }

    .cdk-column-name, .cdk-column-description {
      position: static !important;
    }

    td.mat-mdc-cell .mat-mdc-select .mat-mdc-select-arrow-wrapper {
      display: none !important;
    }
  }
}

@media print {

  @page {
    //size: portrait;//2480 x 3508 = 1240 x 1754
    //size: portrait;
    margin: 25px;
    @bottom-right {
      //this would be ideal but doesn't work in chrome
      //content: counter(page) "/" counter(pages);
      //content: "Powered by MMS WIRE. https://www.metalmanagementsolutions.com";
    }
  }

  body {
    //-webkit-print-color-adjust: exact;
    font-size: 10px;
    overflow: visible !important;
    background: white;

    > app-root > div {
      height: unset; //For page-break
    }

    counter-reset: page;

    div.body {
      zoom: 0.7641; //ref:https://app.clickup.com/t/7272257/WIRE-3741
      //transform:scale(0.72);
      transform-origin: 0 0;
    }
  }

  body.portrait-widescreen {
    div.body {
      zoom: 0.5586; //ref:https://app.clickup.com/t/7272257/WIRE-3741
    }
  }

  body.landscape {
    div.body {
      zoom: 0.744;
    }
  }

  .print-footer:not(.printing-mode), .print-header:not(.printing-mode) {
    display: block;
  }

  .page {
    box-decoration-break: clone;
    background: transparent;
    //position: relative;
    padding: 0 0 !important;
  }

  gridster {
    //position: static !important;
    overflow-y: visible !important;
    border: none !important;

    .gridster-row, .gridster-column {
      display: none;
    }

    .dashboard-tile .tile-content {
      overflow: visible;
    }
  }

  .chart-card {
    .c3 text:not(.zero-value) {
      fill: black !important;
    }
  }

  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    background: transparent;
  }

  .mat-drawer-container {
    position: relative !important; //For page-break
    overflow: visible !important;
    top: 0 !important;
  }

  .mat-drawer-content {
    overflow: visible !important;
  }

  .flowchart-container {
    overflow: hidden !important;
  }

  mat-toolbar.header-toolbar, button.sidenav-closed, .right-mat-menu button, .page-title button,
  .mat-select-value .mat-select-icon:after, .mat-menu-panel, .breadcrumbs,
  footer, nav, .dashboard-tile div.tile-header > span i.edit-no-text, .confirm-print-box, .app-loading,
  sidenavigation, .hideonprint:not(.printing), .hidden-header, .cdk-drag-handle, audit-mixin-indicator {
    display: none !important;
  }

  .page-title {
    display: none;
  }

  .showonprint {
    visibility: visible;
    width: auto;
  }

  .displayonprint {
    display: inline-block;
  }

  .page.editing-mode {
    background-image: none !important;
  }

  .printing-mode {
    //background: white;

    .section-area, .tile-area {
      display: none;
    }

    div.break-before::before, div.break-after::after {
      display: none !important;
    }
  }

  .printing-mode .not-printing {
    display: none;
    background: white;
  }

  .printing-mode .printing {
    display: block;
    background: white;

    .section {
      display: flex;
      border: 0;
    }
  }

  .print-section-tooltip {
    display: none;
  }

  .table {
    break-before: avoid-page;
  }

}
