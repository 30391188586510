@import url("../../../../style/variables.less");

.no-format .context-tile {
  //border: 2px solid #8b8c8e;
  //border-radius: 0 0 8px 8px;
}

.context-tile {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 1rem;

  .title {
    padding: 4px;
    height: 25%;
    max-height: 30px;
    width: 100%;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 1.1em;
  }

  .context-layout {
    display: flex;
    flex-direction: row;
    height: 75%;
    min-height: calc(100% - 30px);
    align-items: center;
    justify-content: space-between;

    .context-content {
      height: 100%;
      width: 100%;
    }
  }

  .primary-tile, .secondary-values {
    display: none;
  }

  .primary-vdt {
    display: flex;
    font-size: 43px; //3em
    width: 40%;
  }

  .primary-value {
    width: 100%;
    text-align: center;
    font-weight: bold;
    padding: 4px;
    margin-top: -10px;
    align-self: center;
  }

  .secondary-values {
    align-items: flex-start;
    //justify-content: center;
    font-weight: bold;
    font-size: 1.33em;
  }

  sparkline:not(.vdt-sparkline) {
    align-self: flex-end;

    svg {
      transform: none !important;
    }

    svg g {
      width: 100%;
      height: 100%;
      transform: none;
    }
  }

  .chart {
    min-width: 60%;
    height: 100%;

    svg g {
      width: 100%;
      height: 100%;
      //transform:none;
    }
  }

  .spark-anchor:not(.vdt-sparkline) {
    g.c3-chart {
      height: 100% !important;
    }

    min-width: 0 !important;
    max-width: 1000px !important;
    height: 100%;
    //padding-left: 4px;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  @vdt-box-height: 46px;

  .vdt-container {
    border-left: 6px solid transparent;
    cursor: pointer;

    &.box-favourable {
      border-color: @favourable;
    }

    &.box-unfavourable {
      border-color: @unfavourable;
    }
  }

  .vdt-title {
    padding: 4px;
    width: 100%;
    font-weight: 500;
    font-size: 1rem;
    white-space: normal;
    line-height: 13px;
    height: 34px;
  }

  .vdt-box {
    align-items: center;
    justify-content: space-around;
    height: @vdt-box-height;

    .vdt-sparkline {
      max-height: @vdt-box-height !important;
      height: @vdt-box-height;
      width: 50%;
      max-width: 60%;
    }

    .spark-anchor, g.c3-chart {
      max-height: @vdt-box-height !important;
      height: @vdt-box-height;
    }

    .vdt-value {
      font-weight: bold;
      font-size: 26px;
      white-space: nowrap;
      padding-right: 4px;
      max-width: 120px;
      transition: visibility 0.5s;
    }
  }

  .context-layout.full {
    flex-direction: column;

    .primary-tile, .secondary-values {
      display: flex;
    }

    .primary-vdt {
      display: none;
    }

    .primary-tile {
      font-size: 3.5em;
      text-align: right;
      line-height: 3.3rem;
      width: 100%;
    }

    .primary-value {
      width: 100%;
      font-weight: bold;
      padding: 4px 20px 4px 4px;
      align-self: center;
      justify-content: flex-end;
    }

    .secondary-values {
      line-height: 2.5em;
      padding-right: 4px;
      min-width: 100px;
    }

    .context-content {
      height: 100%;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding-left: 8px;
    }

    .title {
      font-size: 1.66em;
      padding: 4px 8px;
    }

    .sub-title {
      font-size: 1em;
      color: gray;
      padding: 0 8px;
    }

    sparkline {
      align-self: center;
    }

    generic-chart {
      height: inherit;
      width: inherit;
    }

    .chart {
      .chart-card .md-card-content {
        padding: 16px;
      }

      svg {
        transform: translate(-20px, 0px);
      }

    }
  }


}

.context-tile-form {
  > div.chart-config-border {
    margin-top: 16px;
    padding: 10px;
    border: 1px solid #3a3a3c;

    h4 {
      font-weight: normal;
      width: 200px;
      margin-top: -18px;
      margin-left: 5px;
      background-color: #e6e4e1;
      text-align: center;
    }
  }

  .reminder {
    margin-top: -20px;
    margin-bottom: 20px;
    color: darkorange;
  }
}
