@import url("../../../../style/variables.less");

.wire {

  .option-loading {
    min-height: 32px;
    padding: 0 16px;
  }

  .cdk-overlay-container {
    .mat-mdc-select-panel:not(.select-search-dropdown-panel, .with-search) {
      max-height: 300px;
      //margin-top: -@unit;
    }

    .mat-mdc-select-panel:not(.dark) {
      background: #fff;

      .mat-mdc-option {
        &.mat-mdc-active:not(.mat-mdc-option-multiple, .with-active-indicator), &:hover {
          background: @dropdown-hover;
        }

        &.mat-selected:not(.mat-mdc-option-multiple, .with-active-indicator) {
          background: @dropdown-selected;

          &.mat-mdc-active, &:hover .mat-mdc-option-text {
            background: @dropdown-selected;
          }
        }
      }
    }

    .mat-mdc-select-panel.with-search {
      padding-top: 0;
    }

    .mat-mdc-select-panel {
      .mat-mdc-optgroup-label, .mat-mdc-option, .mat-mdc-option .mat-mdc-option-text {
        height: 2.66rem;
        line-height: 2.66rem;
      }

      &.list-below-select-panel {
        //margin-top: 30px;
      }

      &.full-height-select-panel {
        max-height: calc(100vh - 72px);
      }
    }


  }

  .account-name {
    background: @form-grey;
    color: @dark-disabled;
    padding: 1px 12px;
    border-radius: 12px;
    height: 22px;
    display: flex;
    align-items: center;
  }

  .mat-mdc-select-panel.select-search-dropdown-panel {
    overflow-y: auto;
    min-height: 256px;
    width: 500px;
    min-width: 500px;
    max-width: 500px;

    .cdk-virtual-scroll-viewport {
      overflow-y: auto;
    }

    .sticky {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 1;
      min-height: 2.66rem;
      width: 100%;
      background: @background;
      border-bottom: 1px solid @primary-colour;
    }

    .error {
      display: flex;
      min-height: 2.66rem;
      align-items: center;
      padding: 0 16px;
      color: @primary-colour;
    }

    .center {
      display: flex;

      .mat-mdc-form-field-appearance-fill {
        width: 100%;

        .mat-mdc-form-field-flex {
          padding: 0;
          align-items: center;
        }
      }
    }

    .clear-filter {
      font-size: 1.8rem;
      color: @light-grey;
      cursor: pointer;
      margin-right: 10px;
    }

    .search-dropdown {
      overflow: hidden;
      padding: 0 16px;
      box-sizing: border-box;
    }

    .select-container > div.row {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 1;
      min-height: 2.66rem;
      width: 100%;
      background: @background;
      border-bottom: 1px solid @tile-header;
      height: @header-unit;

      .mat-mdc-checkbox {
        width: 20px;
        min-width: unset;
      }

      .mat-mdc-form-field input {
        border: none !important;
      }
    }

    .mat-mdc-form-field.search {
      padding: 0 16px;
      color: @tile-header;
      display: flex;
      align-items: center;

      input {
        color: @tile-header;
      }

      .mat-mdc-form-field-wrapper {
        width: 100%;
      }

      i {
        vertical-align: unset;
      }
    }

    .no-results {
      padding: 16px;
    }

    .mat-mdc-option {

      .select-item-name {
        flex: 20 1 auto;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .select-item-account-name {
        padding-right: 8px;
        flex: 1 1 auto;
      }

      .mat-select-value .select-item-account-name {
        display: none;
      }
    }

    .mat-mdc-option.with-account-row .mat-mdc-option-text {
      display: flex;
      justify-content: space-between;
      align-items: center;


    }

    .mat-mdc-option .mat-mdc-option-text {
      display: flex;
    }
  }

  @media screen and (max-width: @media-xs) {

    .mat-mdc-select-panel {
      min-width: 98% !important;
    }
  }
}
