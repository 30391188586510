@import url("../../../../style/variables.less");

.modal-backdrop.in {
  opacity: 0
}

.calc-warning {
  padding-left: 8px;
  text-align: left;
  font-weight: bold;
  background: @wire-orange;
  color: white;
  position: sticky;
  left: 0px;
  right: 0px;
}

table.shift_table {
  border-collapse: unset;
  background: #e6e4e1;
  width: unset;
  min-width: unset;

  tr.mat-mdc-header-row {
    height: 40px;
    padding: 4px 0px;
    background: white;
    font-weight: 500;
    position: sticky;
    top: 0;
    z-index: 100;

    th {
      background: white;
      text-align: center;
      border: 0;
      min-width: 64px;

      > div:not(.resize-handle) {
        line-height: 20px;
        white-space: nowrap;
        padding: 0 4px;
      }
    }
  }

  td.mat-mdc-cell {
    padding: 1px 4px;
    background: #e6e4e1;
    vertical-align: middle;
  }

  td.values {
    border-left: 1px solid #dddddd !important;
  }

  td span.missing-values {
    color: dimgrey;
  }

  .mat-mdc-row:nth-child(even) td {
    background: #e6e4e1;
  }

  .mat-mdc-row:nth-child(odd) td {
    background: #e3e1de;
    //background: #deddda;
  }

  tbody tr.mat-mdc-row:nth-child(even) td:nth-child(even) {
    //background: #e2e0dc;
  }

  tbody tr.mat-mdc-row:nth-child(odd) td:nth-child(even) {
    //background: #deddda;
  }

  tr.mat-mdc-row {
    height: 30px;
  }

  td.mat-mdc-cell:first-of-type {
    padding: 0;
  }

  td.mat-mdc-cell:last-of-type, th.mat-mdc-header-cell:last-of-type {
    border-right: 1px solid @table-border-grey;
    padding-right: 2px;
  }

  input {
    background: #efefef;
    font: inherit;
    text-align: right;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  tr.mat-mdc-header-row th.mat-column-group {
    min-width: 30px;
    border-right: none;
  }

  .mat-column-group {
    position: sticky;
    left: 0 !important;
  }

  td.mat-column-group {
    background: #998d7d !important;
    border: 1px solid;

    > div {
      color: white;
      font-weight: 600;
      letter-spacing: 1px;
      text-align: center;
      white-space: nowrap;
      text-transform: uppercase;
      //transform-origin:50% 50%;
      //-webkit-transform: rotateZ(-90deg);
      transform: rotateZ(-90deg) translateX(-75%);
      width: 30px;

      > div {
        overflow: hidden;
        //height:100%;
        //text-align:center;
        //margin-left:-50%;
      }
    }
  }

  td.mat-column-group.alt {
    background: @date-period !important;
  }

  .mat-column-name {
    width: 160px;
  }

  td.mat-column-edit, .mat-mdc-header-row th.mat-column-edit {
    left: 30px !important; //for left stickiness, width of group column
    width: 40px;
    min-width: 40px;
    padding: 0;
    position: sticky;

    > div {
      width: 100%;
      display: block;
    }
  }

  .mat-mdc-header-row th.mat-column-edit {
    border-right: none;
  }

  .mat-column-description {
    width: 254px;
  }

  td.is-sticky-left, th.is-sticky-left {
    left: 70px !important; //for left stickiness, width of group column + edit column
    position: sticky;
  }

  th.is-sticky-left {
    padding-right: 40px;
  }

  th.mat-column-description > div:not(.resize-handle) {
    width: 100%;
  }

  .mat-column-description > div:not(.resize-handle), .mat-column-name > div:not(.resize-handle) {
    width: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
  }

  &.editing-layout th, table.editing-layout td {
    position: sticky;

    &.is-sticky-left {
      z-index: 101;
    }

    &.mat-column-group, &.mat-column-edit {
      z-index: 101;
    }
  }

  .mat-column-Aggregation {
    min-width: 70px;
    width: 70px;
  }

  td.mat-column-comment {
    position: static !important;
  }

  td.align-left, td div.align-left {
    text-align: left;
  }

  td.align-right, td div.align-right {
    text-align: right;
  }

  td.align-center, td div.align-center {
    text-align: center;
  }

  td.mat-mdc-cell {
    border: none;
  }

  td.values {
    width: 50px;
  }

  td:focus {
    background: @date-period-light !important;
  }
}

//Custom Theme Style Overrides here/////////////////////////////////////////////////////////////////

body.light {
  table.shift_table {

    .mat-mdc-row:nth-child(even) td, .mat-mdc-row:nth-child(odd) td,
    tbody tr.mat-mdc-row:nth-child(even) td:nth-child(even), tbody tr.mat-mdc-row:nth-child(odd) td:nth-child(even) {
      background: #ffffff;
      border-bottom: 1px solid @table-border-grey;
      border-right: 1px solid @table-border-grey;
    }
  }

  tr.mat-mdc-header-row th {
    border-right: 1px solid @table-border-grey;
    border-bottom: 1px solid @table-border-grey;
  }

  tr.mat-mdc-header-row th > div:not(.resize-handle) {
    border-right: 0;
  }

  table.shift_table td.values {
    border-left: 0 !important;
  }
}
