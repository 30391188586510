@import url("../../../../style/variables.less");

hr {
  height: 1px;
  border: none;
  color: @primary-colour;
  background-color: @primary-colour;
  width: 100%;
  text-align: right;
  margin: 0 0 0 auto;
}

/**This will create one level higher specificity over standard mat styles
Please add material element overrides here**/
.wire {
  .tip-box {
    display: none;
  }

  .mat-mdc-ripple-element {
    display: none !important;
  }

  .row {
    display: flex;
    flex-direction: row;

    &.center {
      align-items: center;
    }

    &.start {
      align-items: flex-start;
    }

    &.end {
      align-items: flex-end;
    }


    &.justify-start {
      justify-content: flex-start;
    }

    &.justify-center {
      justify-content: center;
    }

    &.justify-end {
      justify-content: flex-end;
    }

    &.self-center, .self-center {
      align-self: center;
    }

    &.justify-self-center {
      justify-self: center;
    }

    &.space-between {
      justify-content: space-between;
    }

    &.stretch {
      align-items: stretch;
    }

    &.auto {
      height: auto;
    }

    .flex-grow-1 {
      flex-grow: 1;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .full-width, .mat-mdc-form-field.full-width {
    width: 100%;
  }

  .full-height {
    height: 100%;
  }

  input {
    height: @form-element-height;
    border: 1px solid @form-element-border;
    font-family: @body-font;
  }

  label {
    font-weight: 600;

    &.standalone {
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  .displayonprint {
    display: none;
  }

  .showonprint {
    visibility: hidden;
    width: 0;
  }

  .mat-hint {
    display: none;
  }
}
