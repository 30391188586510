@use '@angular/material' as mat;
@use 'sass:map';
@import 'globals';

@mixin wire-menu-styles($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  $body-2-size: mat.m2-font-size($typography-config, body-2);
  --mat-menu-item-label-text-size: $body-2-size;
  .dark {
    .mat-mdc-select-value-text, .mat-mdc-select-arrow {
      color: $primary-contrast-colour;
    }
  }
  .mat-mdc-menu-panel:not(.dark) {
    //background: white;
    //padding: 0;

    .mat-mdc-menu-content {

      .mat-mdc-menu-item, .mat-mdc-menu-item .mat-mdc-menu-item-text {
        font-size: $body-2-size;
        min-height: $header-unit;
        align-items: center;
        display: flex;


        //padding: $form-element-outer-padding;

        &:hover {
          //background-color: $off-white;
        }

        i {
          width: 20px;
        }
      }
    }
  }
  .mdc-menu-surface.mat-mdc-select-panel {
    min-width: fit-content;
    //background: $off-white;

    .mat-mdc-option {
      line-height: 36px;
      min-height: 36px;
      font-size: 1.1rem;
      padding: $menu-item-padding;

      .mdc-list-item__primary-text {
        display: flex;
        white-space: nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .mat-pseudo-checkbox, .mat-pseudo-checkbox-minimal {
        //display: none;
      }
    }

    &.full-height-select-panel {
      max-height: calc(100vh - 72px);
    }
  }

}
