@import url("../../../../style/variables.less");

.form-dialog event-column-selector {
  display: block;
}

.column-selector {
  height: auto !important;
  display: flex;
  justify-content: space-between;

  mat-form-field.property-search-input {
    color: @primary-colour;
    font-size: 1rem;
    width: 100%;
    margin-bottom: 0 !important;

    .mat-mdc-text-field-wrapper {
      padding: 0;
    }

    input {
      height: @search-element-height !important;
      background: white;
      border-bottom-width: 0;
      padding: 2px;
      top: 0 !important;
      margin-bottom: -2px;
      box-sizing: border-box;
    }

    .mat-form-field input {
      position: relative;
      top: 26px;
    }

    .mat-form-field-label {
      bottom: 2px;
      left: 5px;
    }

    .mat-form-field-label-wrapper {
      margin-bottom: 0 !important;
    }

    .mat-form-field-label-wrapper label {
      font-weight: 400;
    }

    .mat-form-field-infix {
      margin-bottom: 0 !important;
    }

  }


  option-list-search {
    display: block;
  }

  .cdk-drag {
    height: 48px;
  }

  .draggable-list-container {
    width: @form-element-width;
    flex-grow: 1;
    padding: @form-element-outer-padding;
    vertical-align: top;

    .option-list-search {
      border: 1px solid @secondary-color-grey;
      border-bottom-color: @secondary-color-grey;
    }
    margin-bottom: @form-ctrl-margin;
  }

  .draggable-list.cdk-drop-list-disabled {
    opacity: 50%;
  }

  .draggable-list {
    border: solid 1px @form-element-border;
    border-top: 0;
    min-height: 60px;
    background: white;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    display: block;
    height: 340px;
    overflow-y: auto;
    padding-bottom: 20px;
  }

  .draggable-list-item {
    padding: 10px 10px;
    border-bottom: solid 1px @table-border-grey;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    font-size: 1rem;
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
    padding: 10px;
    background: white;
  }

  .cdk-drag-placeholder {
    opacity: 0;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .draggable-list-item:last-child {
    border: none;
  }

  .draggable-list-item.cdk-drag-disabled {
    cursor: not-allowed;
  }

  .draggable-list.cdk-drop-list-dragging .draggable-list-item:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .draggable-list-item .mat-form-field.no-margins {
    width: 210px;
    margin-bottom: 0;

    .mat-form-field-infix {
      margin-bottom: 0;

      .mat-select {
        top: 0;
      }
    }
  }

  .column-name {
    flex: 1.5;
  }

  .column-title {
    min-width: 140px;
    margin-right: 2px;
    flex: 1;
  }

  @keyframes attention {
    0% {
      background-color: white;
    }
    20% {
      background-color: orange;
    }
    100% {
      background-color: white;
    }
  }

  .dropped {
    animation-name: attention;
    animation-duration: 2s;
  }

  input {
    height: @form-element-height
  }

  .default-value-field {
    flex: 1;
  }
}
