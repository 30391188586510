@import url("./variables.less");

button:active {
  opacity: 0.5;
  transition: opacity 0.5s;
}

.icon-set() {
  background-size: 100% !important;
  background-color: @primary-colour;
  color: white !important;
  width: @header-unit;
  height: @header-unit;
  display: inline-block;
  cursor: pointer;
  background-repeat: no-repeat;
  border: none;
  border-radius: 4px;
}

.icon-time {
  background-image: url('../icons/clock.png') !important;
  background-size: 66%;
  background-position: center center;
}

md-datepicker md-icon {
  background-image: url('../icons/calendar.png') !important;
  background-size: 66%;
  background-position: center center;

  svg {
    display: none;
  }

}

.icon:not(.plotly svg.icon) {
  .icon-set();
}

.icon-add {
  background-image: url('../icons/add.svg') !important;
  .icon-set();
}

.icon-file-upload {
  padding: 4px !important;
  color: white;
  .mat-icon {
    vertical-align: top !important;
  }

}

.icon-edit {
  background-image: url('../icons/edit.svg') !important;
  .icon-set();
}

.icon-print {
  background-image: url('../icons/print.svg') !important;
  .icon-set();
}

.icon-new {
  background-image: url('../icons/new.svg') !important;
  .icon-set();
}

.icon-download {
  background-image: url('../icons/dwnld.svg') !important;
  .icon-set();
}

.icon-save {
  background-image: url('../icons/save.svg') !important;
  .icon-set();
}

.icon-correction {
  background-image: url('../icons/correction.svg') !important;
  .icon-set();
  background-size: 130% !important;
}

.icon-present {
  .icon-set();
  background-size: 130% !important;
  padding-left: 9.5px;
}

.icon-menu {
  .icon-set();
  padding-left: 9.5px !important;
}

.icon-refresh {
  background-image: url('../icons/refresh.svg') !important;
  .icon-set();
  @media (max-width: @media-xs) {
    display: inline-block;
  }
}

.icon-comment {
  background-image: url('../icons/comment.svg') !important;
  background-color: transparent !important;
  border: none;
  .icon-set();
}

.icon-comment-long {
  background-image: url('../icons/comment-long.svg') !important;
  background-color: transparent !important;
  border: none;
  .icon-set();
}

.icon-comment-invert {
  background-image: url('../icons/comment-invert.svg') !important;
  background-color: transparent;
  border: none;
  background-size: 70% !important;
  color: white !important;
  width: 36px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  background-repeat: no-repeat !important;
  margin-left: 10px;
}

.icon-inline-comment {
  background-image: url('../icons/inline-comment.svg');
  background-color: transparent !important;
  border: none;
  .icon-set();
  width: 22px;
  height: 22px;
  background-size: 200% !important;
}

.edit-inline {
  background-image: url('../icons/edit-inline.svg') !important;
  //background-size: 1000% !important;
  //background-position: center;
  width: 30px;
  height: 30px;
  display: inline-block;
  cursor: pointer;
}

.edit-no-text {
  background-image: url('../icons/edit-no-text.svg') !important;
  background-size: 600% !important;
  background-position: center;
  width: 36px;
  height: 28px;
  display: inline-block;
  cursor: pointer;
}

.icon-status {
  background-image: url('../icons/status.svg') !important;
  background-size: 500% !important;
  background-position: center;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.status-warn, .status-action {
  background-image: url('../icons/status-warn.svg') !important;
  background-size: 500% !important;
  background-position: center;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.status-action {
  background-image: url('../icons/status-action.svg') !important;
}

.page-title {
  .fa {
    .icon-set();
    display: block;
    padding-top: 0px;
    font-size: 14px;
    padding-left: 12px;
  }
}

.btn-save-comment, .btn-addtotext {
  width: 28px;
  height: 28px;
  background: #595859;
  font: normal normal normal 10px/1 FontAwesome;
  font-size: 1rem;
  border: 0;
  margin-left: 4px;
  cursor: pointer;
}

.btn-save-comment:active, .btn-addtotext:active {
  background-color: @primary-colour;
}

.btn-save-comment:before {
  content: "\f00c";
  color: white;
}

.btn-addtotext:before {
  content: "\f063";
  color: white;
}
