@use '@angular/material' as mat;
@use 'sass:map';
//@import '@angular/material/theming';
@import 'globals';

@mixin wire-select-styles($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  $primary: map.get($theme, color, primary);

  // Style the select itself
  .mat-mdc-select:not(.account-picker-select) {
    font-weight: normal;
    height: $form-element-height;
    --mdc-typography-body1-line-height: $form-element-height;
    --mdc-select-outline-color: $form-element-border;
    --mdc-select-hover-outline-color: $form-element-border;
    --mdc-select-focus-outline-color: $form-element-border;

    // Style for placeholder
    .mat-mdc-select-placeholder {
      color: $placeholder-text;
    }

    // Style for selected value
    .mat-mdc-select-value {
      //color: mat.get-color-from-palette($foreground, text);
    }

  }
  .mat-mdc-select-disabled .mat-mdc-select-value {
    color: $disabled-text;
  }
  .form-dialog .mat-mdc-form-field:not(.show-select-arrow):not(.mat-mdc-paginator-page-size-select) .mat-mdc-select:not(.mat-mdc-select-disabled):not(.calendar-select) .mat-mdc-select-arrow-wrapper,
  .bordered-select .mat-mdc-form-field .mat-mdc-select:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow-wrapper,
  .form-dialog .mat-mdc-select:not(.mat-mdc-select-disabled):not(.calendar-select):not(.show-select-arrow) .mat-mdc-select-arrow-wrapper,
  .bordered-select .mat-mdc-select:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow-wrapper {
    // Style for the dropdown arrow
    background: $tile-header;
    height: 26px;
    transform: translateY(0);
    width: $form-element-height;

    > div {
      color: $primary-contrast-colour;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 9px solid;
      margin-top: 8px;
      margin-left: 7px;
      height: 18px;
      box-sizing: border-box;
    }

    .mat-mdc-select-arrow {
      svg {
        display: none;
      }
    }
  }
  .form-dialog .mat-mdc-select.calendar-select:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow-wrapper > .mat-mdc-select-arrow,
  .form-dialog .mat-mdc-form-field.mat-mdc-paginator-page-size-select .mat-mdc-select:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow-wrapper > .mat-mdc-select-arrow {
    color: $primary-colour;
    border:unset;
    margin:unset;

    svg {
      display: inline-block;
    }
  }
  .form-dialog .mat-mdc-form-field .mat-mdc-select,
  .bordered-select.mat-mdc-form-field .mat-mdc-select {
    border: 1px solid $form-element-border;
    display: block;
  }

  //Hide arrow in mat-select in table cells
  .mat-mdc-table td.mat-mdc-cell:not(.formula-builder-cell) .mat-mdc-select {
    border: none;
    background: transparent;

    .mat-mdc-select-arrow-wrapper, {
      background: transparent !important;
    }
  }
  .mat-mdc-table td.mat-mdc-cell:not(.formula-builder-cell) .mat-mdc-form-field:not(.show-select-arrow):not(.mat-mdc-paginator-page-size-select) .mat-mdc-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
    display: none;

    svg {
      display: none;
    }
  }
  .mat-mdc-table td.mat-mdc-cell:not(.formula-builder-cell) .mat-mdc-form-field.show-select-arrow .mat-mdc-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow,
  .mat-mdc-form-field.mat-mdc-paginator-page-size-select .mat-mdc-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
    display: inline-block;
    color: $primary-colour;
    margin: 0;
    border: 0;

    svg {
      display: inline-block;
    }
  }
  .mat-mdc-table td.mat-mdc-cell .mat-mdc-form-field.show-select-arrow .mat-mdc-select-disabled .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow{
    color: var(--mat-select-disabled-arrow-color);
  }

  .form-dialog .mat-mdc-paginator .mat-mdc-form-field.mat-mdc-paginator-page-size-select .mat-mdc-select {
    border: none;

    .mat-mdc-select-arrow-wrapper {
      background: transparent;
    }
  }

  //Hide custom arrow in date-period selector on forms
  .date-period .mat-mdc-select .mat-mdc-select-arrow-wrapper,
  {
    background: transparent;
  }

  .row > .mat-mdc-form-field-type-mat-select {
    height: min-content;
  }
}
