table.mat-table:not(.series-table, .shift_table, .custom-events-table, .component-events-table, .component-constants-table) {
}

.manage-user-list {
  padding-top: 36px;

  .mat-tab-group {
    min-height: 220px;
    border-radius: @border-radius;
    //margin-bottom:26px;
  }

  .mat-tab-labels .mat-tab-label.mat-tab-label-active {
    background: #e6e4e1;
    opacity: 1;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 2px 1px 2px rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
    color: @date-period-text;
  }

  .mat-body-wrapper {
    &:extend(.base-mat-body-wrapper);
    border-top-left-radius: 0;
    border: 1px solid @form-grey;
  }

  .mat-body-content {
    height: auto;
    overflow: unset;
  }
}

.table-container {
  max-height: 70vh;
  overflow: auto;
  border: 1px solid #ccc;
}

.calculation-check-table, .collection-events-table, .user-table {

  //tbody {
  //  tr:nth-child(even) {
  //    background-color: #f2f2f2;
  //  }
  //}

  th.mat-mdc-header-cell:first-of-type, td.mat-mdc-cell:first-of-type {
    padding-left: 8px;
  }

  td.mat-mdc-cell {
    border: none;
    padding: 8px;
    vertical-align: middle;
  }
}

.calculation-check-table {
  .mat-column-message, .mat-column-calculation {
    width: 15%;
  }

  .mat-column-status {
    width: 10%;
  }

  .mat-column-description {
    width: 20%;
  }

  .mat-column-formula {
    width: 40%;
  }
}
