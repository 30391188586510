@use '@angular/material' as mat;
@import 'globals';


@mixin wire-checkbox-styles($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  .mat-mdc-checkbox, .mat-mdc-radio-button {

    // Override MDC variables with the typography values
    --mdc-typography-body1-font-size: #{mat.m2-font-size($typography-config, 'body-1')};
    --mdc-typography-body1-line-height: #{mat.m2-line-height($typography-config, 'body-1')};
    --mdc-typography-body1-font-weight: #{mat.m2-font-weight($typography-config, 'body-1')};
    --mdc-typography-body1-letter-spacing: #{mat.m2-letter-spacing($typography-config, 'body-1')};
  }

  .mat-mdc-checkbox, .mat-mdc-checkbox.mat-accent, .mat-pseudo-checkbox {
    --mdc-checkbox-selected-checkmark-color: #{$primary-contrast-colour};
    --mdc-checkbox-selected-focus-icon-color: #{$wire-orange};
    --mdc-checkbox-selected-hover-icon-color: #{$wire-orange};
    --mdc-checkbox-selected-pressed-icon-color: #{$wire-orange};
    --mdc-checkbox-selected-icon-color: #{$wire-orange};
    --mdc-checkbox-label-text-color: black;

    --mat-full-pseudo-checkbox-selected-icon-color:  #{$wire-orange};

    --mdc-checkbox-unselected-focus-icon-color: #{$form-element-border};
    --mdc-checkbox-unselected-hover-icon-color: #{$form-element-border};
    --mdc-checkbox-unselected-icon-color: #{$form-element-border};
    --mdc-checkbox-unselected-pressed-icon-color: #{$form-element-border};

    // Border width
    --mdc-checkbox-unselected-focus-icon-width: 1px;
    --mdc-checkbox-unselected-hover-icon-width: 1px;
    --mdc-checkbox-unselected-icon-width: 1px;
    --mdc-checkbox-unselected-pressed-icon-width: 1px;
    // Border radius
    --mdc-checkbox-shape-size: 0px;

    .mdc-checkbox__background {
      border-radius: 0 !important;
      border-width: 1px !important;
    }

    .mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
      display: none;
    }

    // Label font weight
    --mdc-checkbox-label-text-weight: #{mat.m2-font-weight($typography-config, body-1)};

    // Label font size (for reference)
    --mdc-checkbox-label-text-size: #{mat.m2-font-size($typography-config, body-1)};

    // Distance between checkbox and label
    --mdc-checkbox-label-spacing: 0;

    // Optional: Adjust overall touch target size
    --mdc-checkbox-state-layer-size: 20px;

    // Optional: Adjust checkbox icon size
    --mdc-checkbox-icon-size: 16px;


    padding: $form-element-outer-padding;
    padding-right: 0;

    .mdc-checkbox {
      padding: $form-element-outer-padding;
      padding-right: 0;
      margin: 0;

      .mdc-checkbox__native-control {
        left: 0;
        top: 0;
        height: auto;
        width: auto;
      }

      .mdc-checkbox__background {
        left: 0;
        top: 0;
      }
    }

  }
}
