@import url("../../../../style/variables.less");

.form-dialog button {
  margin: @form-element-outer-padding;
}

button.bordered-button.big-btn {
  font-size: 1.2em;
  height: 40px;
}

button.bordered-button.save:not(.mat-button-disabled), button.big-btn.save:not(.mat-mdc-button-disabled), button.bordered-button.green:not(.mat-button-disabled) {
  background: @favourable;
  color: white;
}

button.bordered-button.save.disabled {
  background: @button-disabled;
}

button.bordered-button.clear:not(.mat-button-disabled) {
  background: @tan;
}

button.bordered-button {
  box-shadow: 0 1px 2px #aaa;
  background: @tan;
  color: #333333;
  font-weight: 500;
  font-family: @body-font;
  margin-right: 10px;
}

.bordered-button:active {
  background: tan;
}


button.cancel-button {
  color: @primary-colour;
  border: solid @primary-colour 1px;
  background: white;
}

.action-class {
  button {
    margin: 3px;
    font-weight: 600;
    height: @header-unit;
  }

  button:hover {
    box-shadow: 1px 1px 5px 2px @secondary-color-grey-hover;
  }

}

.action-class button.ok-button, .action-class button.action-button,
.snack-bar-confirm .action-class .confirm-container button.action-button {
  //background-color: @wire-orange;
  //border: 1px solid @wire-orange;
  //color: @link;
}

button.text-btn {
  padding: 0 0.5rem;
  background: transparent;
  border-radius: 4px;

  &:hover {
    background: @text-button-hover;
    transition: @transition-duration ease;
  }
}
