@import url("../../../../style/variables.less");

.page-list-view {
  .cdk-drag {
    height: unset;
  }

  h2 {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;

    > div {
      display: flex;
      align-items: center;
    }

    mat-select, input {
      font-size: 1rem;
      font-family: @body-font;
      font-weight: normal;
      width: 180px;
      margin-right: 20px;
      border: 1px solid #999999;
      height: 22px;
      padding: 2px;
    }
  }

  .container {
    width: 100%;
    max-width: 100%;
    margin: 0 25px 25px 0;
    display: inline-block;
    vertical-align: top;
  }

  .account {
    width: 10%;
  }

  .name {
    width: 25%;
  }

  .owner {
    width: 15%;
  }

  .changed {
    width: 15%;
  }

  .visibility {
    width: 15%;
  }

  .folder {
    width: 20%;
  }

  .list {
    border: solid 2px @tile-header;
    min-height: 60px;
    background: white;
    //border-radius: 4px;
    overflow: hidden;
    display: block;
    height: 66vh;
    overflow-y: auto;
    padding: 10px 10px 20px 10px;
    border-radius: 0px 0px 8px 8px;

    a {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    a:hover {
      color: darkorange;
    }
  }

  .box, .box-header {
    width: 100%;
    padding: 10px 10px;
    align-items: center;
    box-sizing: border-box;
    border-bottom: solid 1px #ccc;
    background: white;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1.166rem;
    cursor: move;
  }

  .box-header {
    padding: 10px 20px;
    background: @tile-header;
    border-radius: 0;
    border-bottom: 0;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .cdk-drag-placeholder {
    opacity: 0;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .box:last-child {
    border: none;
  }

  .list.cdk-drop-list-dragging .box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.cdk-drag {
  a {
    color: black;
    text-decoration: none !important;
    cursor: pointer;
  }
}
