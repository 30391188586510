@import url("../../../../style/variables.less");

.info, .form-dialog .info {
  font-family: @body-font;
  font-weight: bold;
  font-size: 1.1rem;
  color: @primary-colour;
}

.info.warning {
  color: @warning;
}

.info.description, .form-dialog .info.description {
  color: @tile-header;
  font-weight: 500;
  text-transform: none;
  line-height: 24px;
}

.info.normal {
  font-weight: normal;
}

.row-info {
  background: @background;
  color: @dark-disabled;
  padding: 1px 12px;
  border-radius: 12px;
  height: 22px;
  display: flex;
  align-items: center;
}
