@import url("../../../style/variables.less");

.chart {
  .chart-tooltip {
    background: white;
    border: 1px solid grey;
    border-collapse: collapse;
    min-width: 180px;

    th {
      background: rgba(1, 1, 1, 0.4);
      color: white;
    }

    th, td {
      padding: 6px;
      text-align: left;
      border: 1px solid #cccccc;
    }
  }

  .chart-card {
    width: 100%;
    height: 100%;
    text-align: center;

    .zoom-data {
      position: absolute;
      right: 6px;
      top: 32px;
      z-index: 1;
      display: flex;

      label {
        margin-right: 10px;
        color: #333;
        padding-top: 5px;
      }

      i {
        background: #8b8c8e;
        padding: 11px;
        border-radius: 4px;
        color: white;
        cursor: pointer;
      }
    }

    .md-card-content {
      height: 100%;
      padding: 16px; //This needs to be a hard number so that c3 can work out chart limits correctly.
      box-sizing: border-box;
    }
  }

  .svg, .c3 svg {
    font-family: @header-font;
    font-size: 1.1rem;
  }

  .c3-title {
    font-family: @body-font;
    font-size: 1.3rem;
    transform: translateY(-10px);
  }

  .c3-axis-y .tick text, .c3-axis-y2 .tick text {
    transform: translateY(2px);
  }

  .c3-axis-y-label {
    transform: translate(-10px, 0) rotate(-90deg);
  }

  .c3-axis-y2-label {
    transform: translate(10px, 0) rotate(-90deg);
  }

  .c3-axis-x text, .c3-axis-y2, .c3-axis-y text {
    font-size: 1.1rem;
    fill: #1a1a1a;
    color: #1a1a1a;
    font-weight: 500;
  }

  .c3-axis-x g.tick text.last-x-tick-label {
    transform: translateX(-16px);
  }

  .chart-dialog {
    .c3-axis-x text, .c3-axis-y2, .c3-axis-y text {
      font-size: 1.1em;
    }

    .c3-tooltip-custom .c3-tooltip-name td, .legend > div {
      div.legend-name {
        color: @primary-colour;
        font-size: 1.2em;
      }
    }
  }

  .legend {
    position: relative;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .legend > div {
    display: flex;
    align-content: center;
    justify-content: center;
  }


  .legend-right {
    .legend {
      position: absolute;
      right: 10px;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 50%;

      > div {
        height: unset;
      }
    }
  }

  .showing-title .legend {
    transform: translateY(20px);
  }

  .c3-tooltip-custom .c3-tooltip-name td, .legend > div {
    height: 15px;
    vertical-align: middle;

    div.legend-name {
      color: @primary-colour;
      font-weight: bold;
      cursor: pointer;
      opacity: 1;
      margin-right: 8px;
      font-size: 1.1em;
    }

    div.image {
      margin-right: 4px;
    }

    span, div.image {
      width: 15px;
      border-width: 0;
      height: 15px;
    }

    span.area {
      height: 15px;
      opacity: 0.5;
    }

    div.image.area {
      margin-top: 4px;
      height: 12px;
      width: 12px;
      border-width: 2px;

      &.dotted {
        border-style: dotted;
      }

      &.dashed, &.longdashed {
        border-style: dashed;
        border-right: 0;
        border-left: 0;
      }

      &.solid {
        border-style: solid;
      }
    }

    span.bar {
      height: 15px;
    }

    div.image.bar {
      height: 12px;
      width: 12px;
      margin-top: 4px;
    }

    span.line, div.image.line {
      height: 7px;
      border-top-width: 2px;
      border-top-style: solid;
      background-color: transparent !important;
      align-self: center;
      margin-top: 4px;
    }

    div.image.line {
      height: unset;
    }

    span.solid, div.image.solid {
      border-top-style: solid;
    }

    span.dotted, div.image.dotted {
      border-top-style: dotted;
    }

    span.dashed, div.image.dashed {
      border-top-style: dashed;
    }

    span.longdashed, div.image.longdashed {
      border-top-style: dashed;
    }

    span.thickness-thick, div.image.thickness-thick {
      border-width: 0;
      border-top-width: 3px;
    }

    span.thickness-thin, div.image.thickness-thin {
      border-width: 0;
      border-top-width: 1px;
    }
  }

  .c3-legend-item {
    visibility: hidden !important;
    font-size: 0.83em;
    fill: @primary-colour;
    font-weight: bold;
  }

  //classes for configurable line styles
  .c3-chart-lines {
    path.c3-line {
      stroke-width: 2px;
    }

    g.thickness-thin path.c3-line {
      stroke-width: 1px;
    }

    g.thickness-thick path.c3-line {
      stroke-width: 3px;
    }

    g.c3-target-dashed path {
      stroke-dasharray: 4px, 2px;
    }

    g.c3-target-longdashed path {
      stroke-dasharray: 8px, 4px;
    }

    g.c3-target-dotted path {
      stroke-dasharray: 1px, 4px;
      stroke-linecap: round;
    }
  }

  /////////////////////////////////
  .hide-tick-labels .c3-axis-x .tick text, .hide-tick-labels .c3-axis-y .tick text {
    display: none !important;
  }

  g.c3-chart-lines .c3-target-diagonal, g.c3-chart-lines .c3-target-regression {
    .c3-circles .c3-circle {
      display: none;
    }
  }

  .c3-circle {
    opacity: 1;
  }

  .c3-legend-item {
    font-size: 0.83em;
    fill: @primary-colour;
    font-weight: bold;
  }

  .axis path,
  .axis line {
    fill: none;
    stroke: #C0D0E0;
    stroke-width: 1;
    shape-rendering: crispEdges;
  }

  .x.axis line {
    stroke: @primary-colour;;
  }

  .dots0 {
    fill: #0978c9;
  }

  .dots1 {
    fill: #4cc94a;
  }

  .verticalGrid {
    border-color: @primary-colour;
    border-width: 1px;
    border-style: dashed;
  }

  .path0 {
    stroke: @primary-colour;
    stroke-width: 2;
    fill: none;
  }

  .line {
    fill: none;
    stroke: @primary-colour;
    stroke-width: 1.5px;
  }

  .line.above {
    stroke: @primary-colour;
  }

  .start-line line {
    stroke: green;
  }

  .end-line line {
    stroke: rgb(255, 87, 34);
  }

  .event-region {
    fill: rgb(255, 87, 34);
  }

  .event-start, .event-end {
    stroke-dasharray: 3, 3;
  }

  .event-line-hover line {
    stroke-width: 2px;
    stroke: #666666;
    stroke-dasharray: none;
  }

  .event-line-hover text {
    fill: #666666;
  }

  .c3-tooltip-container td.name {
    text-align: left !important;
  }

  .chart-tile {
    height: inherit;
    max-height: inherit;
  }

  .stack-chart {
    height: 100%;
    width: 100%;
  }

  .show-ops-points {
    display: flex;
    position: relative;
    top: -20px;

    .mat-checkbox label {
      color: @primary-colour;
    }
  }

  .quick-charts {
    h2 {
      font-size: 1.4rem;
      margin: 0.6rem 0;
    }

    series-edit-button .quick-charts {
      margin-right: 4px;
    }
  }

  .c3-ygrid-line.hihi line {
    stroke: navy;
    stroke-width: 2px;
    stroke-linecap: round;
  }

  .c3-ygrid-line.hi line {
    stroke: navy;
    stroke-width: 2px;
    stroke-linecap: round;
  }

  .c3-ygrid-line.lo line {
    stroke: navy;
    stroke-width: 2px;
    stroke-linecap: round;
  }

  .c3-ygrid-line.lcl mean {
    stroke-width: 2px;
    stroke-linecap: round;
  }

  .c3-ygrid-line.cl line {
    stroke-dasharray: 3px, 3px;
    stroke-linecap: round;
  }

  .c3-ygrid-line.ucl line {
    stroke-dasharray: 3px, 3px;
    stroke-linecap: round;
  }

  .c3-ygrid-line.lcl line {
    stroke: grey;
    stroke-dasharray: 3px, 3px;
    stroke-linecap: round;
  }

  .c3-ygrid-line.lolo line {
    stroke: navy;
    stroke-width: 2px;
    stroke-linecap: round;
  }

  .c3-ygrid-line.first-sigma line {
    stroke: blue;
    stroke-dasharray: 3px 3px;
    stroke-linecap: round;
  }

  .c3-ygrid-line.second-sigma line {
    stroke: purple;
    stroke-dasharray: 3px 3px;
    stroke-linecap: round;
  }

  .c3-ygrid-line.ucl-lcl line {
    stroke: @error;
    stroke-dasharray: 3px 3px;
    stroke-linecap: round;
  }

  .c3-ygrid-line.first-sigma text {
    fill: blue;
  }

  .c3-ygrid-line.second-sigma text {
    fill: purple;
  }

  .c3-ygrid-line.ucl-lcl text {
    fill: @error;
  }

  .c3-ygrid-line.lo text {
    fill: navy;
  }

  .c3-ygrid-line.lolo text {
    fill: navy;
  }

  .c3-ygrid-line.hi text {
    fill: navy;
  }

  .c3-ygrid-line.hihi text {
    fill: navy;
  }
}

.plotly-chart {
  height: 100%;
  display: block;

  plotly-plot {
    height: 100%;
    display: block;
  }

  text {
    overflow: visible;
  }

  /**Shift the chart title slightly downwards to sit in the middle of the space (because margins have been adjusted in
  the plotly layout config**/

  text.gtitle {
    transform: translateY(15px);
  }
}

.button-margin {
  margin-left: 10px;
}

.dashboard-tile .js-plotly-plot .plotly .modebar {
  right: 10px;
}

.quick-charts select-many-series {
  flex-grow: 0.5;
}
