@import url("../../../../style/variables.less");
@import url("../../../../style/icons.less");

table#forecast_table {
  border: 1px solid @table-border-grey;
}

table.estimates_table {
  border: 1px solid @table-border-grey;
  border-collapse: collapse;
  margin: @form-element-outer-padding;
  width: calc(100% - 16px);
  //thead {
  //  background: #ccc;
  //}
  //
  //td {
  //  text-align: left;
  //  padding: 2px;
  //}

  .action {
    width: 10%;
    text-align: center;
  }

  tr.unsaved td {
    background: peachpuff;
  }
}

.chart-card .statistics {
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  margin-bottom: 14px;
  color: #666666;

  label {
    font-weight: 600;
    margin-right: 10px;
  }

}

.chart-card table.statistics-table {
  margin: 0 30px 0;
  padding: 10px;
  background: white;
  min-width: unset;
  max-width: 300px;
  text-align: left;
  border: 1px solid @table-border-grey;
  font-size: 0.91rem;
  color: #666666;

  td {
    border-right: 1px solid @table-border-grey !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    height: 20px;
  }

  tr.mat-mdc-header-row {
    padding: 0;
    height: 20px;

    th.mat-mdc-header-cell {
      background: #ccc;
      //background: @tile-header;
      padding: 2px;
    }
  }

  tr.mat-mdc-row {
    height: 20px;
  }

  td.mat-mdc-cell:last-of-type, th.mat-mdc-header-cell:last-of-type {
    border-right: 0 solid @table-border-grey;
  }
}

.page.widescreen-60 {
  series-table.adjust-row-height {
    table.series-table {
      tr.mat-mdc-row {
        height: 40px;
      }

      tr.mat-mdc-header-row {
        height: 48px;
      }
    }
  }
}

table.series-table, table#forecast_table, table.statistics-table, table.events-table, table.value-driver-tree-table {
  border-collapse: collapse;
  box-sizing: border-box;
  min-width: 100%;
  position: relative;
  z-index: 0;
  padding-bottom: 0;
  padding-right: 8px;

  th, td {
    min-width: 64px;
  }

  td.mat-mdc-cell {
    border: 0;
    text-align: left;
    background: @disabled;
    padding: 1px 4px;
    vertical-align: middle;
  }

  .mat-mdc-row:nth-child(even) td {
    background: @disabled;
  }

  .mat-mdc-row:nth-child(odd) td {
    background: #deddda;
  }

  tbody tr.mat-mdc-row:nth-child(even) td:nth-child(even) {
    //background: #e6e4e1;
    //background: #e2e0dc;
  }

  tbody tr.mat-mdc-row:nth-child(odd) td:nth-child(even) {
    //background: #deddda;
  }

  tr.mat-mdc-row {
    height: 30px;
  }


  td.mat-mdc-cell:last-of-type, th.mat-mdc-header-cell:last-of-type {
    border-right: 1px solid @table-border-grey;
  }

  td.mat-column-Name, td.mat-column-name {
    min-width: 150px;
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media screen and (max-width: @media-xs) {
      position: static !important;
      min-width: 120px;
      width: unset;
    }
  }

  td.mat-column-Description > div, td.mat-column-description > div, td.mat-column-Alias > div, td.mat-column-alias > div {
    width: 100%;
    //text-overflow: ellipsis;
    //overflow: hidden;
    display: block;
    //white-space: nowrap;
    text-align: inherit;

    > a {
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      overflow: hidden;
      padding-top: 2px;
    }
  }

  td.mat-column-Description > div.editable, td.mat-column-Alias > div.editable {
    width: unset;
    display: flex;
  }

  td.mat-column-Description, .mat-column-Estimate {
    min-width: 200px;
    width: 200px;
    //white-space:nowrap;
    //text-overflow:ellipsis;
    @media screen and (max-width: @media-xs) {
      position: static !important;
      min-width: 60px;
      padding: 1px 2px !important;
      div.editable {
        display: block;
        width: inherit;
      }
    }
  }

  td:not(.freeze-pane).trend-cell {
    text-align: center;
  }

  .mat-column-Status {
    min-width: @header-unit;
    width: @header-unit;
    text-align: inherit !important;

    button {
      width: 100%;
      height: 36px;
      padding-top: 6px;
    }

    .mat-sort-header-arrow {
      display: none !important;
    }

    @media screen and (max-width: @media-xs) {
      position: static !important;
    }
  }

  td.mat-column-Status {
    text-align: center;
    //padding-top:10px !important;
    height: 30px;
  }

  .mat-column-Sparkline {
    width: 150px;
  }

  td.mat-mdc-cell:first-of-type, td.mat-footer-cell:first-of-type, th.mat-mdc-header-cell:first-of-type {
    padding: unset;
  }

  td:not(.freeze-pane) {
    text-align: right;
  }
}

.events-table {
  tr.mat-mdc-row td.mat-mdc-cell {
    text-align: left;
    padding: 2px;
  }

  .mat-column-Duration {
    text-align: center;
  }

  .mat-column-Start, .mat-column-End {
    text-align: center;
    min-width: 80px;
  }
}

button.mat-sort-header-button { //, .mat-sort-header-arrow, .mat-sort-header-stem
  display: inline-block;
  text-align: center;
  width: 100%;
  justify-content: center;
}

.mat-sort-header-arrow {
  display: none;
}

.arrow-down:after, .arrow-up:after {
  content: ' ';
  position: relative;
  left: 2px;
  border: 8px solid transparent;
}

.arrow-down:after {
  top: 10px;
  border-top-color: black;
}

.arrow-up:after {
  bottom: 15px;
  border-bottom-color: black;
}

.arrow-down, .arrow-up {
  padding-right: 10px;
}

.mat-table-sticky {
  border-right: 1px solid #e0e0e0;
}

.kpi {
  padding-left: 10px;
  padding-right: 14px;
  color: red;
  white-space: nowrap;
  font-weight: 600;
}

//Custom Theme Style Overrides here/////////////////////////////////////////////////////////////////

body.light {
  table.series-table {

    tr.mat-mdc-row {
      background: white;
    }

    .mat-mdc-row:nth-child(even) td, .mat-mdc-row:nth-child(odd) td,
    tbody tr.mat-mdc-row:nth-child(even) td:nth-child(even), tbody tr.mat-mdc-row:nth-child(odd) td:nth-child(even) {
      background: #ffffff;
      border-bottom: 1px solid @table-border-grey;
      border-right: 1px solid @table-border-grey;
    }

    .mat-column-Sparkline {
      width: 150px;
    }
  }
}
