
h4 {
  text-transform: uppercase;
  margin: 10px 0px;
}

.side-min {
  width: 30%;
}

.side-max {
  width: 70%;
}

.side-panel {
  box-shadow: -2px 0px 2px #f5f5f5;
  margin-left: 10px;
  background: #fff;
  height: 74vh;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 154px;

}

@media screen and (max-width: 1280px) {
  .side-panel {
    top: 184px;
  }
}

.image-options .mat-option-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.2rem;
}

.image-form .mat-tab-body-wrapper {
  min-height: 300px;
}

mat-select.image-select {
  font-size: 1rem;

  .mat-select-value {
    font-size: 1rem;
  }
}

.context-button {
  position: absolute;
  top: 120px;
  left: 0;
  background: orange;
  width: 100px;
  height: 30px;
  color: white;
}

.flowchart-context-menu {
  width: 400px;
  box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: none;
  background: white;

  ul {
    margin: 0;
  }

  .menu-options {
    list-style: none;
    padding: 0;

    .menu-option {
      font-weight: 500;
      font-size: 1.166rem;
      padding: 10px 40px 10px 20px;
      cursor: pointer;

      a {
        color: inherit;
        text-decoration: none;
        display: flex;
        padding: 10px 40px 10px 20px;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
