@use '@angular/material' as mat;
@use 'app/shared/scss/button-theme' as buttonStyles;
@use 'app/shared/scss/card-theme' as cardStyles;
@use 'app/shared/scss/checkbox-theme' as checkboxStyles;
@use 'app/shared/scss/dialog-theme' as dialogStyles;
@use 'app/shared/scss/form-field-theme' as formFieldStyles;
@use 'app/shared/scss/input-theme' as inputStyles;
@use 'app/shared/scss/menu-theme' as menuStyles;
@use 'app/shared/scss/paginator-theme' as paginatorStyles;
@use 'app/shared/scss/radio-theme' as radioStyles;
@use 'app/shared/scss/select-theme' as selectStyles;
@use 'app/shared/scss/table-theme' as tableStyles;
@use 'app/shared/scss/tooltip-theme' as tooltipStyles;
@use 'app/shared/scss/toggle-theme' as toggleStyles;

@import 'palette';

@mixin app-light-theme {

  $my-primary: mat.m2-define-palette($primary-palette);
  $my-accent: mat.m2-define-palette($accent-palette);
  $my-secondary: mat.m2-define-palette($secondary-palette);

 // Base surface color
  $default-surface: white;
  $dialog-surface: unquote(mat.m2-get-color-from-palette($primary-palette, A200)); // Lighter surface for dialogs
  $dark-surface: mat.m2-get-color-from-palette($primary-palette, 500); // Dark surface for header menus/selects

  $my-background: #f8f8f8;
  $my-on-surface: mat.m2-get-color-from-palette($primary-palette, 900);
  $my-on-dark-surface: #dbd3d2; // Text color for dark surfaces

  $wire-topography: mat.m2-define-typography-config(
          $font-family: 'Rajdhani, sans-serif',
          $body-1: mat.m2-define-typography-level(14.4px, 16px, 500),
          $body-2: mat.m2-define-typography-level(16px, 18px, 500),
          $caption: mat.m2-define-typography-level(14.4px, 16px, 500)
  );

  $wire-light-theme: mat.m2-define-light-theme((
          color: (
                  primary: $my-primary,
                  accent: $my-accent,
                  warn: $my-secondary,
                  surface: $default-surface, // Default surface
                  background: $my-background, // Background for the page
                  on-surface: $my-on-surface, // Text on surface
          ),
          typography: $wire-topography,
          density: -2,
  ));

  //@include angular-material-theme($wire-light-theme);
  @include mat.all-component-themes($wire-light-theme);
  html{
  //@include mat.core-theme($wire-light-theme);
  //@include mat.dialog-theme($wire-light-theme);
  //@include mat.button-theme($wire-light-theme);
  //@include mat.input-theme($wire-light-theme);
  //@include mat.select-theme($wire-light-theme);
  //@include mat.paginator-theme($wire-light-theme);
  //@include mat.card-theme($wire-light-theme);
  //@include mat.table-theme($wire-light-theme);
  //@include mat.checkbox-theme($wire-light-theme);
  //@include mat.slide-toggle-theme($wire-light-theme);
  //@include mat.radio-theme($wire-light-theme);
  //@include mat.fab-theme($wire-light-theme);

  // Include element-specific styles
  @include buttonStyles.wire-button-styles($wire-light-theme);
  @include checkboxStyles.wire-checkbox-styles($wire-light-theme);
  @include cardStyles.wire-card-styles($wire-light-theme);
  @include dialogStyles.wire-dialog-styles($wire-light-theme);
  @include formFieldStyles.wire-form-field-styles($wire-light-theme);
  @include inputStyles.wire-input-styles($wire-light-theme);
  @include menuStyles.wire-menu-styles($wire-light-theme);
  @include paginatorStyles.wire-paginator-styles($wire-light-theme);
  @include radioStyles.wire-radio-styles($wire-light-theme);
  @include selectStyles.wire-select-styles($wire-light-theme);
  @include tableStyles.wire-table-styles($wire-light-theme);
  @include tooltipStyles.wire-tooltip-styles($wire-light-theme);
  @include toggleStyles.wire-toggle-styles($wire-light-theme);
}}
