///Beside the external and main sections, all files are loaded in the file tree order
@import url('assets/less/modules/_fonts.less');
///External///
//@import '~@angular/cdk/overlay-prebuilt.css';
@import url('../node_modules/c3/c3.css');
@import url('../node_modules/pivottable/dist/pivot.min.css');
@import url('../node_modules/c3/c3.css');
@import url('../node_modules/handsontable/dist/handsontable.full.css');
@import url('../node_modules/overlayscrollbars/styles/overlayscrollbars.css');
@import '@webdatarocks/webdatarocks/webdatarocks.min.css';
///Main///
@import url("app/app.component.less");
@import url("app/shared/styles/menus.less");
@import url("app/page-view/page-tile/page-tile.component.less");
@import url('app/date-time/date-time-period.component.less');
///Charts///
@import url('app/tables/series-table/series-table.component.less');
@import url('app/charts/spc-chart.component.less');
@import url('app/charts/charts.less');
///Components///
@import url('app/components/apply-correction/apply-correction.component.less');
@import url('app/components/context/context.component.less');
@import url('app/components/json-table/json-table.component.less');
@import url('app/page-view/page-list-view/page-list-view.component.less');
@import url('app/components/paragraph/paragraph.component.less');
@import url('app/components/pivot-tile/pivot-tile.component.less');
@import url('app/components/pivot-tile-ng/pivot-tile-ng.component.less');
@import url('app/components/value-driver-tree/value-driver-tree.component.less');
@import url('app/components/weather-widget/weather-widget.component.less');
///data-sheets///
@import url('app/data-sheets/estimate-data-sheet-view.less');
@import url('app/data-sheets/log-sheet/log-sheet.component.less');
///flowchart, forecasts///
@import url('app/flowchart/flowchart-focus-view/flowchart-focus-view.component.less');
@import url('app/forecasts/recovery-grade-forecast/recovery-grade-forecast.component.less');
///forms///
@import url('app/forms/forms.less');
@import url('app/forms/pivot-tile-form/pivot-tile-form.component.less');
@import url('app/forms/series-table-form.component.less');
@import url('app/notifications/snackbar/snackbar.component');
@import url('app/notifications/snackbar/confirm-snackbar/confirm-snackbar.component');
///handsonsheets///
@import url('app/handson-sheets/audit-history/audit-history.component.less');
@import url('app/handson-sheets/history-table/history-table.component.less');
@import url('app/handson-sheets/handson-sheet');
///menu_bars///
//TODO after menu moves
///reports///
@import url('app/reports/water-balance-view/water-balance-view.component.less');
///tables///
@import url('app/shared/styles/shared-table-styles.less');
@import url('app/tables/sparkline/sparkline.component.less');
@import url('app/tables/series-table/series-table.component.less');
///user_control///
@import url('app/user_control/user-list.component.less');
@import url('app/user_control/register.component.less');
@import url('app/forms/constant-property-table-form/constant-property-table-form.component.less');

@import url('app/shared/styles/select-search-dropdown-panel.less');
@import url('app/shared/styles/animations.less');

///print styles///
@import url('print-style.less');

///shared styles///
@import url('app/shared/styles/mat-select.less');
@import url('app/shared/styles/buttons.less');
@import url('app/shared/styles/info.less');
@import url('app/components/select-search/select-search.component.less');
@import url('app/shared/styles/menus.less');
@import url('app/shared/styles/mat-dialog.less');
@import url('app/shared/styles/input.less');

@import url('app/components/event-column-selector/event-column-selector.component.less');
