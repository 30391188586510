@use '@angular/material' as mat;
@import 'globals';

@mixin wire-radio-styles($theme) {

  .mat-mdc-radio-button {

    .mdc-radio__native-control:enabled:focus + .mdc-radio__background .mdc-radio__outer-circle,
    .mdc-radio__native-control:enabled:focus + .mdc-radio__background .mdc-radio__inner-circle,
    .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
    .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__inner-circle,
    .mdc-radio:active .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
    .mdc-radio:active .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__inner-circle,
    .mdc-radio:hover .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
      border-color: #{mat.m2-get-color-from-palette($secondary-palette, 500)};
    }

  }
}
