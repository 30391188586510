
#wdr-pivot-view .wdr-credits {
    display: none;
}

.pivot-tile-ng {
  .wdr-ui-element {
    #wdr-toolbar-wrapper {
      display: none;
    }
  }

  .wdr-fields-view-wrap {
    display: none !important;
  }

  .wdr-icon.wdr-filter-icon, .wdr-empty {
    //visibility: hidden;
  }
}

.pivot-ng-editing {
  .pivot-tile-ng {
    .wdr-empty {
      visibility: visible;

    }

    .wdr-ui-element {
      #wdr-toolbar-wrapper {
        display: block;
        width: calc(100% - 16px) !important;

        #wdr-toolbar {
          li#wdr-tab-format, li#wdr-tab-options, li#wdr-tab-fields, li#wdr-tab-fullscreen {
            display: inline-block !important;
          }
        }
      }
    }

    .wdr-fields-view-wrap {
      display: block !important;
    }

    .wdr-icon.wdr-filter-icon {
      visibility: visible;
    }

  }

  #wdr-toolbar-wrapper {
    position: fixed !important;
    z-index: 10;
  }

  #wdr-pivot-view {
    top: 116px;
    position: fixed !important;
    z-index: 1;

    .wdr-grid-layout, #wdr-grid-view {
      overflow: unset;
    }
  }

  .wdr-ui-element.wdr-ui.wdr-fields-view-wrap {
    display: none;
  }
}

#pivot_wrapper {
  //padding: 8px;
}

#wdr-pivot-view .wdr-credits a {
  //display: none;
}
