@import url("../../../style/variables.less");

.registration-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: @header-unit;
  height:90%;
}

mat-option.with-account-row .mat-option-text {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .account-name {
    background: #f3f3f3;
    color: #575757;
    padding: 0 10px;
    border-radius: 11px;
    height: 22px;
    display: flex;
    align-items: center;
  }
}

.register-form {
  background-color: @form-grey !important;
  min-width: 500px !important;
  max-width: 600px;
  max-height: fit-content;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 5px 1px #888888;

  .mat-checkbox-checked .mat-checkbox-background {
    background: #d84325 !important;
  }

  .form-group, form-group-roles {
    display: flex;
    width: 100%
  }

  .form-group-roles {
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .form_group_label {
    font-size: 14px;
    font-weight: 700;
    width: fit-content;
  }

  button.disabled {
    color: @dark-disabled;
    background: @disabled;
  }

  input, .mat-mdc-select {

    font-family: "Rajdhani", sans-serif;
    font-size: 1rem;
    border-radius: 5px;
    border: solid black 1px;
    padding:0 4px;
  }

  .form-group-roles .mat-mdc-select {
    display: flex;
    align-items: center;
    background: #ffffff;
    padding: 5px 1px 5px 8px !important;
    min-width: 300px;
    height: 32px !important;
  }

  .mat-mdc-select-arrow-wrapper {
    background: transparent !important;
  }

  .mat-mdc-select-arrow {
    color: #d84325 !important;
  }

  button {
    font-size: 13px;
    font-weight: 700 !important;
    color: white;
    background: #d84325;
  }

  .role-select {
    min-width: 400px !important;
  }
}