@import url("../../../style/variables.less");

.form-dialog {
  height: 100%;
}

.form-dialog, estimate-data-sheet-focus-view, estimate-data-sheet-view {
  .form-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: @mat-toolbar-height;

    .tab-group-menu {
      flex-grow: 1;
      font-weight: 500;
      font-size: 1.25rem;
      height: auto;
      justify-content: flex-start;

      > div {
        margin: 0 4px;
        cursor: pointer;
        color: #e6e4e1;
        float: none;
      }

      .active {
        color: orange;
        border-bottom: 1px solid orange;
      }
    }

    .hint {
      color: orange;
      text-transform: uppercase;
      cursor: pointer;
      height: auto;
    }
  }

  h3, p, .text-area-element, .list, h4, .mat-mdc-radio-group {
    padding: @form-element-outer-padding;
  }

  table {
    width: 100%;
  }

  .page-tile table {
    margin: 0;
  }

  .text-area-element {
    margin-bottom: @form-ctrl-margin;
  }

  .options-group {

    h3 {
      position: relative;
      top: 10px;
      left: 10px;
      margin: 0;
      background: rgb(230, 228, 225);
      display: inline;
      padding: 0 10px;
      text-align: center;
    }

    > div {
      border: 1px solid @secondary-color-grey;
      padding: 20px 10px 10px 10px;
      margin-bottom: 20px;
      align-items: center;
      height: auto;
    }
  }

  .mat-mdc-form-field, .mat-mdc-checkbox, mat-mdc-radio-group, .spacer {
    flex-grow: 1;
    min-width: 140px;
    width: @form-element-width;
  }
}

//
//  min-width: 850px;
//  color: @primary-colour;
//  font-size: 1rem;
//  @media screen and (max-width: @media-xs) {
//    min-width: 70vw; //default angular max for dialog is 80vw plus 5x2 padding
//  }
//
//  h2 {
//    font-size: 1.4rem;
//  }
//
//  .placeholder {
//    color: @secondary-color-grey;
//  }
//
.flex-half, .mat-mdc-form-field.flex-half {
  flex-grow: 1;
  flex-basis: 50%;
}

.flex-third, .mat-mdc-form-field.flex-third {
  flex-grow: 1;
  flex-basis: 33.33%;
}

.flex-half.alone, .mat-mdc-form-field.flex-half.alone, .select-search.flex-half.alone,
.flex-third.alone, .mat-mdc-form-field.flex-third.alone, .select-search.flex-third.alone {
  flex-grow: 0;
}

//
//  .form-ctrl-margin-top {
//    margin-top: @form-ctrl-margin-top;
//  }
//
//  .form-ctrl-margin-bottom {
//    margin-bottom: @form-ctrl-margin-bottom;
//  }
//
.form-ctrl-margin-right {
  margin-right: @form-ctrl-margin-right;
}

.form-dialog .mat-divider.mat-divider-horizontal {
  margin-top: @form-ctrl-margin-top;
  margin-bottom: @form-ctrl-margin-bottom;
}


.form-dialog .row {
  height: 75px;

  &.auto {
    height: auto;
  }

  .full-width {
    width: 100%;
  }
}

.row.full-height {
  height: 76px;
}

.row.medium-height {
  height: 60px;
}

.row.low-height {
  height: 40px;
}

.row.min-height {
  height: 30px;
}

.row.display {
  height: 40px;
  min-height: 30px;
  align-items: center;

  label {
    margin-right: 20px;
  }
}

.row.list {
  min-height: 20px;
  height: 20px;

  label:not(.mat-mdc-mdc-checkbox-layout) {
    min-width: 140px;
  }
}

//Class for aligning non top-labelled elements with the center of the input of other elements
.row.center .form-element-align, .row.center .mat-mdc-checkbox.form-element-align {
  margin-top: 24px;
}

.align-bottom {
  position: absolute;
  bottom: 48px;
  right: @form-padding;
  left: @form-padding;
  width: -webkit-fill-available;
  z-index: 1;
  background: @form-grey;

  > div {
    background: inherit;
  }
}

.form-dialog .align-bottom {
  border-bottom-left-radius: @border-radius;
  border-bottom-right-radius: @border-radius;
}

.form-dialog.tile-form-dialog .align-bottom:not(.date-period) {
  > div {
    margin-bottom: 36px;
    padding: 20px @header-unit 2px;
    border-top: 2px groove #fff;
    transition: margin 0.5s ease 0s;

    &.dtp-showing {
      margin-bottom: 74px;
      transition: margin 0.5s ease 0s;
    }
  }
}

//  //When using mat-mdc-label with mat-mdc-form-field the placeholder gets hidden. This class overrides that behaviour
//  .mat-mdc-form-field.mat-mdc-form-field-has-label.mat-mdc-form-field-hide-placeholder.override-hide-placeholder {
//    .mat-mdc-select-placeholder {
//      color: rgb(117, 117, 117);
//      -webkit-text-fill-color: rgb(117, 117, 117);
//    }
//  }
//

//
//  .mat-mdc-form-field.two-thirds {
//    flex-grow: 2;
//    width: 380px;
//  }
//

//
//  //for form items that dont have their own margins (checkboxes etc)
//  .row.height-spacer {
//    min-height: 50px;
//  }
//
//  .flex-quarter {
//    flex-grow: 0;
//    flex-basis: calc(25% - @form-ctrl-margin);
//  }
//
//  .custom {
//    max-width: unset;
//    min-width: unset;
//    width: inherit;
//    //margin-right: 20px;
//  }
//
.mat-mdc-radio-checked .mat-mdc-radio-inner-circle {
  background: @wire-orange;
}

//
//  .mat-mdc-radio-disabled.mat-mdc-radio-checked .mat-mdc-radio-inner-circle {
//    background: grey;
//  }
//
//  .mat-mdc-radio-outer-circle {
//    color: @primary-colour;
//    border-width: 1.2px;
//  }

.label-required::after {
  content: "*";
  color: @red;
  font-weight: bold;
  font-size: 16px;
  margin-left: 2px;
  line-height: 4px;
}

.required-info {
  width: 100%;
  text-align: right;
  margin-bottom: -14px;
  color: @red;
}

//
//  label.inline {
//    margin-right: 20px;
//  }
//
//  .inline, label.inline {
//    label {
//      margin-right: 20px;
//    }
//
//    .mat-mdc-form-field-infix {
//      display: flex;
//      align-items: center;
//
//      .mat-mdc-form-field-label-wrapper {
//      }
//
//      mat-mdc-select, input {
//        top: 0 !important;
//      }
//    }
//  }
//
//  input, textarea {
//    line-height: 22px;
//    background: white;
//    border: 1px solid @tile-header;
//    padding: 2px 4px;
//  }
//
//
//  .form-section {
//    display: flex;
//    //flex-basis: 25%;
//    .mat-mdc-form-field {
//      //margin:1%;
//      //line-height:@unit;
//    }
//  }
//
//  .showing-hints {
//    .mat-mdc-form-field:hover label, .mat-mdc-radio-group:hover label,
//    .mat-mdc-checkbox:hover label, .mat-mdc-form-field:hover mat-mdc-label {
//      color: orange;
//    }
//  }
//
//
//
//  .info {
//    font-weight: bold;
//    font-size: 1em;
//  }
//
//  .inline-tip {
//    border: 1px solid orange;
//    background: #efefef;
//    line-height: 1.3;
//    padding: 5px;
//    margin: 10px 16px 10px 0;
//    display: flex;
//  }

.event-query-builder-tab .mat-mdc-tab-label {
  width: auto;
}

mat-mdc-tab-body .event-query-builder-tab {
  margin: -@header-unit !important;

  .mat-mdc-tab-list {
    background-color: rgba(102, 102, 102, 0.8) !important
  }

  .mat-mdc-tab-body-wrapper {
    box-shadow: none !important;
  }
}

//
//.form-margin-right {
//  //margin-right: 20px;
//}
//
.btn-save, .btn-close, .btn-download, .btn-get-correction, .btn {
  display: inline-flex;
  color: orange;
  float: right;
  text-transform: uppercase;
  background: transparent;
  border-width: 0;
  font-size: 1.166em;
  font-family: @body-font;
  cursor: pointer;

  &:disabled {
    color: @primary-colour;
    cursor: default;
  }
}

.btn-save:before, .btn-close:before, .btn-download:before {
  font: normal normal normal 10px/1 FontAwesome;
  padding: 5px 4px;
}

.btn-save:before {
  content: "\f00c";
}

.btn-close:before {
  content: "\f00d";
}

.btn-download:before {
  //content: "\f019";
  content: "\f063";
}

//
//.tile-buttons button {
//  box-shadow: 0 1px 2px #aaa;
//}
//
.audit-text {
  position: absolute;
  bottom: 12px;
  left: @form-padding;
  color: orange;
  font-size: 1.1rem;
}

//
//.series-component-rel-buttons {
//  align-self: center;
//  display: flex;
//
//  button {
//    margin: 0 0 0 4px;
//  }
//}
//
//.disabled {
//  border: none;
//  font-weight: 500;
//}
//
//h3.constant-property-header {
//  margin-bottom: -36px;
//  margin-top: 30px;
//  font-weight: 600;
//  text-transform: none;
//  font-size: 1.1em;
//}
//
//.form-dialog {
//  .section-drag-list {
//    border-radius: 4px;
//    overflow: hidden;
//    display: block;
//  }
//
//  div.cdk-drag {
//    padding: 10px 10px;
//    border-bottom: solid 1px #ccc;
//    color: rgba(0, 0, 0, 0.87);
//    align-items: center;
//    justify-content: space-between;
//    box-sizing: border-box;
//    cursor: move;
//    background: white;
//    font-size: 1rem;
//    margin-bottom: 4px;
//
//    .cdk-drag-handle {
//      margin-bottom: 10px;
//
//      i {
//        cursor: grab;
//        padding-right: 10px;
//      }
//    }
//  }
//}
//
//.form-dialog.series-form {
//  div.row.tile-search-paginator .mat-mdc-form-field, div.row.tile-search-paginator .mat-mdc-form-field-infix,
//  .tile-search-paginator .mat-mdc-form-field .mat-mdc-select {
//    margin: 0;
//    top: 0;
//  }
//
//  .mat-mdc-tab-header-pagination {
//    display: flex;
//  }
//
//  .mat-mdc-tab-body-wrapper {
//    border-top-left-radius: 8px;
//  }
//
//  .mat-mdc-tab-header-pagination-chevron {
//    height: 12px;
//    width: 12px;
//  }
//}
//


.disabled-drag-item {
  background-color: #cccccc !important;
  margin-bottom: 0 !important;
}

//
//form custom-chart-form {
//  width: 100%;
//  min-width: 780px;
//}
//
//
table-column-menu.inline, .form-dialog table-column-menu.inline {
  background-color: @primary-colour;
  display: flex;
  border-radius: 4px;

  div.row {
    min-height: 46px;
    height: auto;
  }
}

//
//button.copy-button {
//  background: transparent;
//  border: none;
//  padding: 6px;
//  border-radius: 2px;
//  cursor: pointer;
//  margin-left: 6px;
//}
//
//button.bordered-button {
//  box-shadow: 0 1px 2px #aaa;
//  background: #c2b49b;
//  color: #333333;
//  font-weight: 500;
//  font-family: @body-font;
//  //letter-spacing:1px;
//  margin-right: 10px;
//}
//
//.bordered-button:active {
//  background: tan;
//}
//
//.register-form {
//  //box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
//  margin: 0 auto;
//  max-width: 800px;
//  min-width: 640px;
//  background: #e6e4e1;
//  padding: 20px 20px 40px;
//  border-radius: 8px;
//
//  button {
//    margin-top: -14px;
//    font-weight: bold !important;
//  }
//}

.is-invalid {
  background: #f0e1e1 !important;
}

.mat-mdc-hint {
  color: red;
}

button.disabled {
  color: #ccc;
}

.reset-password {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.user .email {
  i {
    position: absolute;
    top: 30px;
    z-index: 10;
  }
}
