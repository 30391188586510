@import url("../../../../style/variables.less");

.empty-dashboard {
  position: absolute;
  top: 100px;
  left: calc((100vw - 940px) / 2); //Should be -1080px (width) but adjusting by eye
  width: 1080px;
  font-size: 1.33rem;

  h1 {
    font-size: 2.8rem;
  }

  a {
    color: @medium-gradient;
  }
}

.section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  //height: @tile_h_medium; //the default
  align-items: center;
  justify-content: center;
  min-height: 30px;
}

.section.auto > div {
  height: auto;
  align-self: flex-start;

  .dashboard-tile .tile-content {
    overflow-y: hidden;
    overflow-x: auto;
  }

}

.section > div {
  height: 100%;
  padding: 0.7%; //8px; //the guttering between tiles

}

//.section > div.quarter {
//  width: 25%;
//  min-width: @tile_w_quarter;
//
//  .cdk-drag-handle {
//    width: calc(25% - 84px); //size of menu on right plus cdk padding
//  }
//}

//.section > div.third {
//  width: 33%;
//  min-width: @tile_w_third;
//
//  .cdk-drag-handle {
//    width: calc(33% - 84px); //size of menu on right plus cdk padding
//  }
//}

//.section > div.two-thirds {
//  width: 66%;
//  min-width: @tile_w_two_thirds;
//
//  .cdk-drag-handle {
//    width: calc(66% - 168px); //size of menu on right plus cdk padding
//  }
//}
//
//.section > div.half {
//  width: 50%;
//  min-width: @tile_w_half;
//
//  .cdk-drag-handle {
//    width: calc(50% - 120px);
//  }
//}
//
//.section > div.full {
//  width: 100%;
//  min-width: @tile_w_full;
//}

//.section {
//  .cdk-drag {
//    height: 100%;
//  }
//
//  .cdk-drag-handle {
//    position: absolute;
//    z-index: 1;
//    height: @header-unit;
//    width: 30px !important;
//    min-width: 30px;
//    cursor: move;
//    color: white;
//  }
//}

//-----Tile styles----------------------//
page-tile {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  //border:2px solid @tile-header;
  //border-top:0;
  //border-radius: 0 0 @border-radius @border-radius;
}

.column-tile {
  //max-height: 74vh;
  margin-bottom: 19px;
  position: relative;
}

.column-tile .tile-content {
  min-height: 100px;
}

.column-tile .dashboard-tile .tile-content {
  height: unset;
  max-height: calc(82vh - 28px);
}

quick-charts page-tile.no-format .dashboard-tile.showing-comments {
  box-shadow: 1px 0 5px 1px #ccc;

}

.dashboard-tile {
  width: 100%;
  height: 100%;
  background: @link;
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

  .tile-content {
    overflow: auto;
    width: 100%;
    height: calc(100% - 28px);
    border-radius: inherit;
    text-align: center;
  }

  ol {
    list-style: decimal inside none;
  }

  div.tile-header {
    display: flex;
    position: relative;
    padding: 0;
    //height: @unit;
    height: 28px; // hardcoding to 28px as changing unit causes a large part of UI to look inconsistent
    background: @tile-header;
    color: @link;

    > span {
      padding: 0 10px 0 0;
      cursor: pointer;
      position: absolute;
      right: 0;
      background: transparent;
    }

    h2 a {
      color: @link !important;

      i {
        padding: 0 8px;
        cursor: pointer
      }
    }

    h2 button {
      background: transparent;
      color: @link !important;
      border: none;
      border-radius: 4px;
      font-size: 1.2rem;
      cursor: pointer;

      &:disabled {
        color: @button-disabled !important;
        cursor: default;
      }
    }

    h2 button:active {
      background: @primary-colour;
    }

    h2 {
      font-family: @header-font;
      font-size: 1.166rem;
      margin: 0;
      padding-top: 4px;
      padding-left: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: whitesmoke;
      display: flex;

      span {
        padding-left: 20px;
        font-weight: 400;
        font-size: 1rem;
        line-height: 20px;
      }
    }

    button .warning {
      color: #ef7a3d;
      background: white;
      border-radius: 10px;
    }

    div.date-period-icon {
      img {
        height: 16px;
      }

      margin-top: 11px;
      position: absolute;
      right: 54px;
    }

    .tile-header-right {
      right: 0;
      position: absolute;
      display: flex;
      //I'm pretty sure this isn't needed any more cos tile editing is in a dialog and it's creating an issue when scrolling under the dtp
      z-index: 200;
      padding-left: 10px;
      border-radius: 4px;
      height: 28px;

      > span.mat-menu-trigger {
        display: none;
      }
    }

    h2 .tile-header-right {
      right: 160px;
      top: 6px;
    }

  }

  div.tile-no-data {
    position: absolute;
    top: 28px;
    bottom: 0;
    width: 100%;

    background: @disabled;
    padding: 20px;
    color: @primary-colour;
    z-index: 111;

    i {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .date-period-range-select {
    min-width: 80px;
    margin-right: 10px;
    align-self: center;
    height: @unit;
    background: transparent;

    .mat-mdc-select {
      background: inherit;

      .mat-mdc-select-value, .mat-mdc-select-arrow {
        color: white;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }

  .hidden-header .date-period-range-select {
    .mat-mdc-select .mat-mdc-select-value, .mat-mdc-select-arrow {
      color: transparent;
    }

    &:hover {
      background: @tile-header;

      .mat-mdc-select .mat-mdc-select-value, .mat-mdc-select-arrow {
        color: white;
      }
    }
  }

  .dtp_picker {
    background: rgba(1, 1, 1, 0.5);
    position: relative;
    height: 72px;
    min-width: 800px;
    top: 40px;
    left: 20px;
    padding: 6px 10px;
    border-radius: 4px;
    z-index: 1;

    date-time-period.date-period.inline {
      width: 97%;
      left: 1.5%;
    }

    .action-buttons {
      margin-top: 40px;
    }
  }

}

@media screen and (min-device-width: 768px) and (max-device-width: 1112px) {
  .tile-grid-item div.tile-header {
    height: 40px;

    h2 {
      font-size: 1.6rem;
      padding-top: 6px;
    }

    h2 button, h2 a {
      padding: 0 12px;
      cursor: pointer;
      font-size: 2rem;
    }
  }

  .tile-grid-item .tile-content {
    height: calc(100% - 56px);
  }
}

.dashboard-tile.showing-comments {
  border-radius: 0;
}

.tile-content.series-table, .tile-content.log-sheet {
  background: #e3e1de;
}

.tile-content.data-exceptions-table {
  padding: 10px;
}

.page.editing-mode {
  page-tile .no-format {
    border: 1px solid @tile-header;
  }
}

.tile-content.hide-scroll.flowchart {
  overflow: hidden;
}

page-tile.no-format .dashboard-tile, page-tile div.no-format.dashboard-tile {
  border: 0;

  .tile-content {
    overflow: hidden;
    height: 100%;
  }

  .tile-content.paragraph:not(.hide-scroll) {
    overflow: auto;
  }

  div.tile-header {
    background: transparent;
    height: 0 !important; //will override tile header 40px on presentation mode but not loose the edit
    h2 {
      visibility: hidden;
    }

    > span i {
      background-color: #595859;
      border-radius: 2px;
      width: 32px;
      background-size: 700% !important;
      position: absolute;
      //top:12px;
      right: 12px;
      visibility: hidden;
      z-index: 1001;
      height: inherit;
    }
  }
}


page-tile .no-format:hover div.tile-header > span i {
  visibility: visible;
  height: 28px;
}

page-tile:hover .comment-button, .chart-dialog-chart:hover .comment-button {
  visibility: visible;
}

page-tile .dashboard-tile .tile-header-right.hidden-header, page-tile > div.no-format.dashboard-tile .tile-header-right {
  color: transparent;

  .date-period-range-select .mat-select-disabled {
    color: transparent;
  }
}

.editing-mode page-tile div.no-format.dashboard-tile.dashboard-tile:hover .tile-header {
  position: absolute;
  width: 100%;
  z-index: 1;
  height: @unit !important;
  background: @tile-header;
  visibility: visible;
  color: white;

  h2 {
    visibility: visible;
  }

  .tile-header-right {
    color: white;
  }
}

.tile-menu {
  //see global styles in app.component.less
}

.tile-buttons button {
  font-family: inherit;
  background: inherit;
  color: inherit;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: 400;
  position: relative;
  z-index: 1;
}

.icon-comment {
  position: absolute;
  bottom: 12px;
  right: 10px;
  cursor: pointer;
  z-index: 10000;

  span {
    display: none;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-family: @body-font;
    text-align: right;
  }
}

.icon-comment.show-drawer:not(.tile-dtp .icon-comment.show-drawer) {
  background-image: url('../../../../icons/comment-highlight.svg');
}

.icon-comment.show-drawer, .icon-comment-invert.show-drawer, .icon-comment.has-comment {
  background-image: url('../../../../icons/comment-highlight.svg') !important;
  visibility: visible;
}

.icon-comment-header {
  background-image: none !important;
  left: unset;
  bottom: unset;
  top: 0;
  right: 40px;
  margin-right: 50px;

  span {
    display: flex;
  }
}

.icon-comment.show-drawer.icon-comment-header {
  span {
    color: orange;
  }
}

//-----End Tile styles----------------------//

page-view gridster.tile-grid gridster-item.see-through {
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }

  page-tile.see-through {
    opacity: 0.7;
  }
}

//General class to show when the tile is in edit mode according to tileData.editingSubject
.tile-editing {
  border: 2px solid orange;
}


@media screen and (max-width: @media-xs) {
  .empty-dashboard {
    width: 100%;
  }

  .section > div {
    width: 100% !important;
    min-width: 100% !important;
    height: auto !important;
  }

  .section-title {
    font-size: 2rem;
  }
}

//Custom Theme Style Overrides here/////////////////////////////////////////////////////////////////

body.light {
  .dashboard-tile {
    background: white;
  }

  .tile-content.series-table, .tile-content.log-sheet {
    background: white;
  }

  .editing-mode .dashboard-tile {
    background: white;
  }
}

.tile-button-icon {
  cursor: pointer !important;
}
