@import url("../../../../style/variables.less");

.tree-progress-bar {
  //margin-left: 30px;
}

.info {
  font-weight: bold;
  font-size: 1em;
}

.select-search {
  flex-grow: 1;
  //min-width: 140px;
  //justify-self: end;
  //margin-right: 20px;
  //position: relative;
}

.analysis-tools {
  .as-split-area {
    background: @form-grey;
  }

  .vdt-page {
    height: 80vh;

    .page-vdt-area {
      overflow: hidden;

      svg {
        height: 550px;
      }
    }
  }
}

value-driver-tree-view {
  .value-driver-tree-view {
    > div {
      height: 60px;

      > div {
        width: 460px;
        margin-right: 20px;
      }

      > button {
        cursor: pointer;
        border: none;
        height: 40px;
        font-size: 16px;
        margin-left: 20px;
        align-self: flex-end;
        background: transparent;
      }
    }

    .formula-view-toggle {
      align-self: flex-end;
      margin-bottom: 10px;
      width: 200px;
    }
  }

  .mat-form-field, .mat-form-field-infix {
    margin-bottom: 0;

    .mat-select {
      top: 0;
    }
  }

  .as-split-area {
    background: @form-grey;
  }

  .vdt-page {
    border: 2px dashed #ccc;
    height: 80vh;

    .page-vdt-area > div {
      height: 99%;
    }

    .value-driver-tree {
      height: 100%;
      min-height: 50vh;
    }
  }
}

.context-menu {
  fill: #999999;
}

div.vdt-tile {
  height: 100%;
  background: @form-grey;

  > div {
    height: 100%;

    svg {
      height: 99%;
    }
  }
}

#value_driver_tree > div, svg.value-driver-tree {
  width: 100%;
  overflow: auto;
  background: @form-grey;


  #static_svg_g {
    cursor: grab;
  }

  g.node, path.link {
    //transition: all 0.5s ease;
  @include transition(.3 s width ease-in-out);
  }

  .rect-node, .rect-node-children {
    //stroke-width: 1px !important;
    //stroke: rgba(236, 104, 59, 1);
    //stroke: #cccccc;
    fill: @off-white;
  }

  .rect-node-children {
    fill: @off-white;
  }

  foreignObject {
    border-radius: 2px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    cursor: default;
    box-shadow: 2px 1px 20px #ccc;
    border: 1px solid #ccc;
  }

  .circle-node {
    stroke-width: 1px !important;
    stroke: #cccccc;
    fill: @off-white;
  }

  .plus-line, .minus-line {
    stroke: @primary-colour;
    stroke-width: 2px;
  }

  .node-text, .info {
    font-family: @body-font;
    font-weight: 500;
  }

}

.value-driver-tree-table {
  overflow: hidden;

  td.mat-mdc-cell.mat-column-depth {
    width: 40px;
    text-align: center;
  }
}

table.value-driver-tree-table .mat-mdc-row:nth-child(odd) td {
  background: white;
}

table.value-driver-tree-table tbody tr.mat-mdc-row:nth-child(odd) td:nth-child(even) {
  background: white;
}

table.value-driver-tree-table {
  tbody tr.mat-mdc-row:nth-child(even) td:nth-child(even) {
    background: #f5f5f5;
  }
}
