@import url("../../../style/variables.less");

.date-period {
  font-family: @header-font;
  position: fixed;
  right: 0;
  left: 0;
  z-index: (@z-index-init + 1);
  top: @mat-toolbar-height;
  height: @header-unit;
  background: @gradient;
}

.date-period-font() {
  font-family: @header-font;
  font-weight: 600;
  font-size: 1.1rem;
}

.date-period {
  > div {
    background: @date-period;
    color: @primary-colour;
    height: @date-period-height;
    margin-top: 2px;
    .date-period-font();

    .row.dtp-container {
      height: inherit;
    }
  }

  label {
    text-transform: uppercase;
    font-size: 1.12rem;
    font-weight: 600;
    text-align: right;
    white-space: nowrap;
  }

  .time-traveler-label {
    white-space: nowrap;
    height: @date-period-height;
    align-items: center;

    .chevron {
      display: inline-block;
      background: url("../../../icons/chevron.png") center center;
      background-size: 100%;
      height: calc(100% - 2px);
      width: 14px;
      margin: -2px 6px 0 @dtp-padding;
    }
  }

  label.text {
    margin: @dtp-padding;
    display: flex;
    align-items: center;
  }

  .date-period-icon, .range-icon {
    width: 16px;
    min-width: 16px;

    &:hover {
      width: 16px;
      border-radius: 50px;
      background-color: #b0a696;
    }
  }

  .date-period-icon {
    cursor: pointer;
  }

  .timezone-selector-trigger {
    display: flex;
    width: 28px;
    height: 28px;
    padding: 4px;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 17px;
  }

  .range-icon {
    margin: 0 0 0 @dtp-padding;
  }

  .range, .period, .calendar {
    display: flex;
    align-items: center;
  }

  .mat-mdc-select, .mat-mdc-select.calendar-select {
    margin-left: @dtp-padding;
    background: @date-period-light;
    display: flex;
    border: 0;
    .date-period-font();
  }

  .mat-mdc-select-trigger {
  }


  .mat-mdc-select-value {
  }

  .mat-mdc-select-arrow {
  }

  .mat-mdc-select-arrow svg {
    fill: black;
  }

  .mat-mdc-button.period-select, .mat-mdc-button.range-select {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 600;
    background: @date-period-light;
    border-radius: 0;
    height: 28px;
    line-height: 28px;
    letter-spacing: normal;
    margin-left: @dtp-padding;
    text-align: left;
    padding: 0 @dtp-padding;
    color: @primary-colour;

    .button-icon {
      top: -1px;
      position: relative;
    }

    .mat-icon {
      vertical-align: middle; /* Ensure vertical alignment */
    }
  }


  label.text {
    margin-left: (@dtp-padding * 2);
  }

  .range label.text, .time-traveler-label .text {
    margin-left: @dtp-padding;
  }

  .period:after, .datetime-pager:before, .datetime-pager:after {
    content: '';
    border-right: 2px solid @contrast-colour;
    width: (@dtp-padding * 2);
    height: 80%;
  }

  .datetime-pager:after {
    width: @dtp-padding;
  }

  label.text.end-date-min {
    display: none;
  }

  .start-date, .end-date {
    display: flex;

    i.fa-caret-down {
      position: relative;
      right: -6px;
      font-size: 15px;
    }

    .date-part {
      width: 130px;
    }

    .mat-datepicker-toggle {
      height: 32px;
      display: flex;
      align-items: center;
      right: 34px;
      position: relative;

    }
  }

  .datetime {
    padding-left: 4px;
    background: @date-period-light !important;
    border: 0;
    margin: 3px 0 3px @dtp-padding;
    font-family: @header-font;
    font-weight: 500;
    font-size: 1.1rem;
    width: 120px;
    height: 26px;
  }

  .datetime.time {
    width: 80px;
    height: 26px;
    margin-left: 0;
  }

  .datetime:hover {
    background: #c9c4bb !important;
  }

  .datetime-pager {
    display: flex;
    align-items: center;
    //color: @contrast-colour;
    font-size: 1.42rem;

    i {
      margin: 0 0 0 10px;
      cursor: pointer;
      border-radius: 6px;
      border-style: solid;
      border-color: @date-period;
      border-width: 2px;
    }

    i:hover {
      background: #b0a696;
    }

    i:active {
      border-style: inset;
    }
  }

  .dtp-refresh {
    display: flex;
    align-items: center;
    font-size: 17px;
    padding: 6px 10px 7px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 16px;
  }

  .dtp-refresh:active {
    border-style: inset;
    border-width: 2px;
    //background: #998e7a;
    //transition: background 0.5s;
  }

  .toggle-dtp {
    display: none;
  }
}

.nested-range-menu-panel {
  min-width: 180px;
  background: white;

  button.mat-menu-item {
    font-family: @body-font;
    height: 2.66rem;
    line-height: 2.66rem;
    background: white;
    font-size: 1.1rem;
    font-weight: 500;

    &:hover {
      background: #F3F3F3;
    }
  }
}

.calendar-select, .range-select, .period-select {
  padding: 0 5px;
  background: #d7cfc4;
  height: calc(32px - 4px);


  &:hover {
    background: #c9c4bb;
  }

  .mat-mdc-select-value {
    padding-right: 4px;
  }
}

.calendar-select {
  .mat-mdc-option-text {
    display: inline-flex;
    justify-content: space-between;
  }
}

label.text, .range-icon {
  display: none !important;
}

label.text.end-date-min, .period label.text {
  display: flex !important;
}

label.text.end-date-min {
  margin-right: @dtp-padding;
}

.start-date .date-part input.date-time {
  margin-left: (@dtp-padding * 2);
}

input[type='time']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  margin-left: 0;
}

@media (max-width: @media-lg) {
  .date-period .time-traveler-label .chevron, .time-traveler-label .text {
    display: none;
  }
}

@media (max-width: @media-md + 1px) {
  .date-period .range-icon {
    display: none;
  }

  .time-traveler-in-mobile-container {
    margin-top: auto;
    margin-bottom: auto;
  }

  .page-title button.icon-refresh, .time-traveler-label {
    display: none;
  }
}

@media (max-width: @media-sm) {
  .date-period .start-date .date-part, .date-period .end-date .date-part {
    width: 120px;
  }

  .date-period .datetime {
    width: 110px;
  }

  .date-period .datepicker .datetime.time {
    width: 60px;
  }
}

@media (min-width: @media-md + 1px) {


  .time-traveler-in-mobile-container {
    display: none;
  }
}

date-time-period.date-period.wrap {
  position: relative;
  display: block;
  height: 72px;
  top: unset;

  > div {
    height: 72px;
    padding: 0 !important;

    .dtp-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .time-traveler-label {
        display: none;
      }

      .mobile-container, .datetime-pager {
        display: none;
      }

      .period:after {
        border: 0;
      }

      label.text.end-date-min {
        display: none !important;
      }

      label.text.end-date, label.text.start-date {
        display: inline-flex !important;
        width: 40px;
        min-width: 40px;
        margin-left: @dtp-padding;
      }

      .datetime:not(.time) {
        //width: 90px;
      }

      .start-date .date-part, .end-date .date-part {
        //width: 100px;
      }

      .start-date .mat-datepicker-toggle, .end-date .mat-datepicker-toggle {
        height: 24px;
      }

      .datepicker {
        flex-direction: column;
        padding-top: 5px;
        width: 100%;
        border-right: 0;
        justify-content: center;
        padding-right: 8px;

        .end-date {
          margin-top: 4px;
        }

        .datetime {
          font-size: 1rem;
          max-width: 180px;
          margin-top: 0;
        }
      }

      .dtp-refresh {
        display: none;
      }
    }
  }

}

@media (max-width: @media-xs) {
  mat-toolbar .right-mat-menu button.toggle-dtp {
    display: inline-block;
  }

  .date-period {
    display: none;
  }

  .visible-dtp {
    .date-period {
      display: block;
      height: 80px;
    }

    .date-period > div {
      height: 72px;
      padding: 0 !important;

      .dtp-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .time-traveler-label {
          display: none;
        }

        .mobile-container, .wrap {
          padding-top: 5px;
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .period:after {
          border: 0;
        }

        label.text.end-date, label.text.start-date {
          display: none;
        }

        label.text.end-date-min {
          display: inline-flex;
          margin-left: @dtp-padding;
        }

        .datetime:not(.time) {
          width: 90px;
        }

        .start-date .date-part, .end-date .date-part {
          width: 100px;
        }

        .start-date .mat-datepicker-toggle, .end-date .mat-datepicker-toggle {
          height: 24px;
        }

        .datepicker {
          padding-top: 5px;
          width: 100%;
          border-right: 0;
          justify-content: center;
          padding-right: 8px;

          .datetime {
            font-size: 1rem;
            max-width: 180px;
            height: 23px;
            margin-top: 0;
          }
        }

        .dtp-refresh {
          position: relative;
          top: -60px;
          left: calc(50% - 24px);
        }
      }
    }
  }
}

////inline date-time-period component styles///////////////////////////////////////////////////
.form-dialog .date-period.inline .row {
  min-height: unset;
  align-items: center;
}

.date-period.inline {
  position: absolute;
  top: unset !important;
  overflow: hidden;
  width: 100%;
  height: @header-unit !important;
  background: inherit;

  .row {
    min-height: unset;
    align-items: center;
  }

  > div {
    background: inherit;
    margin-top: 0;
    padding-left: 0;
    height: @header-unit !important;

    > div {
      height: @header-unit !important;
      background: #c2b49b;
    }
  }

  > div.disabled > div {
    background: @date-period-disabled;
    color: @date-period-disabled-text;

    .mat-mdc-select, .mat-mdc-select-trigger {
      background: @date-period-disabled !important;
      color: @date-period-disabled-text;
    }

    .mat-mdc-select-value span, input {
      background: @date-period-disabled !important;
      color: @date-period-disabled-text;
    }

    input[type='time']::-webkit-calendar-picker-indicator {
      cursor: default;
      color: @date-period-disabled-text;
      fill: @date-period-disabled-text;
      stroke: @date-period-disabled-text;
      background: @date-period-disabled;
    }

    .period:after, .datepicker:after {
      border-color: @date-period-disabled-text;
    }
  }

  .period:after, .datepicker:after {
    content: '';
    border-right: 2px solid @contrast-colour;
    height: @unit;
    width: @dtp-padding;
  }

  label.text {
    margin-right: @dtp-padding;
  }

  .time-traveler-label {
    display: none !important;
  }

  //.date-period-icon {
  //display: none !important;
  //}

  .date-period .start-date .date-part, .date-period .end-date .date-part {
    width: 120px;
  }

  .date-period .datetime {
    width: 110px;
  }

  .date-period .datetime.time {
    width: 70px;
  }

  label.text {
    display: none;
  }

  label.text.end-date-min {
    display: inline-flex;
  }

  .datetime-pager {
    display: none;
  }

}

.date-period.inline.align-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.date-period.inline.align-top {
  top: 0 !important;
}

.form-dialog .mat-mdc-dialog-content .date-period.inline.align-bottom {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  .dtp-container {
    padding-left: @header-unit;
  }

  .mat-datepicker-toggle {
    right: 46px;
  }

  .mat-mdc-select:not(.calendar-select) .mat-mdc-select-arrow-wrapper > div.mat-mdc-select-arrow {
    border: none;
    width: 10px;
    height: 5px;
    color: @date-period-text;

    svg {
      display: block;
      top: -2px;
      fill: black;
    }
  }
}

.form-dialog .date-period.align-bottom.slide {
  bottom: 0;
  height: 0 !important;
  transition: height 0.5s ease 0s;
}

.form-dialog .date-period.align-bottom.slide.showing {
  bottom: 0;
  height: 36px !important;
  transition: height 0.5s ease 0s;
}

//
/////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  .dtp-refresh.dtp-immutable {
//    display: flex;
//  }
//
//  @media (min-width: @media-m) {
//    .dtp-refresh {
//      display: flex;
//    }
//  }
//}
//

//@media (min-width: @media-m) {
//   .date-period .datetime-pager {
//    padding-right: 12px;
//    border-right: 2px solid #dbd3d2;
//  }
//}
//
.large-font {
  //Datetime-period
  @media screen and (min-width: @media-xs) {
    .date-period > div {
      height: 36px !important;

      > div {
        height: inherit !important;
      }
    }

    .date-period {
      height: 45px;

      .row label.time-traveler {
        display: none;
      }

      .range {
        height: inherit;
      }

      .range .mat-mdc-select {
        font-size: 18px;
      }

      .date-period .mat-mdc-select-value span {
        padding: 1px 2px;
      }

      div.datepicker {
        height: inherit;
      }

      input.datetime {
        font-size: 16px;
      }
    }
  }
}

////Calendar popup styles copied from default angular material theme///
.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
  background-color: #fff !important;
  color: rgba(0, 0, 0, .87);
  padding: 1rem;
}

.mat-calendar {
  //font-family: Roboto, "Helvetica Neue", sans-serif;
  font-family: @header-font;
}

.mat-calendar-body-cell-content:before {
  display: none;
}

.mat-calendar-body-cell:hover {
  z-index: 2;
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, .38);
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
  background: white;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, .54);
}

.mat-calendar-body-label, .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-body-label, .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-body-label, .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-body-disabled {
  > div {
    color: #666666;
  }
}

.mat-calendar-table-header-divider::after {
  content: '';
  position: absolute;
  top: 0;
  left: -8px;
  right: -8px;
  height: 1px;
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, .12);
}

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, .87);
  border-color: transparent;
}

////Custom calendar styles/////////

mat-month-view .mat-calendar-body-today:hover::after {
  content: 'Today';
  position: absolute;
  left: 0;
  top: 24px;
  min-width: 200px;
  border: 1px #aaaaaa solid;
  border-radius: 4px;
  background-color: #3a3a3c;
  padding: 6px 0;
  color: #ffffff;
  font-size: 14px;
  z-index: 1;
}

mat-month-view .current-calendar, mat-month-view .other-calendar, .start-picker mat-month-view .selected-start,
.end-picker mat-month-view .selected-end, .date-time-picker mat-month-view .selected-date {
  border-radius: 100%;


  .mat-calendar-body-cell-content:hover::after {
    counter-increment: section;
    content: attr(aria-label);
    position: absolute;
    left: 0;
    top: 24px;
    min-width: 200px;
    border: 1px #aaaaaa solid;
    border-radius: 4px;
    background-color: #3a3a3c;
    padding: 6px 0;
    color: #ffffff;
    font-size: 14px;
    z-index: 1;
  }

}

.start-picker mat-month-view .selected-start, .end-picker mat-month-view .selected-end, .date-time-picker mat-month-view .selected-date {
  .mat-calendar-body-cell-content:hover::after {
    content: 'Current selection';
  }
}

.current-calendar .mat-calendar-body-cell-content {
  border: 1px solid @wire-orange;
  //background: orange;
}

.other-calendar .mat-calendar-body-cell-content {
  border: 1px dashed #cccccc;
  background: white;
}

.date-time-picker .selected-date .mat-calendar-body-cell-content {
  border-color: @wire-orange;
  background: @wire-orange;
  color: white;
}

.start-picker .selected-start .mat-calendar-body-cell-content {
  border-color: @wire-orange;
  background: @wire-orange;
  color: white;
}

.end-picker .selected-end .mat-calendar-body-cell-content {
  border-color: @wire-orange;
  background: @wire-orange;
  color: white;
}

.mat-calendar-body-today {
  border-color: #dddddd;
  background: #dddddd;
}

//////End calendar styles////////////////

.date-period .dtp-refresh {
  //box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  display: flex;
  align-items: center;
  /* font-size: 19px; */
  /* padding: 4px 8px 7px; */
  margin: 2px 10px 0 10px;
  cursor: pointer;
  border-radius: 16px;
  height: 28px;
  width: 28px;
  justify-content: center;
}

.date-period .dtp-refresh:hover {
  background: #b0a696;
}

.date-part:hover {
  input.datetime {
    border-style: inset;
    border-width: 2px;
  }

  button svg {
    border-style: outset;
    border-radius: 0;
    border-width: 1px;
  }
}
