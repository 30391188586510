.spark-anchor {
  width: 100%;
  min-width: 120px;
  max-width: 150px;
  g.c3-chart, g.c3-event-rects rect.c3-event-rect, g.c3-chart-lines g.c3-chart-line {
    height:30px !important;
    //transform: translate(0px, -10px);
  }
  .c3-circle {
    opacity:0 !important;
  }
}
