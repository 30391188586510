.c3-ygrid-line.hihi line {
    stroke: red;
    stroke-dasharray: 1px, 2px;
    stroke-linecap: round;
}

.c3-ygrid-line.hi line {
    stroke: orangered;
    stroke-dasharray: 1px, 2px;
    stroke-linecap: round;
}

.c3-ygrid-line.lo line {
    stroke: orangered;
    stroke-dasharray: 1px, 2px;
    stroke-linecap: round;
}

.c3-ygrid-line.lolo line {
    stroke: red;
    stroke-dasharray: 1px, 2px;
    stroke-linecap: round;
}

.c3-ygrid-line.hihi text {
    fill: red;
}

.c3-ygrid-line.hi text {
    fill: orangered;
}

.c3-ygrid-line.lo text {
    fill: orangered;
}

.c3-ygrid-line.lolo text {
    fill: red;
}

.c3-ygrid-line.ucl-lcl text {
    fill: red;
}

.c3-ygrid-line.first-sigma line {
    stroke: blue;
    stroke-dasharray: 3px, 3px;
    stroke-linecap: round;
}

.c3-ygrid-line.second-sigma line {
    stroke: purple;
    stroke-dasharray: 3px, 3px;
    stroke-linecap: round;
}

.c3-ygrid-line.ucl-lcl line {
    stroke: red;
    stroke-dasharray: 3px, 3px;
    stroke-linecap: round;
}
