@use '@angular/material' as mat;
@import 'globals';

@mixin wire-toggle-styles($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  // custom-slide-toggle-styles.scss
  .wire {
    .mat-mdc-slide-toggle, .mat-mdc-slide-toggle.mat-accent {
      --mdc-typography-body1-font-size: #{mat.m2-font-size($typography-config, 'body-1')};
      --mdc-typography-body1-line-height: #{mat.m2-line-height($typography-config, 'body-1')};
      --mdc-typography-body1-font-weight: #{mat.m2-font-weight($typography-config, 'body-1')};
      --mdc-typography-body1-letter-spacing: #{mat.m2-letter-spacing($typography-config, 'body-1')};

      --mdc-switch-state-layer-size: 0;
      --mdc-switch-handle-surface-color: #{$secondary-colour};
      --mdc-switch-handle-hover-surface-color: #{$secondary-colour};
      --mdc-switch-unselected-track-color: #{$secondary-colour};
      --mdc-switch-unselected-hover-track-color: #{$secondary-colour};
      --mdc-switch-unselected-icon-color: transparent;
      --mdc-switch-selected-icon-color: transparent;
      --mdc-switch-selected-focus-state-layer-color: #{mat.m2-get-color-from-palette($secondary-palette, 500)};
      --mdc-switch-selected-handle-color: #{mat.m2-get-color-from-palette($secondary-palette, 500)};
      --mdc-switch-selected-hover-state-layer-color: #{mat.m2-get-color-from-palette($secondary-palette, 500)};
      --mdc-switch-selected-pressed-state-layer-color: #{mat.m2-get-color-from-palette($secondary-palette, 500)};
      --mdc-switch-selected-focus-handle-color: #{mat.m2-get-color-from-palette($secondary-palette, 700)};
      --mdc-switch-selected-hover-handle-color: #{mat.m2-get-color-from-palette($secondary-palette, 700)};
      --mdc-switch-selected-pressed-handle-color: #{mat.m2-get-color-from-palette($secondary-palette, 700)};
      --mdc-switch-selected-focus-track-color: #{mat.m2-get-color-from-palette($secondary-palette, 300)};
      --mdc-switch-selected-hover-track-color: #{mat.m2-get-color-from-palette($secondary-palette, 300)};
      --mdc-switch-selected-pressed-track-color: #{mat.m2-get-color-from-palette($secondary-palette, 300)};
      --mdc-switch-selected-track-color: #{mat.m2-get-color-from-palette($secondary-palette, 300)};

      .mdc-form-field {
        .mdc-switch__icons {
          display: none;
        }
      }
    }

  }
}
