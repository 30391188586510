@import url("../../../style/variables.less");
@import url("../../../style/icons.less");
input.forecast-year {
  border: 1px solid #e6e4e1;
  padding:6px;
  width:100px;
}
.estimate-input {
    position:relative;
    top:0;
    width:100%;
    height:100%;
    font-size:1em;
    padding:0;
    display: flex;
    justify-content: center;
    align-items: center;
    border:2px solid @tile-header;
    border-top:0;
    border-radius: 0 0 @border-radius @border-radius;


  table {
    border-collapse: collapse;
    min-width: 100%;
    position: relative;
    z-index: 0;
    background: rgba(222, 221, 218, 1);
    padding-bottom: 0px;
    margin-bottom:10px;
    padding-right: 8px;

    th, td {
      min-width: 64px;
    }
    td.mat-mdc-cell {
      border: 0;
      text-align: left;
      background: #e6e4e1;
    }
    .mat-mdc-row:nth-child(even) td {
      background: #e6e4e1;
    }
    .mat-mdc-row:nth-child(odd) td {
      background: #e3e1de;
    }
    tbody tr.mat-mdc-row:nth-child(even) td:nth-child(even) {
      //background: #e6e4e1;
     // background: #e2e0dc;
    }
    tbody tr.mat-mdc-row:nth-child(odd) td:nth-child(even) {
      //background: #deddda;
    }
    td.mat-mdc-cell:first-of-type {
      padding-left:8px;
    }
    tr.mat-mdc-row {
      height: 30px;
      border:none;
    }
    tr.mat-mdc-header-row {
      height: 40px;
      text-align: center;
      padding: 8px;
      background: white;
      th.mat-mdc-header-cell {
        height: 24px;
        background: white;
        text-align: center;
        border: 1px solid #e6e4e1;
        padding: 2px;
      }
      button {
        width:100%;
        text-align:center;
      }
    }

    td.mat-mdc-cell:last-of-type, th.mat-mdc-header-cell:last-of-type {
      border-right: 1px solid #ccc;
    }
    td.month {
      padding: 0 2px;
    }
    .mat-column-Name {
      min-width: 150px;
      width: 150px;
      white-space: nowrap;
      text-overflow: ellipsis;
      @media screen and (max-width: @media-xs) {
        position: static !important;
        min-width: 120px;
        width: unset;
      }
    }
  }
}
