@use '@angular/material' as mat;
@import 'globals';

@mixin wire-tooltip-styles($theme) {
  .cdk-overlay-container {
    //TODO check this again in later versions of AM
    --mdc-tooltip-container-padding: 10px;

    .mat-mdc-tooltip {
      --mdc-plain-tooltip-container-color: rgba(58, 58, 60, 0.9);
      --mdc-plain-tooltip-supporting-text-color: white;
      --mdc-plain-tooltip-container-shape: 4px;
      --mdc-plain-tooltip-supporting-text-line-height: 16px;
      --mdc-plain-tooltip-supporting-text-size: 16px;
      --mdc-plain-tooltip-supporting-text-weight: 500;
      --mdc-tooltip-word-break: normal;

      .mdc-tooltip__surface {
        padding: 10px !important;
      }
    }
  }
  .mdc-snackbar{
    --mdc-snackbar-container-color: white;
    --mdc-snackbar-supporting-text-color: mat.$light-primary-text;
  }
}